import styled from 'styled-components'

export const TabsHeaderDivider = styled.div`
  width: 0px;
  height: 26px;
  border: 1px solid #868686;
  margin: 0 30px;
`

export const TabsHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  div > h3 {
    margin: 10px 0 20px;
  }
`
export const BackButton = styled.div`
  color: #ed1c24;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  font-family: Montserrat;
  cursor: pointer;

  img {
    width: 16px;
    margin: 0 4px 0 0 !important;
  }
`
