import { createTheme } from '@material-ui/core/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#f33',
    },
    secondary: {
      main: '#000000',
    },
    text: {
      primary: '#333333',
      secondary: '#FFFFFF',
      hint: '#6C6E75',
    },
    error: {
      main: '#FF0000',
    },
    divider: '#434448',
  },
  typography: {
    fontFamily: 'Montserrat',
    h1: {
      fontSize: '2.286rem', // 32px
    },
    h2: {
      fontSize: '2rem', // 28px
    },
    h3: {
      fontSize: '1.643rem', // 23px
    },
    h4: {
      fontSize: '1.5rem', // 21px
    },
    h5: {
      fontSize: '1rem', // 14px
    },
    h6: {
      fontSize: '0.7143rem', // 10px
    },
    subtitle1: {
      fontSize: '1.143rem', // 16px
    },
    subtitle2: {
      fontSize: '0.7857rem', // 11px
    },
    body1: {
      fontSize: '14px',
    },
  },
  overrides: {
    MuiFormHelperText: {
      root: {
        textAlign: 'right',
      },
    },
    MuiInputBase: {
      root: {
        '& svg.MuiSvgIcon-root': {
          color: '#6C6E75',
        },
      },
      input: {
        paddingLeft: '10px',

        '& textarea': {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
      inputMultiline: {
        padding: '10px',

        '&::placeholder': {
          color: '#6C6E75',
          opacity: '1',
        },
      },
    },
    MuiInput: {
      root: {
        border: 'none',
        outline: 'none',
      },
      underline: {
        '&:before': {
          borderBottom: `1px solid transparent`,
        },
        '&:after': {
          borderBottom: `1px solid transparent`,
        },
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid transparent`,
        },
        '&.Mui-error:after': {
          border: `1px solid transparent`,
        },
      },
    },
    MuiButton: {
      containedPrimary: {
        '&:hover': {
          backgroundColor: '#ff7378',
        },
      },
      outlinedPrimary: {
        border: '2px solid #f33',
        '&:hover': {
          border: '2px solid #ff7378',
          color: '#ff7378',
          backgroundColor: '#fff',
        },
      },
    },
  },
})
