import React from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import Translations from '../../utils/translations'
import { SelectListOption } from '../../Models/DashboardTypes'
import './RoundUp.scss'
interface RoundUpProps {
  change: () => (option: SelectListOption) => void
  value: number
  id?: string
}

const RoundUp = (props: RoundUpProps) => {
  const { change, value } = props
  const { t } = useTranslation()

  return (
    <div className="content-row">
      <div className="width-100">
        <label>{t(Translations.RoundUpTo)}</label>
        <Select
          autosize={false}
          value={value}
          options={[
            { value: 0, label: t(Translations.None) },
            { value: 100, label: '$1.00' },
            { value: 50, label: '$0.50' },
            { value: 25, label: '$0.25' },
            { value: 10, label: '$0.10' },
            { value: 5, label: '$0.05' },
          ]}
          onChange={change()}
          clearable={false}
          id={props.id}
        />
      </div>
    </div>
  )
}

export default RoundUp
