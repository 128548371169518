import { Grid, Hidden } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Images } from '../../assets'
import Translations from '../../utils/translations'
import PortalInformation from './PortalInformation'
import { buildStyles } from './styles'

export interface LoginLayoutProps {
  children: React.ReactNode
}

const LoginLayout = ({ children }: LoginLayoutProps) => {
  const classes = buildStyles()
  const { t } = useTranslation()

  return (
    <Grid
      container
      justifyContent="center"
      alignContent="center"
      className={classes.backgroundContainer}
    >
      <Grid
        item
        xs={12}
        md={5}
        lg={5}
        container
        direction="column"
        alignItems="center"
        spacing={2}
      >
        <Grid item className={classes.leftSideItem}>
          <div className="col-sm-12 text-center">
            <span className="Business">{t(Translations.TEZ_ADMIN)}</span>
          </div>
        </Grid>

        <Grid item className={classes.logo}>
          <img src={Images.T2PLogo} alt="whitegreen logo" />
        </Grid>

        <Hidden smDown>
          <Grid item>
            <PortalInformation />
          </Grid>
        </Hidden>
      </Grid>

      <Hidden smDown>
        <Grid item xs={12} md={1}></Grid>
      </Hidden>

      <Grid item xs={12} md={5} lg={3} container alignContent="center">
        {children}
      </Grid>

      <Hidden mdUp>
        <Grid item xs={12}>
          <PortalInformation />
        </Grid>
      </Hidden>
    </Grid>
  )
}

export default LoginLayout
