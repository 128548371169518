import * as React from 'react'
import styled from 'styled-components'

const Content: React.FunctionComponent<{ className?: string }> = ({
  children,
  className,
}) => {
  return <div className={!!className ? className : 'col-xs-12'}>{children}</div>
}

const RowContainer = styled.div`
  &:not(:first-child) {
    margin-top: 20px;
  }
`

const Row: React.FunctionComponent = ({ children }) => {
  return <RowContainer>{children}</RowContainer>
}

const Label = styled.label`
  font-family: 'Roboto';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: black;
  display: block;
`

const Popup = {
  Content,
  Row,
  Label,
}

export default Popup
