import { createContext, Dispatch } from 'react'
import { SET_IS_LOGGED, SET_IS_SPINNING, SET_PROFILE_NAME } from './constants'

export type AppContextState = {
  isLogged: boolean
  profileName: string
  isSpinning: boolean
}

export type AppContextAction =
  | { type: typeof SET_IS_LOGGED; isLogged: boolean }
  | { type: typeof SET_PROFILE_NAME; profileName: string }
  | { type: typeof SET_IS_SPINNING; isSpinning: boolean }

export type AppContextType = {
  state: AppContextState
  contextDispatch: Dispatch<AppContextAction>
}

export const AppContext = createContext<AppContextType>({} as AppContextType)
