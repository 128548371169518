import axiosCreator, { AxiosRequestConfig } from 'axios'
import { routes } from '../routes'
import {
  getTokensFromLocalStorage,
  logoutAccount,
  setAuthHeader,
  setSessionExpiredPopupInLocalStorage,
  setTokensInLocalStorage,
} from './auth'
import { APP_CONFIG } from './config'
import { REFRESH_TOKEN } from './constants'
import { authApi } from './services'

const axiosRequestConfig: AxiosRequestConfig = {
  baseURL: APP_CONFIG.apiUrl,
}
const axios = axiosCreator.create(axiosRequestConfig)

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // Reject promise if usual error
    const errorResponse = error.response

    if (
      errorResponse &&
      errorResponse.status === 401 &&
      error.config.url !== REFRESH_TOKEN
    ) {
      const tokens = getTokensFromLocalStorage()

      if (tokens) {
        setAuthHeader(tokens.refresh)
        return authApi
          .refresh()
          .then((response) => {
            if (response && response.data) {
              const { auth, refresh, expires } = response.data.tokens
              setTokensInLocalStorage({ auth, refresh, expires })

              errorResponse.config.headers['Authorization'] = 'Bearer ' + auth
              const url = errorResponse.config.url.replace(
                APP_CONFIG.apiUrl,
                ''
              )
              errorResponse.config.url = url
              return axiosCreator(errorResponse.config)
            } else {
              logoutAccount()
              setSessionExpiredPopupInLocalStorage(true);
              window.location.href = routes.root.LOGIN;
              return Promise.reject(error)
            }
          })
          .catch((error) => {
            logoutAccount()
            window.location.href = routes.root.LOGIN
            return Promise.reject(error)
          })
      }
    } else {
      return Promise.reject(error)
    }
  }
)

axios.interceptors.request.use(
  (config) => {
    const tokens = getTokensFromLocalStorage()

    if (
      tokens &&
      tokens.auth &&
      config.headers['Authorization'] !== `Bearer ${tokens.refresh}` &&
      config.url !== REFRESH_TOKEN
    ) {
      config.headers['Authorization'] = `Bearer ${tokens.auth}`
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

export default axios
