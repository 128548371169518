import { makeStyles, Theme } from '@material-ui/core'
import { Images } from '../../assets'

export const buildStyles = makeStyles((theme: Theme) => ({
  backgroundContainer: {
    height: '100vh',
    background: `url(${Images.RegistrationBackground}) no-repeat 50% fixed`,
    backgroundSize: 'cover',
    padding: theme.spacing(3),
  },

  paper: {
    width: '100%',
  },

  wrapper: {
    padding: theme.spacing(3),
  },

  error: {
    textTransform: 'uppercase',
    fontWeight: 500,
  },

  logo: {
    width: '80%',

    '& img': {
      width: '100%',
    },
  },

  leftSideItem: {
    textAlign: 'center',
  },

  portalInformation: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
}))
