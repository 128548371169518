import React from 'react'
import NumberFormat, { NumberFormatProps } from 'react-number-format'

export interface PercentageInputProps extends NumberFormatProps {
  handler: () => (v: number | null) => void
}

export const PercentageInput: React.FC<PercentageInputProps> = ({
  defaultValue,
  handler,
  ...props
}) => {
  return (
    <NumberFormat
      allowedDecimalSeparators={['.', ',']}
      decimalScale={2}
      className="form-control percentage"
      max={100}
      allowLeadingZeros
      allowEmptyFormatting
      allowNegative={false}
      onChange={(e) => {
        if (!e.target.value) return handler()(null)
      }}
      onValueChange={(e) => {
        if (typeof e.floatValue !== 'number') return
        if (e.floatValue === 0) return handler()(null)
        handler()(e.floatValue > 100 ? 100 : e.floatValue)
      }}
      {...props}
    />
  )
}

export default PercentageInput
