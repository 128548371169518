import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import usTranslation from './resources/en-US/translation.json'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      us: {
        translation: usTranslation,
      },
    },
    fallbackLng: 'us',
    load: 'currentOnly',
    keySeparator: false,
    supportedLngs: ['us'],
    interpolation: {
      escapeValue: false,
    },
    debug: false,
    detection: {
      order: ['localStorage', 'navigator'],
      lookupLocalStorage: 'i18nextLng',
      // checkWhitelist: true,
    },
  })

export default i18n
