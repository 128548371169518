import React from 'react'
import { useTranslation } from 'react-i18next'
import Translations from '../../utils/translations'
import * as S from '../../Controls/Body/Body'
import RadioButtonGroup from '../../Controls/CustomRadioButton/RadioButtonGroup'
import { AccountType, Nacha } from '../../Enums/Enums'
import { PaymentsContext } from './PaymentsContext'
import { integrationApi } from '../../api/services'
import { Icons } from '../../assets'
import {
  ConfigurationSource,
  useConfigurationSource,
} from './ConfigurationSource'

const PaymentConfiguration = () => {
  const { state, dispatch } = React.useContext(PaymentsContext)
  const { t } = useTranslation()
  const { config } = state
  const { source } = useConfigurationSource()
  const getAccountTypeLabel = React.useCallback(
    (accountType: AccountType | undefined): string => {
      if (!accountType || !(accountType in AccountType)) {
        return '-'
      }
      return config.accountType === AccountType.Checking
        ? t(Translations.Checking)
        : t(Translations.Savings)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleACHChange = React.useCallback(
    (selectedMerchantECheck: string) => {
      if (selectedMerchantECheck) {
        dispatch({
          type: 'change_merchant_echeck',
          merchant: selectedMerchantECheck,
          returnedItemFee: 5,
        })
      } else {
        dispatch({
          type: 'change_merchant_echeck',
          merchant: '',
          returnedItemFee: 0,
        })
      }
    },
    [dispatch]
  )

  const getRedirectUrlToNachaPortal = (baseUrl: string) => {
    integrationApi.getRedirectUrlToNachaPortal(baseUrl).then((result) => {
      result && window.location.replace(result.data)
    })
  }

  const redirectToNacha = (): void => {
    getRedirectUrlToNachaPortal(config.redirectURL)
  }

  const redirectToNachaACR = (): void => {
    config.redirectURLACR && getRedirectUrlToNachaPortal(config.redirectURLACR)
  }
    
  return (
    <>
      <div className="content col-centered">
        <div className="header">
          <img src={Icons.Price} alt="price icon" />
          <h3>{t(Translations.PaymentConfiguration)}</h3>
        </div>
        <ConfigurationSource />
        <S.Title text={t(Translations.GeneralInfo)} />
        {!!config.merchantList?.length && (
          <S.Body>
            <S.Item label={t(Translations.Merchant)}>
              <RadioButtonGroup
                options={config.merchantList}
                id="merchant"
                disabled={source.nacha}
                selected={config.selectedMerchant}
                onChange={(selected: string) => {
                  dispatch({ type: 'change_merchant', merchant: selected })
                }}
              />
            </S.Item>
          </S.Body>
        )}
        <S.Body>
          <S.Item label={t(Translations.NachaType)}>
            {config.nacha === Nacha.Use
              ? t(Translations.UseNacha)
              : t(Translations.DontUseNacha)}
          </S.Item>
        </S.Body>
        <S.Separator />
        <S.Title text={t(Translations.AccountConfiguration)}>
          <button
            type="button"
            className="cancelBtn changeBtn"
            disabled={!config.redirectURL}    
            onClick={redirectToNacha}
            id="accountConfigChangeButton"
          >
            {t(Translations.EditPaymentConfiguration)}
          </button>
        </S.Title>
        <S.Body>
          <S.Item label={t(Translations.RoutingNumber)}>
            {config.routingNumber || '-'}
          </S.Item>
          <S.Item label={t(Translations.ReceiverName)}>
            {config.receiverName || '-'}
          </S.Item>
          <S.Item label={t(Translations.SenderId)}>
            {config.senderId || '-'}
          </S.Item>
          <S.Item label={t(Translations.AccountNachaDfiNumber)}>
            {config.accountNachaDfiNumber || '-'}
          </S.Item>
          <S.Item label={t(Translations.AccountType)}>
            {getAccountTypeLabel(config.accountType)}
          </S.Item>
        </S.Body>
        <S.Separator />
        <S.Title text={t(Translations.AcrNachaTransferConfiguration)}>
          <button
            type="button"
            id="redirectButton"
            className="cancelBtn changeBtn"
            disabled={!config.redirectURLACR}
            onClick={redirectToNachaACR}
          >
            {t(Translations.EditPaymentConfiguration)}
          </button>
        </S.Title>
        <S.Body>
          <S.Item label={t(Translations.RoutingNumber)}>
            {config.routingNumberACR || '-'}
          </S.Item>
          <S.Item label={t(Translations.ReceiverName)}>
            {config.receiverNameACR || '-'}
          </S.Item>
          <S.Item label={t(Translations.SenderId)}>
            {config.senderIdACR || '-'}
          </S.Item>
          <S.Item label={t(Translations.AccountNachaDfiNumber)}>
            {config.accountNachaDfiNumberACR || '-'}
          </S.Item>
          <S.Item label={t(Translations.AccountType)}>
            {getAccountTypeLabel(config.accountTypeACR)}
          </S.Item>
        </S.Body>
      </div>
      {!!config.merchantListECheck?.length && (
        <div className="content col-centered">
          <div className="header">
            <img src={Icons.ACH_ECheck} alt="ach echeck icon" />
            <h3>{t(Translations.ACHECheck)}</h3>
          </div>
          <S.Body>
            <S.Item label={t(Translations.SelectMethod)} bold>
              <RadioButtonGroup
                id="ach"
                options={[
                  ...config.merchantListECheck,
                  { value: '', label: t(Translations.None) },
                ]}
                selected={config.selectedMerchantECheck}
                onChange={handleACHChange}
                disabled={source.nacha}
              />
            </S.Item>
          </S.Body>
        </div>
      )}
    </>
  )
}

export default PaymentConfiguration
