/* eslint-disable jsx-a11y/anchor-is-valid */
import * as React from 'react'
import axios from '../../../api'
import { ApiResultBase } from '../../../api/types'
import { Icons } from '../../../assets'
import AppSpinner from '../Spinner/AppSpinner'
import './DataTable.scss'

export interface DataTableParams {
  searchValue: string
  pageIndex: number
  pageSize: number
  sortField: string
  sortDirection: 'ASC' | 'DESC'
}

export interface DataTableApiResult<T = unknown> extends ApiResultBase {
  data: T
  recordCount: number
}

type FetchDataFunction<T> = (
  filters: DataTableParams
) => Promise<DataTableApiResult<T> | null>

interface DataTableProps<T> extends React.Props<DataTable<T>> {
  url: FetchDataFunction<T> | string
  pageSize: number
  sortField: string
  sortDirection: string
  title?: string
  filters?: any
  showPager?: boolean
  showPagerSize?: boolean
  auth?: boolean
  callback?: (data: any, result: any) => void
  refreshValue?: any
  isTotalRowVisible?: boolean
}

interface DataTableState {
  isLoading: boolean
  data: any
  pageIndex: number
  pageSize: number
  sortField: string
  sortDirection: string
  recordCount: number
  errorWhileRetrivingData: boolean
  isTotalRowVisible?: boolean
}

export class DataTable<T> extends React.Component<
  DataTableProps<T>,
  DataTableState
> {
  static defaultProps = {
    showPagerSize: false,
  }
  private dtRef: any

  constructor(props: DataTableProps<T>) {
    super(props)

    this.state = {
      isLoading: false,
      data: [],
      pageIndex: 1,
      pageSize: props.pageSize,
      sortField: props.sortField,
      sortDirection: props.sortDirection,
      recordCount: 0,
      errorWhileRetrivingData: false,
      isTotalRowVisible: props.isTotalRowVisible,
    }
  }

  loadData = (props?: DataTableProps<T>) => {
    props = props || this.props
    this.setState({ isLoading: true })

    const data = {
      pageSize: this.state.pageSize,
      pageIndex: this.state.pageIndex,
      sortField: this.state.sortField,
      sortDirection: this.state.sortDirection,
      ...props.filters,
    }

    let fetchDataPromise: Promise<DataTableApiResult<T> | null>
    if (typeof this.props.url === 'string') {
      fetchDataPromise = axios.get(this.props.url as string, {
        params: data,
      }) as Promise<DataTableApiResult<T>>
    } else {
      fetchDataPromise = this.props.url(data)
    }

    fetchDataPromise &&
      fetchDataPromise
        //FIXME:
        .then((result: any) => {
          if (result.data !== null) {
            let state: any
            if (typeof this.props.url === 'string' && result.data !== null) {
              state = {
                recordCount: result.data.recordCount,
                data: result.data.data,
              }
            } else if (result.data !== null) {
              state = {
                recordCount: result.recordCount,
                data: result.data,
              }
            }

            const pageCount = Math.ceil(state.recordCount / this.state.pageSize)
            if (pageCount < this.state.pageIndex && pageCount > 0) {
              state = { ...state, pageIndex: pageCount }
            }
            this.setState(state, () => {
              if (typeof this.props.callback === 'function')
                this.props.callback(this.state.data, result)
            })
          } else {
            if (typeof this.props.callback === 'function') {
              this.props.callback(null, result)
            }
          }

          setTimeout(
            () =>
              this.setState({
                isLoading: false,
                errorWhileRetrivingData: false,
              }),
            200
          )
          this.setState({
            isLoading: false,
          })
        })
        .catch((error: any) => {
          setTimeout(
            () =>
              this.setState({
                isLoading: false,
                errorWhileRetrivingData: true,
              }),
            500
          )
        })
  }

  getFirst = () => {
    this.setState({ pageIndex: 1 }, this.loadData)
  }

  getPrev = () => {
    this.setState({ pageIndex: this.state.pageIndex - 1 }, this.loadData)
  }

  getNext = () => {
    this.setState({ pageIndex: this.state.pageIndex + 1 }, this.loadData)
  }

  getLast = () => {
    const pageCount = Math.ceil(this.state.recordCount / this.state.pageSize)
    this.setState({ pageIndex: pageCount }, this.loadData)
  }

  onPageSizeChange = (e: { target: any }) => {
    const el = e.target
    this.setState({ pageSize: Number(el.value) }, this.loadData)
  }

  onSort = (e: { preventDefault: () => void; target: any }) => {
    e.preventDefault()
    const el = e.target,
      sortField = el.getAttribute('data-column'),
      sortDirection =
        sortField !== this.state.sortField
          ? 'ASC'
          : el.getAttribute('data-direction')

    this.setState(
      {
        sortField: sortField,
        sortDirection: sortDirection,
      },
      this.loadData
    )
  }

  componentDidMount() {
    this.loadData()
  }

  UNSAFE_componentWillReceiveProps(nextProps: DataTableProps<T>) {
    if (this.props.refreshValue !== nextProps.refreshValue) {
      this.setState(
        {
          pageIndex: 1,
        },
        this.loadData
      )
    }
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this
    const header = React.Children.map(this.props.children, (c, i) => {
      const child = c as React.ReactElement<ColumnProps>
      const imgSrc = Icons.Arrow
      const sortDisabled =
        child.props.sortDisabled ||
        typeof child.props.formatHeader === 'function' ||
        false
      return (
        <th
          key={i}
          style={{ ...child.props.style, ...child.props.headerStyle }}
          onClick={
            sortDisabled
              ? child.props.onClick
                ? child.props.onClick
                : undefined
              : that.onSort
          }
          className={child.props.headerClassName}
          data-column={sortDisabled ? null : child.props.keyName}
          data-direction={
            sortDisabled
              ? null
              : that.state.sortDirection === 'DESC'
              ? 'ASC'
              : 'DESC'
          }
        >
          {typeof child.props.formatHeader === 'function' ? (
            child.props.formatHeader()
          ) : (
            <span>{child.props.headerName}</span>
          )}
          {!sortDisabled && this.state.sortField === child.props.keyName && (
            <img
              height="70%"
              style={{ margin: '0px 4px' }}
              src={imgSrc}
              alt="
            "
            />
          )}
        </th>
      )
    })
    const columndData = React.Children.map(this.props.children, (c) => {
      const child = c as React.ReactElement<ColumnProps>
      return {
        keyName: child.props.keyName,
        format: child.props.format,
        style: child.props.style,
        rowStyle: child.props.rowStyle,
        onClick: child.props.onClick,
        className: child.props.className,
      }
    })
    const body = this.state.data.map(
      (
        row: { [x: string]: any; RowId: number },
        i: string | number | null | undefined
      ) => {
        const rowStyle =
          this.state.isTotalRowVisible && row.RowId === this.state.recordCount
            ? { fontWeight: 'bold' as 'bold', backgroundColor: '#eeeeee' }
            : {}

        return (
          <tr key={i} style={rowStyle}>
            {columndData &&
              columndData.map((column, j) => {
                const td =
                  typeof column.format === 'function'
                    ? column.format(row)
                    : column.keyName
                    ? row[column.keyName]
                    : undefined
                return (
                  <td
                    key={j}
                    style={{ ...column.style, ...column.rowStyle }}
                    className={column.className}
                  >
                    {td}
                  </td>
                )
              })}
          </tr>
        )
      }
    )

    const emptyBody = columndData ? (
      <tr key={0}>
        <td key={1} colSpan={columndData.length}>
          {this.state.errorWhileRetrivingData
            ? 'Error while retrieving data'
            : 'No data'}
        </td>
      </tr>
    ) : undefined

    const showPager = this.props.showPager == null || this.props.showPager
    return (
      <div ref={(ref) => (this.dtRef = ref)} className="dt-table">
        <Title title={this.props.title} />

        <table>
          <thead>
            <tr>{header}</tr>
          </thead>
          <tbody>{body.length > 0 ? body : emptyBody}</tbody>
        </table>
        <Footer
          showPager={showPager}
          recordCount={this.state.recordCount}
          pageIndex={this.state.pageIndex}
          pageSize={this.state.pageSize}
          showPagerSize={this.props.showPagerSize || false}
          onFirst={this.getFirst}
          onPrev={this.getPrev}
          onNext={this.getNext}
          onLast={this.getLast}
          onChange={this.onPageSizeChange}
        />
        <AppSpinner show={this.state.isLoading} />
      </div>
    )
  }
}

interface ColumnProps extends React.Props<Column> {
  headerName: string | JSX.Element
  keyName?: string
  headerClassName?: string
  className?: string
  style?: React.CSSProperties
  headerStyle?: {}
  rowStyle?: {}
  format?: (data: any) => any
  sortDisabled?: boolean
  formatHeader?: () => any
  onClick?: () => any
}

export class Column extends React.Component<ColumnProps, any> {
  render() {
    return null
  }
}

interface TitleProps extends React.Props<Title> {
  title?: string
}

class Title extends React.Component<TitleProps, any> {
  render() {
    return this.props.title != null ? (
      <div className="title">{this.props.title}</div>
    ) : null
  }
}

interface FooterProps extends React.Props<Footer> {
  showPager: boolean
  showPagerSize: boolean
  recordCount: number
  pageSize: number
  pageIndex: number
  onFirst: () => void
  onPrev: () => void
  onNext: () => void
  onLast: () => void
  onChange: (e: any) => void
}

class Footer extends React.Component<FooterProps, any> {
  render() {
    if (!this.props.showPager) {
      return null
    }

    let pageSize = null
    if (this.props.showPagerSize && Math.ceil(this.props.recordCount / 5) > 1) {
      pageSize = (
        <div className="dt-rowcount">
          <select
            className="form-control"
            onChange={this.props.onChange}
            value={this.props.pageSize}
            name="row_count"
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
      )
    }

    return (
      <div className="dt-pager">
        <div>
          <Pager
            recordCount={this.props.recordCount}
            currentPage={this.props.pageIndex}
            pageSize={this.props.pageSize}
            onFirst={this.props.onFirst}
            onPrev={this.props.onPrev}
            onNext={this.props.onNext}
            onLast={this.props.onLast}
          />
          {pageSize}
        </div>
      </div>
    )
  }
}

interface PagerProps extends React.Props<Pager> {
  recordCount: number
  currentPage: number
  pageSize: number
  onFirst: () => void
  onPrev: () => void
  onNext: () => void
  onLast: () => void
}

class Pager extends React.Component<PagerProps, any> {
  pageCount: number

  constructor(props: PagerProps | Readonly<PagerProps>) {
    super(props)

    this.pageCount = Math.ceil(this.props.recordCount / this.props.pageSize)
  }

  UNSAFE_componentWillUpdate(
    nextProps: { recordCount: number; pageSize: number },
    nextState: any
  ) {
    this.pageCount = Math.ceil(nextProps.recordCount / nextProps.pageSize)
  }

  getFirst = (currentPage: number) => {
    if (currentPage <= this.pageCount && currentPage > 1) {
      return (
        <a onClick={this.props.onFirst} className="btn btn-sm btn-secondary">
          1
        </a>
      )
    }
    return null
  }

  getPrev = (currentPage: number) => {
    if (currentPage <= this.pageCount && currentPage > 1) {
      return (
        <a
          onClick={this.props.onPrev}
          className="btn btn-sm btn-secondary arrow"
        >
          &lt;
        </a>
      )
    }
    return null
  }

  getCurrent = (currentPage: number) => {
    if (currentPage < this.pageCount || currentPage > 1) {
      return (
        <span>
          {currentPage - 2 > 1 ? <span>...</span> : null}
          {currentPage - 1 > 1 ? (
            <a onClick={this.props.onPrev} className="btn btn-sm btn-secondary">
              {currentPage - 1}
            </a>
          ) : null}

          <a className="dt-current-page btn btn-sm btn-pager disabled">
            {currentPage}
          </a>

          {currentPage + 1 < this.pageCount ? (
            <a onClick={this.props.onNext} className="btn btn-sm btn-secondary">
              {currentPage + 1}
            </a>
          ) : null}
          {currentPage + 2 < this.pageCount ? <span>...</span> : null}
        </span>
      )
    }
    return null
  }

  getNext = (currentPage: number) => {
    if (currentPage < this.pageCount) {
      return (
        <a
          onClick={this.props.onNext}
          className="btn btn-sm btn-secondary arrow"
        >
          &gt;
        </a>
      )
    }
    return null
  }

  getLast = (currentPage: number) => {
    if (currentPage < this.pageCount) {
      return (
        <a onClick={this.props.onLast} className="btn btn-sm btn-secondary">
          {this.pageCount}
        </a>
      )
    }
    return null
  }

  render() {
    if (
      this.getPrev(this.props.currentPage) === null &&
      this.getFirst(this.props.currentPage) === null &&
      this.getCurrent(this.props.currentPage) === null &&
      this.getLast(this.props.currentPage) === null &&
      this.getNext(this.props.currentPage) === null
    ) {
      return null
    }

    return (
      <div className="dt-pages">
        {this.getPrev(this.props.currentPage)}
        {this.getFirst(this.props.currentPage)}
        {this.getCurrent(this.props.currentPage)}
        {this.getLast(this.props.currentPage)}
        {this.getNext(this.props.currentPage)}
      </div>
    )
  }
}
