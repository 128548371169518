import * as React from 'react'
import i18n from '../../i18n'
import Translations from '../../utils/translations'
import { CurrencyInput } from '../../Controls/CustomInput/CurrencyInput'
import RadioButtonGroup from '../../Controls/CustomRadioButton/RadioButtonGroup'
import { FeesType } from '../../Enums/Enums'
import { LabelValue } from '../../Models/DashboardTypes'
import InputAdornent from '../../Controls/CustomInput/InputAdornent'
import './Fees.scss'
import PercentageInput from '../../Controls/CustomInput/PercentageInput'

interface FeesProps extends React.Props<Fees> {
  buttonSelected: FeesType
  handleChange: any
  firstInputValue: number
  firstInputOnChange: any
  secondInputValue: number
  secondInputOnChange: any
  thirdInputValue: number
  thirdInputOnChange: any
  fourthInputValue: number
  fourthInputOnChange: any
  parkerInputValue?: number
  parkerInputOnChange?: (value: number) => void
  id?: string
}

class Fees extends React.Component<FeesProps, any> {
  inputChange = (
    value: number | null | undefined,
    handler: any,
    min: number,
    max?: number,
    notDecimal?: boolean
  ) => {
    if (value === null || value === undefined) {
      if (!min) {
        return handler()(null)
      } else {
        value = min
      }
    }
    if (value < min) {
      value = min
    } else if (max && value > max) {
      value = max
    }

    if (notDecimal) {
      value.toFixed(0)
    }

    handler()(value)
  }

  percentFormat = (num: any) => {
    return num + '%'
  }

  render() {
    const {
      buttonSelected,
      handleChange,
      firstInputValue,
      firstInputOnChange,
      secondInputValue,
      secondInputOnChange,
      thirdInputValue,
      thirdInputOnChange,
      fourthInputValue,
      fourthInputOnChange,
      parkerInputOnChange,
      parkerInputValue,
    } = this.props

    const percentageInput = (
      <InputAdornent
        endAdornment="%"
        paddingLeft={10}
        disabled={buttonSelected !== FeesType.Percentage}
      >
        <PercentageInput
          id={`${this.props.id}-percentage`}
          className="form-control percentage"
          value={
            buttonSelected !== FeesType.Percentage
              ? ''
              : firstInputValue || null
          }
          handler={firstInputOnChange}
          defaultValue={firstInputValue}
          disabled={buttonSelected !== FeesType.Percentage}
          style={{ marginLeft: 10 }}
        />
      </InputAdornent>
    )

    const flatFeeInput = (
      <InputAdornent
        startAdornment="$"
        paddingLeft={10}
        disabled={buttonSelected !== FeesType.FlatFee}
      >
        <CurrencyInput
          id={`${this.props.id}-flat`}
          className="form-control"
          value={
            buttonSelected !== FeesType.FlatFee ? undefined : secondInputValue
          }
          disabled={buttonSelected !== FeesType.FlatFee}
          changed={(value: number) => {
            this.inputChange(value, secondInputOnChange, 0)
          }}
          allowEmpty={true}
          moneyFormat={false}
          style={{ marginLeft: 10 }}
        />
      </InputAdornent>
    )
    const percentageFlatFeeInput = (
      <>
        <InputAdornent
          startAdornment="$"
          disabled={buttonSelected !== FeesType.Mixed}
          paddingLeft={10}
        >
          <CurrencyInput
            id={`${this.props.id}-mixFlat`}
            className="form-control"
            value={
              buttonSelected !== FeesType.Mixed ? undefined : thirdInputValue
            }
            disabled={buttonSelected !== FeesType.Mixed}
            changed={(value: number) => {
              this.inputChange(value, thirdInputOnChange, 0)
            }}
            allowEmpty={true}
            moneyFormat={false}
            style={{ marginLeft: 10 }}
          />
        </InputAdornent>
        <span style={{ paddingLeft: 8, paddingRight: 8 }}>+</span>
        <InputAdornent
          endAdornment="%"
          disabled={buttonSelected !== FeesType.Mixed}
        >
          <PercentageInput
            id={`${this.props.id}-mixPercentage`}
            className="form-control percentage"
            defaultValue={fourthInputValue}
            value={
              buttonSelected !== FeesType.Mixed ? '' : fourthInputValue || null
            }
            handler={fourthInputOnChange}
            disabled={buttonSelected !== FeesType.Mixed}
          />
        </InputAdornent>
      </>
    )

    const parkerFeeInput = !!parkerInputOnChange ? (
      <InputAdornent
        startAdornment="$"
        disabled={buttonSelected !== FeesType.Parker}
        paddingLeft={10}
      >
        <CurrencyInput
          className="form-control"
          value={
            buttonSelected !== FeesType.Parker ? undefined : parkerInputValue
          }
          disabled={buttonSelected !== FeesType.Parker}
          changed={(value: number) => {
            this.inputChange(value, parkerInputOnChange, 0.01)
          }}
          allowEmpty={true}
          moneyFormat={false}
          style={{ marginLeft: 10 }}
        />
      </InputAdornent>
    ) : undefined

    const options: LabelValue[] = [
      {
        value: FeesType.Percentage,
        label: i18n.t(Translations.Percentage),
        additional: percentageInput,
      },
      {
        value: FeesType.FlatFee,
        label: i18n.t(Translations.FlatFee),
        additional: flatFeeInput,
      },
      {
        value: FeesType.Mixed,
        label: i18n.t(Translations.PercentageFlatFee),
        additional: percentageFlatFeeInput,
      },
      ...(!!parkerInputOnChange
        ? [
            {
              value: FeesType.Parker,
              label: i18n.t(Translations.PerParker),
              additional: parkerFeeInput,
            },
          ]
        : []),
    ]
    return (
      <div className="fees">
        <RadioButtonGroup
          options={options}
          selected={buttonSelected}
          onChange={handleChange}
        />
      </div>
    )
  }
}

export default Fees
