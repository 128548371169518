import { Grid } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Translations from '../../utils/translations'
import { buildStyles } from './styles'

export interface PortalInformationProps {}

const PortalInformation = (props: PortalInformationProps) => {
  const classes = buildStyles()
  const { t } = useTranslation()

  return (
    <>
      <Grid item className={classes.portalInformation}>
        <div className="col-sm-12 text-center">
          <span className="parkingMadeEasy">
            {t(Translations.PARKING_MADE_EASY)}
          </span>
        </div>
      </Grid>

      <Grid item className={classes.portalInformation}>
        <div className="col-sm-12 text-center">
          <span className="turnEmptySpace">
            {t(Translations.TURN_EMPTY_SPACE_INTO_REVENUE)}
          </span>
        </div>
      </Grid>
    </>
  )
}

export default PortalInformation
