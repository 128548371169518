import React from 'react'
import { generatePath, RouteComponentProps, withRouter } from 'react-router'
import { corporationApi } from '../../api/services'
import { GetCorporationsModel } from '../../api/services/corporation/types'
import { Icons } from '../../assets'
import i18n from '../../i18n'
import { routes } from '../../routes'
import {
  Column,
  DataTable,
  DataTableParams,
} from '../../shared/components/DataTable/DataTable'
import Translations from '../../utils/translations'
import './CorporationList.scss'
import SearchContext, { SearchContextType } from '../../context/SearchContext'

type stateParam = {
  statement: string
  refresh: boolean
  CorporationName: string
}

type CorporationListProps = RouteComponentProps

export class CorporationList extends React.Component<
  CorporationListProps,
  stateParam
> {
  static contextType = SearchContext
  constructor(props: CorporationListProps, context: SearchContextType) {
    super(props)

    this.state = {
      statement: '',
      refresh: false,
      CorporationName: context.corpSearch,
    }
  }

  handleCoporationRedirect = (corporationId: string): void => {
    this.props.history.push(
      generatePath(routes.business.CORPORATION, { corporationId })
    )
  }

  corporationNameChange = (e: { target: { value: any } }): void => {
    this.setState({ CorporationName: e.target.value })
  }

  searchClick = () => {
    this.setState({
      refresh: !this.state.refresh,
    })
    this.context.setCorpSearch(this.state.CorporationName)
  }

  serachEnter = (e: { keyCode: number }) => {
    if (e.keyCode === 13) {
      this.setState({ refresh: !this.state.refresh })
      this.context.setCorpSearch(this.state.CorporationName)
    }
  }

  formatCorporationName = (data: GetCorporationsModel) => {
    return (
      <span
        className="corporationName table-clickable"
        onClick={() => this.handleCoporationRedirect(data.id.toString())}
      >
        {' '}
        {data.name}{' '}
      </span>
    )
  }

  formatColumnAddress = (data: GetCorporationsModel) => {
    return <span> {data.address} </span>
  }

  render() {
    const filters: Partial<DataTableParams> = {
      searchValue: this.state.CorporationName,
    }

    return (
      <div className="content col-centered">
        <div className="header">
          <img src={Icons.Corporation} alt="corporation icon" />
          <h3>{i18n.t(Translations.Corporations)}</h3>

          <input
            className="form-control magnifier"
            onKeyUp={this.serachEnter}
            placeholder={i18n.t(Translations.CorporationName)}
            style={{ maxWidth: '350px', marginLeft: 'auto' }}
            value={this.state.CorporationName}
            onChange={this.corporationNameChange}
            id="searchCorporationInput"
            maxLength={30}
          />

          <button
            className="cancelBtn text-uppercase"
            onClick={this.searchClick}
            id="searchCorporationButton"
          >
            {i18n.t(Translations.Search)}
          </button>
        </div>
        <div className="list">
          <DataTable<GetCorporationsModel[]>
            url={corporationApi.getCorporations}
            pageSize={10}
            sortField="Name"
            sortDirection="ASC"
            refreshValue={this.state.refresh}
            filters={filters}
          >
            <Column
              keyName="Name"
              style={{ width: '48%' }}
              headerName={i18n.t(Translations.CorporationName)}
              format={this.formatCorporationName}
            />
            <Column
              keyName="Address"
              style={{ width: '52%' }}
              headerName={i18n.t(Translations.Address)}
              format={this.formatColumnAddress}
            />
          </DataTable>
        </div>
      </div>
    )
  }
}
export default withRouter(CorporationList)
