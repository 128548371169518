import {
  Grid,
  IconButton,
  makeStyles,
  TextField as MaterialTextField,
  TextFieldProps as MaterialTextFieldProps,
  Theme,
} from '@material-ui/core'
import React from 'react'

export const buildStyles = makeStyles((theme: Theme) => ({
  '@keyframes glow': {
    '100%': {
      boxShadow:
        'inset 0 1px 1px rgb(0 0 0 / 8%), 0 0 8px rgb(102 175 233 / 60%)',
    },
  },

  root: {
    color: theme.palette.text.primary,

    '&.Mui-focused': {
      animation: `$glow 300ms both`,
      animationIterationCount: 1,
      outline: 0,
    },
  },
  root2: (props: { withoutBorderRadius?: boolean }) => ({
    borderRadius: props.withoutBorderRadius ? '2px' : '17px',
  }),
  border: (props: { withoutBorderRadius?: boolean }) => ({
    border: '1px solid #7c7c7c',
    borderRadius: props.withoutBorderRadius ? '2px' : '17px',
  }),
  textField: {
    '& .Mui-error': {
      color: theme.palette.error.main,
      textTransform: 'uppercase',
    },
  },
  error: {
    fontWeight: 500,
  },
  iconButton: {
    margin: '-8px 0 0 0',
  },
}))

export type AppTextFieldProps = MaterialTextFieldProps & {
  labelIcon?: JSX.Element
  iconButton?: JSX.Element
  withoutBorderRadius?: boolean
  iconButtonOnClick?: () => void
}

const AppTextField = ({
  label,
  id,
  helperText,
  labelIcon,
  iconButton,
  iconButtonOnClick,
  withoutBorderRadius,
  ...props
}: AppTextFieldProps) => {
  const classes = buildStyles({ withoutBorderRadius })

  return (
    <div>
      {label && (
        <div className="row">
          <label htmlFor={id} className="inputLabel col-xs-12">
            {label}
          </label>
        </div>
      )}

      <div style={{ display: 'flex' }}>
        <MaterialTextField
          id={id}
          className={`${classes.textField} ${
            !!helperText ? 'Mui-error' : undefined
          }`}
          classes={{ root: `${classes.border} ${classes.border}` }}
          error={!!helperText}
          FormHelperTextProps={{ className: classes.error }}
          InputProps={{ className: `${classes.root} ${classes.root2}` }}
          {...props}
        />

        {iconButton && (
          <IconButton
            edge="end"
            className={classes.iconButton}
            onClick={() => iconButtonOnClick && iconButtonOnClick()}
          >
            {iconButton}
          </IconButton>
        )}
      </div>

      <Grid item xs={12} container>
        <label className="label_Invalid">{helperText}</label>
      </Grid>
    </div>
  )
}

AppTextField.defaultProps = {
  type: 'text',
}

export default AppTextField
