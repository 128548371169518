import React from 'react'
import styled from 'styled-components'
import './Spinner.scss'

const Wrapper = styled.div`
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
  width: 100%;
  background: rgba(#121212, 0.4);
`

export interface AppSpinnerProps {
  show?: boolean
}

const AppSpinner = ({ show }: AppSpinnerProps) => {
  if (!show) return null

  return (
    <Wrapper>
      <div className={`loader ${show ? 'is-spinning' : ''}`}></div>
    </Wrapper>
  )
}

AppSpinner.defaultProps = {
  show: true,
}

export default AppSpinner
