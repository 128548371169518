import { AppContextAction } from '.'
import { SET_IS_LOGGED, SET_IS_SPINNING, SET_PROFILE_NAME } from './constants'

export const setIsLogged = (
  dispatch: React.Dispatch<AppContextAction>,
  isLogged: boolean
) => {
  dispatch({
    type: SET_IS_LOGGED,
    isLogged,
  })
}

export const setProfileName = (
  dispatch: React.Dispatch<AppContextAction>,
  profileName: string
) => {
  dispatch({
    type: SET_PROFILE_NAME,
    profileName,
  })
}

export const setIsSpinning = (
  dispatch: React.Dispatch<AppContextAction>,
  isSpinning: boolean
) => {
  dispatch({
    type: SET_IS_SPINNING,
    isSpinning,
  })
}
