import React from 'react'
import styled, { css } from 'styled-components'

const Wrapper = styled.div<{
  $startAdornment?: string
  $endAdornment?: string
  disabled?: boolean
  $paddingLeft?: number
  $maxWidth?: number
}>`
  margin-bottom: 0 !important;
  display: flex;
  align-items: center;
  width: min-content;
  input {
    min-width: 100px !important;
  }
  ${(_) =>
    _.$startAdornment &&
    css`
      padding-left: 10px;
      input {
        margin-left: 6px;
      }
    `}
  ${(_) =>
    _.$endAdornment &&
    css`
      padding-right: 10px;
      input {
        margin-right: 6px;
      }
    `}
`
Wrapper.defaultProps = {
  $paddingLeft: 0,
  $maxWidth: 100,
}

export interface InputAdornentProps {
  disabled?: boolean
  paddingLeft?: number
  startAdornment?: string
  endAdornment?: string
  maxWidth?: number
}

export const InputAdornent: React.FC<InputAdornentProps> = ({
  startAdornment,
  endAdornment,
  ...props
}) => {
  return (
    <Wrapper
      $startAdornment={startAdornment}
      $endAdornment={endAdornment}
      disabled={props.disabled}
      $paddingLeft={props.paddingLeft}
      $maxWidth={props.maxWidth}
    >
      {!!startAdornment && <span>{startAdornment}</span>}
      {props.children}
      {!!endAdornment && <span>{endAdornment}</span>}
    </Wrapper>
  )
}

export default InputAdornent
