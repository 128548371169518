import * as React from 'react'
import {
  generatePath,
  matchPath,
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router'
import { Icons } from '../../assets'
import { corporationApi } from '../../api/services'
import { routes } from '../../routes'
import AppSpinner from '../../shared/components/Spinner/AppSpinner'
import LocationConfiguration from '../Payments/LocationConfiguration'
import { LocationList } from './LocationList'
import Translations from '../../utils/translations'
import i18n from '../../i18n'
import * as S from './Styled'
import SearchContext from '../../context/SearchContext'

interface MatchParams {
  corporationId: string
  locationId?: string
}

type LocationProps = RouteComponentProps<MatchParams>

interface LocationState {
  name: string
  locationName?: string
  isLoading: boolean
}

class Corporation extends React.Component<LocationProps, LocationState> {
  static contextType = SearchContext
  constructor(props: LocationProps) {
    super(props)

    this.state = {
      name: '',
      locationName: undefined,
      isLoading: false,
    }
  }

  componentDidMount() {
    this.loadCorporationName()
  }

  componentDidUpdate() {
    this.clearLocationName()
  }

  loadCorporationName = async () => {
    this.setState({
      isLoading: true,
    })

    const corporationId = parseInt(this.props.match.params.corporationId)

    const result = corporationId
      ? await corporationApi.getCorporationName(corporationId)
      : undefined

    if (!result || !result.success) {
      this.props.history.push(routes.root.BUSINESS)
      return
    }

    this.setState({
      name: result.data,
      isLoading: false,
    })
  }

  setLocationName = (name: string | undefined) => {
    this.setState({
      locationName: name,
    })
  }

  clearLocationName = () => {
    if (!this.state.locationName) {
      return
    }
    const isLocationConfigurationView = matchPath(
      this.props.location.pathname,
      {
        path: routes.location.MONTHLY_PARKING_FEES,
      }
    )

    if (!isLocationConfigurationView) {
      this.setState({
        locationName: undefined,
      })
    }
  }

  handleClick = (): void => {
    if (!!this.state.locationName) {
      const corpId = this.props.match.params.corporationId
      this.clearLocationName()
      this.props.history.push(
        generatePath(routes.corporation.LOCATION_LIST, {
          corporationId: corpId,
        })
      )
      return
    } else {
      this.props.history.push(routes.corporations.CORPORATIONS)
      this.context.setLocSearch('')
      return
    }
  }

  getPlace = (): string => {
    return !!this.state.locationName
      ? i18n.t(Translations.Location)
      : i18n.t(Translations.Corporation)
  }

  render() {
    return (
      <div className="container">
        <AppSpinner show={this.state.isLoading} />

        {!!this.state.name && (
          <>
            <S.TabsHeader className="tabs-header">
              <S.BackButton onClick={this.handleClick}>
                <img src={Icons.BackIcon} alt="back icon" />
                {i18n.t(Translations.BackToList, { place: this.getPlace() })}
              </S.BackButton>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <h3>{this.state.name}</h3>
                {!!this.state.locationName && (
                  <>
                    <S.TabsHeaderDivider />
                    <h3>{this.state.locationName}</h3>
                  </>
                )}
              </div>
            </S.TabsHeader>
            <Switch>
              <Route
                exact={true}
                path={routes.corporation.LOCATION_LIST}
                component={LocationList}
              />
              <Route
                path={routes.location.MONTHLY_PARKING_FEES}
                render={(props) => (
                  <LocationConfiguration
                    {...props}
                    setLocationName={this.setLocationName}
                  />
                )}
              />
              <Redirect to={routes.corporation.LOCATION_LIST} />
            </Switch>
          </>
        )}
      </div>
    )
  }
}

export default withRouter(Corporation)
