import * as React from 'react'
import i18n from '../../i18n'
import Translations from '../../utils/translations'
import CustomPopup from './CustomPopup'

interface ActionPopupProps {
  show: boolean
  isSuccess: boolean
  title: JSX.Element
  successIcon: string
  errorIcon: string
  successText: JSX.Element | string
  errorText: JSX.Element | string
  onClick: () => void
}

class ActionPopup extends React.Component<ActionPopupProps> {
  getContent = (): JSX.Element => {
    const {
      isSuccess,
      successIcon,
      errorIcon,
      successText,
      errorText,
    } = this.props

    return (
      <div className="col-xs-12 action-popup" style={{ textAlign: 'center' }}>
        <div className="row">
          <div className="col-xs-12">
            <img
              src={isSuccess ? successIcon : errorIcon}
              alt={isSuccess ? 'success icon' : 'error icon'}
            />
          </div>
        </div>
        <div className="row" style={{ marginTop: '20px' }}>
          <div className="col-xs-12">
            <label className="label-title" style={{ fontSize: '16px' }}>
              {isSuccess
                ? `${i18n.t(Translations.Success)}!`
                : `${i18n.t(Translations.Declined)}`}
            </label>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <p className="popup-text" style={{ fontSize: '14px' }}>
              {isSuccess ? successText : errorText}
            </p>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { show, title, onClick } = this.props
    return (
      <CustomPopup
        titleElement={title}
        content={this.getContent()}
        open={show}
        okBtnText={i18n.t(Translations.Ok)}
        onClick={onClick}
      />
    )
  }
}

export default ActionPopup
