import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory, useParams } from 'react-router'
import { APP_CONFIG } from '../../api/config'
import { integrationApi } from '../../api/services'
import { Icons } from '../../assets'
import { AppContext } from '../../context'
import { setIsSpinning } from '../../context/actions'
import Border from '../../Controls/Border'
import Content from '../../Controls/Content/Content'
import ContentHeader from '../../Controls/Content/ContentHeader'
import RadioButton from '../../Controls/CustomRadioButton/RadioButton'
import CustomPopup from '../../Controls/Popup/CustomPopup'
import InfoPopup from '../../Controls/Popup/InfoPopup'
import PopupContent from '../../Controls/Popup/PopupContent'
import T2PSwitch from '../../Controls/Switch/T2PSwitch'
import { routes } from '../../routes'
import Helper from '../../Utility/Helpers'
import Translations from '../../utils/translations'
import IntegrationSettingsContext from './IntegrationSettingsContext'
import { integrationSettingsReducer } from './integrationSettingsReducer'
import { buildStyles } from './styles'
import { T2IntegrationAreaType, T2IntegrationVersionType } from './types'
const IntegrationSettings = () => {
  const { contextDispatch } = useContext(AppContext)
  const { t } = useTranslation()
  const classes = buildStyles()
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const history = useHistory()
  const { locationId, corporationId } = useParams<{
    locationId: string
    corporationId: string
  }>()
  const [state, dispatch] = React.useReducer(integrationSettingsReducer, {
    t2Integrations: [],
    enforcementIntegrations: [],
  })

  const { t2Integrations, enforcementIntegrations } = state

  const setT2Integrations = React.useCallback(
    (t2Integrations: T2IntegrationAreaType[]) => {
      dispatch({ type: 'set_t2_integrations', t2Integrations })
    },
    [dispatch]
  )

  const [getversionNumber, setVersionNumber] = React.useState<number | null>(
    null
  )
  const [getareaId, setAreaId] = React.useState<number | null>(null)
  const [showIntegrationPopup, setShowIntegrationPopup] = React.useState(false)
  const [invalidEmail, setInvalidEmail] = React.useState<string>()
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false)
  const handleT2IntegrationEnabled = (areaId: number, checked: boolean) => {
    const copy = [...t2Integrations]
    const foundIndex = copy.findIndex((x) => x.areaId === areaId)
    copy[foundIndex].isEnabled = checked
    setT2Integrations(copy)
  }

  const handleT2IntegrationInputChange = (
    areaId: number,
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setIsSubmitted(false)
    const { value, name } = event.target
    const copy = [...t2Integrations]
    const foundIndex = copy.findIndex((x) => x.areaId === areaId)
    copy[foundIndex][name] = value
    setT2Integrations(copy)
  }
  const showT2V2IntegrationPopup = (
    areaId: number,
    version: T2IntegrationVersionType
  ) => {
    setAreaId(areaId)
    setVersionNumber(version)
    setShowIntegrationPopup(true)
  }

  const handleT2IntegrationRadioChange = (
    areaId: number | null,
    version: number | null
  ) => {
    setIsSubmitted(false)
    const copy = [...t2Integrations]
    const foundIndex = copy.findIndex((x) => x.areaId === areaId)
    copy[foundIndex].integrationVersion = version || 0
    setT2Integrations(copy)
  }
  const handlePopupOkClick = () => {
    handleT2IntegrationRadioChange(getareaId, getversionNumber)
    setAreaId(null)
    setVersionNumber(null)
    setShowIntegrationPopup(false)
  }
  const handlePopupCancelClick = () => {
    setAreaId(null)
    setVersionNumber(null)
    setShowIntegrationPopup(false)
  }
  const back = React.useCallback(() => {
    history.push(
      generatePath(routes.corporation.LOCATION_LIST, {
        corporationId,
      })
    )
  }, [history, corporationId])

  const validateEmails = (emailsWithoutSplit: string) => {
    if (!emailsWithoutSplit) {
      return false
    }

    const emails = emailsWithoutSplit.split(';')
    let isEmailValid = true

    emails.some((email) => {
      isEmailValid = Helper.EmailValidationRegex.test(email)
      if (!isEmailValid) {
        setInvalidEmail(email)
        return isEmailValid
      }
      return false
    })

    return isEmailValid
  }
  const loadData = async () => {
    setIsSpinning(contextDispatch, true)

    const result = await integrationApi.getIntegrationModulesSettings(
      parseInt(locationId)
    )
    if (result) {
      const integrationSettings = {
        t2Integrations: result.data.t2Integrations,
        enforcementIntegrations: result.data.enforcementIntegrations,
      }
      dispatch({ type: 'set_settings', settings: integrationSettings })
    }
    setIsSpinning(contextDispatch, false)
  }
  const verifyT2ConnectionV2 = async (areaId: number, locationId: string) => {
    const data = await integrationApi.VerifyT2ConnectionV2(locationId)
    const copy = [...t2Integrations]
    const foundIndex = copy.findIndex((x) => x.areaId === areaId)
    copy[foundIndex].locationIdValid = data?.locationIdValid
    setT2Integrations(copy)
  }
  const verifyT2Connection = async (
    areaId: number,
    accountName: string,
    signatureKey: string,
    t2RegionName: string
  ) => {
    const data = await integrationApi.VerifyT2Connection(
      accountName,
      signatureKey,
      t2RegionName
    )
    const copy = t2Integrations ? [...t2Integrations] : []
    const foundIndex = copy.findIndex((x) => x.areaId === areaId)
    copy[foundIndex].regionValid = data?.regionValid
    copy[foundIndex].accountAndSignatureKeyValid =
      data?.accountAndSignatureKeyValid
    setT2Integrations(copy)
  }
  const handleSave = React.useCallback(async () => {
    setIsSubmitted(true)

    const errors = t2Integrations.some((el) => {
      el.areEmailsValid = validateEmails(el.reportEmails)

      return (
        el.isEnabled &&
        ((el.integrationVersion === 0 &&
          (!el.signatureKey ||
            !el.t2RegionName ||
            !el.accountName ||
            !el.areEmailsValid)) ||
          (el.integrationVersion === 1 && !el.locationId))
      )
    })

    if (!errors && t2Integrations && enforcementIntegrations) {
      setIsSpinning(contextDispatch, true)
      const result = await integrationApi.saveIntegrationModulesSettings(
        parseInt(locationId),
        t2Integrations,
        enforcementIntegrations
      )
      setIsSpinning(contextDispatch, false)
      if (result && result.success) {
        setIsPopupOpen(true)
        // back()
      }
      setIsSubmitted(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t2Integrations, enforcementIntegrations])

  const contextValue = React.useMemo(
    () => ({ state, dispatch }),
    [state, dispatch]
  )

  const popupTitle = React.useMemo(
    () => (
      <div className="col-xs-12">
        <img alt="icon info" src={Icons.Info} className={classes.iconInfo} />
        <h3>{t(Translations.Attention)}</h3>
      </div>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const popupContent = React.useMemo(
    () => (
      <PopupContent.Content>
        <PopupContent.Row>
          <PopupContent.Label>
            {t(Translations.T2V2Enabling)}
          </PopupContent.Label>
        </PopupContent.Row>
        <PopupContent.Row>
          <PopupContent.Label>
            <ul className={classes.listItem}>
              {t(Translations.T2V2DeleteCoupons)}
            </ul>
          </PopupContent.Label>
        </PopupContent.Row>
        <PopupContent.Row>
          <PopupContent.Label>
            <ul className={classes.listItem}>
              {t(Translations.T2V2DeleteFee)}
            </ul>
          </PopupContent.Label>
        </PopupContent.Row>
        <PopupContent.Row>
          <PopupContent.Label>
            {t(Translations.T2V2TakeAction)}
          </PopupContent.Label>
        </PopupContent.Row>
      </PopupContent.Content>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  React.useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId])

  return (
    <IntegrationSettingsContext.Provider value={contextValue}>
      <Content className="col-centered">
        <ContentHeader
          imgSrc={Icons.Integration}
          text={t(Translations.SystemIntegrations)}
        />
        <div className="payment-wrapper">
          {!!t2Integrations &&
            t2Integrations.map((item, i) => (
              <Border key={item.areaId}>
                <div className="content-row">
                  <div>
                    <label className="area-label">{item.areaName}</label>
                  </div>
                  <div>
                    <div>
                      <T2PSwitch
                        onChange={(e, checked) => {
                          handleT2IntegrationEnabled(item.areaId, checked)
                        }}
                        selected={item.isEnabled}
                        title={t(Translations.EnableT2Integration)}
                      />
                    </div>
                  </div>
                  {item.isEnabled && (
                    <div>
                      <div className={'radio-button-group'}>
                        <RadioButton
                          onClick={() => {
                            handleT2IntegrationRadioChange(item.areaId, 0)
                          }}
                          checked={item.integrationVersion === 0}
                          disabled={!APP_CONFIG.t2IntegrationV1Enabled}
                        >
                          {t(Translations.VersionV1)}
                        </RadioButton>
                        <RadioButton
                          onClick={() => {
                            showT2V2IntegrationPopup(item.areaId, 1)
                          }}
                          checked={item.integrationVersion === 1}
                          disabled={!APP_CONFIG.t2IntegrationV2Enabled}
                        >
                          {t(Translations.VersionV2)}
                        </RadioButton>
                      </div>
                      {item.integrationVersion === 0 && (
                        <>
                          <div className={`content-row ${classes.contentRow}`}>
                            <label>{t(Translations.T2RegionName)}</label>
                            <input
                              onChange={(e) =>
                                handleT2IntegrationInputChange(item.areaId, e)
                              }
                              name="t2RegionName"
                              className="form-control"
                              value={item.t2RegionName ?? ''}
                            />
                            <div className="error-div">
                              <label
                                className={`label_Invalid ${classes.labelInvalid}`}
                              >
                                {!!isSubmitted &&
                                  item.integrationVersion === 0 &&
                                  !item.t2RegionName &&
                                  t(Translations.Required)}
                              </label>
                            </div>
                          </div>
                          <div className={`content-row ${classes.noMarginTop}`}>
                            <label>{t(Translations.AccountName2)}</label>
                            <input
                              onChange={(e) =>
                                handleT2IntegrationInputChange(item.areaId, e)
                              }
                              name="accountName"
                              className="form-control"
                              value={item.accountName ?? ''}
                            />
                            <div className="error-div">
                              <label
                                className={`label_Invalid ${classes.labelInvalid}`}
                              >
                                {!!isSubmitted &&
                                  item.integrationVersion === 0 &&
                                  !item.accountName &&
                                  t(Translations.Required)}
                              </label>
                            </div>
                          </div>
                          <div className={`content-row ${classes.noMarginTop}`}>
                            <label>{t(Translations.Email)}</label>
                            <input
                              onChange={(e) =>
                                handleT2IntegrationInputChange(item.areaId, e)
                              }
                              name="reportEmails"
                              className="form-control"
                              value={item.reportEmails ?? ''}
                            />
                            <label style={{ marginTop: 2 }}>
                              {t(Translations.EmailEnterMultiple)}
                            </label>
                            <div className="error-div">
                              <label
                                className={`label_Invalid ${classes.labelInvalidSmall}`}
                              >
                                {!!isSubmitted &&
                                  item.integrationVersion === 0 &&
                                  !item.areEmailsValid &&
                                  t(Translations.EmailNotValid) +
                                    ' ' +
                                    (invalidEmail === undefined
                                      ? ''
                                      : invalidEmail)}
                              </label>
                            </div>
                          </div>
                          <div className={`content-row ${classes.noMarginTop}`}>
                            <label>{t(Translations.SignatureKey)}</label>
                            <input
                              onChange={(e) =>
                                handleT2IntegrationInputChange(item.areaId, e)
                              }
                              name="signatureKey"
                              className="form-control"
                              value={item.signatureKey ?? ''}
                            />
                            <div className="error-div">
                              <label
                                className={`label_Invalid ${classes.labelInvalid}`}
                              >
                                {!!isSubmitted &&
                                  item.integrationVersion === 0 &&
                                  !item.signatureKey &&
                                  t(Translations.Required)}
                              </label>
                            </div>
                            <div
                              className={`content-row ${classes.noMarginTop} ${classes.noMarginLeft}`}
                            >
                              <button
                                onClick={() =>
                                  verifyT2Connection(
                                    item.areaId,
                                    item.accountName,
                                    item.signatureKey,
                                    item.t2RegionName
                                  )
                                }
                                className="saveBtn text-uppercase btn-small"
                                disabled={
                                  !item.signatureKey ||
                                  !item.t2RegionName ||
                                  !item.accountName
                                }
                              >
                                {t(Translations.VerifyConnection)}
                              </button>
                            </div>
                            <div className="error-div">
                              <label
                                className={`label_Invalid ${classes.labelInvalid}`}
                              >
                                {item.accountAndSignatureKeyValid != null &&
                                  item.accountAndSignatureKeyValid === true &&
                                  item.regionValid != null &&
                                  item.regionValid === false &&
                                  t(Translations.T2RegionInvalid)}
                              </label>
                              <label
                                className={`label_Valid ${classes.labelInvalid}`}
                              >
                                {item.regionValid &&
                                  t(Translations.T2RegionValid)}
                              </label>
                            </div>
                            <div className="error-div">
                              <label
                                className={`label_Invalid ${classes.labelInvalid}`}
                              >
                                {item.accountAndSignatureKeyValid != null &&
                                  item.accountAndSignatureKeyValid === false &&
                                  t(Translations.T2AccountAndSignatureInvalid)}
                              </label>
                              <label
                                className={`label_Valid ${classes.labelInvalid}`}
                              >
                                {item.accountAndSignatureKeyValid &&
                                  t(Translations.T2AccountAndSignatureValid)}
                              </label>
                            </div>
                          </div>
                        </>
                      )}
                      {item.integrationVersion === 1 && (
                        <div className={`content-row ${classes.noMarginTop}`}>
                          <label>{t(Translations.T2LocationId)}</label>
                          <input
                            onChange={(e) =>
                              handleT2IntegrationInputChange(item.areaId, e)
                            }
                            name="locationId"
                            className="form-control"
                            value={item.locationId ?? ''}
                          />
                          <div className="error-div">
                            <label
                              className={`label_Invalid ${classes.labelInvalid}`}
                            >
                              {!!isSubmitted &&
                                item.integrationVersion === 1 &&
                                !item.locationId &&
                                t(Translations.Required)}
                            </label>
                          </div>
                          <div
                            className="content-row"
                            style={{ marginTop: 0, marginLeft: 0 }}
                          >
                            <button
                              onClick={() =>
                                verifyT2ConnectionV2(
                                  item.areaId,
                                  item.locationId
                                )
                              }
                              className="saveBtn text-uppercase btn-small"
                              disabled={!item.locationId}
                            >
                              {t(Translations.VerifyConnection)}
                            </button>
                          </div>
                          <div className="error-div">
                            <label
                              className="label_Invalid"
                              style={{ height: 14 }}
                            >
                              {item.locationIdValid === false &&
                                t(Translations.T2LocationIdInvalid)}
                            </label>
                            <label
                              className="label_Valid"
                              style={{ height: 14 }}
                            >
                              {item.locationIdValid &&
                                t(Translations.T2LocationIdValid)}
                            </label>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Border>
            ))}
        </div>
      </Content>

      <div className="buttons center">
        <button className="cancelBtn text-uppercase" onClick={back}>
          {t(Translations.Cancel)}
        </button>

        <button className="saveBtn text-uppercase" onClick={handleSave}>
          {t(Translations.Save)}
        </button>
      </div>
      <CustomPopup
        open={showIntegrationPopup}
        onCancel={handlePopupCancelClick}
        titleElement={popupTitle}
        content={popupContent}
        onClick={handlePopupOkClick}
        okBtnText={Translations.Confirm}
      />
      <InfoPopup
        show={isPopupOpen}
        text={t(Translations.SAVED_SUCCESSFULLY)}
        onClick={() => {
          setIsPopupOpen(false)
          loadData()
        }}
      />
    </IntegrationSettingsContext.Provider>
  )
}

export default IntegrationSettings
