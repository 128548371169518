import {
  GetPaymentConfigModel,
  ProcessingFeeAmountConfigModel,
} from '../../../api/services/payments/types'
import {
  ConfigurationSource,
  FeesType,
  Nacha,
  T2PProcessingFeeAmountConfigPaymentType,
} from '../../../Enums/Enums'
import { PaymentType, PayOrAcr } from '../ProcessingFeeConfiguration'
import {
  Fee,
  PartialPaymentsState,
  PaymentConfig,
  PaymentsState,
  ProcessingFeesState,
} from '../types'
import * as apiToState from './apiToState'

export const initialPaymentNumbers: Fee = {
  enabled: false,
  selected: FeesType.Percentage,
  percentage: 0,
  parker: 0,
  flat: 0,
  maximumValue: undefined,
  name: '',
  mixed: {
    percentage: 0,
    flat: 0,
  },
  roundUp: {
    value: 0,
    enabled: false,
  },
}
const initialPayments: PaymentConfig = {
  Id: '',
  accountName: '',
  enabled: true,
  pay: initialPaymentNumbers,
  acr: initialPaymentNumbers,
}

export const initialState = (locationId: string): PaymentsState => {
  return {
    locationId: parseInt(locationId),
    nacha: Nacha.DontUse,
    T2PPaymentConfigId: '',
    routingNumber: '',
    receiverName: '',
    senderId: '',
    accountNachaDfiNumber: '',
    accountType: undefined,
    redirectURL: '',
    merchantSwitch: 0,
    selectedMerchant: '',
    selectedMerchantECheck: '',
    senderIdACR: '',
    routingNumberACR: '',
    receiverNameACR: '',
    nachaACR: Nacha.Use,
    accountTypeACR: undefined,
    redirectURLACR: '',
    accountNachaDfiNumberACR: '',
    merchantList: [],
    merchantListECheck: [],
    acrSettingsId: undefined,
    creditCardPayments: { ...initialPayments },
    achPayments: { ...initialPayments },
    convenienceFeePayments: { ...initialPayments },
    configurationSource: ConfigurationSource.T2P,
  }
}

type MapApiProcessingFees = <T>(
  feeAmounConfig: ProcessingFeeAmountConfigModel[],
  object: T
) => T & ProcessingFeesState
const mapApiProcessingFees: MapApiProcessingFees = (feeAmounConfig, object) => {
  const ACHProcessingFee = apiToState.getProcessingFee('ACH', feeAmounConfig)
  const ACHAcrProcessingFee = apiToState.getProcessingFee(
    'ACHACR',
    feeAmounConfig
  )
  const creditCardProcessngFee = apiToState.getProcessingFee(
    'CreditCard',
    feeAmounConfig
  )
  const creditCardAcrProcessingFee = apiToState.getProcessingFee(
    'CreditCardACR',
    feeAmounConfig
  )
  const achPayments = ACHProcessingFee
    ? {
        Id: ACHProcessingFee.id,
        accountName: ACHProcessingFee.payPalAccountName,
        pay: apiToState.getVendorFee(ACHProcessingFee) || initialPaymentNumbers,
        acr:
          apiToState.getVendorFee(ACHAcrProcessingFee) || initialPaymentNumbers,
        enabled: ACHProcessingFee.isEnabled === 1,
      }
    : initialPayments

  const creditCardPayments = creditCardProcessngFee
    ? {
        Id: creditCardProcessngFee.id,
        accountName: creditCardProcessngFee.payPalAccountName,
        pay:
          apiToState.getVendorFee(creditCardProcessngFee) ||
          initialPaymentNumbers,
        acr:
          apiToState.getVendorFee(creditCardAcrProcessingFee) ||
          initialPaymentNumbers,
        enabled: creditCardProcessngFee.isEnabled === 1,
      }
    : initialPayments

  const pay = apiToState.getConvenienceFeeNumbers([
    creditCardProcessngFee,
    ACHProcessingFee,
  ])
  const acr = apiToState.getConvenienceFeeNumbers([
    creditCardAcrProcessingFee,
    ACHAcrProcessingFee,
  ])

  const convenienceFeePayments = {
    Id: '',
    accountName: '',
    pay,
    acr,
    enabled: apiToState.isConvenienceFeeEnabled([pay, acr]),
  }
  return { ...object, convenienceFeePayments, creditCardPayments, achPayments }
}

export const mapPartialApiModelToState = (
  api: GetPaymentConfigModel,
  locationId: number
): PartialPaymentsState => {
  const { CreditCard, ACH, PaymentGatewayCreditCard, PaymentGatewayACH } =
    T2PProcessingFeeAmountConfigPaymentType
  const apiModel = api.t2PPaymentConfigModel
  const ccMerchant = apiToState.getSelectedCarrierId(api, [
    CreditCard,
    PaymentGatewayCreditCard,
  ])
  const achMerchant = apiToState.getSelectedCarrierId(api, [
    ACH,
    PaymentGatewayACH,
  ])
  const merchantList = apiModel.merchantList.map((el) => {
    const typeSetting =
      apiModel.paymentConfig.t2PPaymentTypeSettingsConfig.find(
        (el1) => el1.carrierId === el.value
      )
    if (typeSetting) {
      return {
        ...el,
        paymentGatewayRedirectUsed: typeSetting.paymentGatewayRedirectUsed,
        nachaConfigurationId: typeSetting.nachaConfigurationId,
        nachaBillingSetting: typeSetting.nachaBillingSetting,
      }
    }
    return el
  })
  const merchantListECheck = apiModel.merchantListECheck.map((el) => {
    const typeSetting =
      apiModel.paymentConfig.t2PPaymentTypeSettingsConfig.find(
        (el1) => el1.carrierId === el.value
      )
    if (typeSetting) {
      return {
        ...el,
        paymentGatewayRedirectUsed: typeSetting.paymentGatewayRedirectUsed,
        nachaConfigurationId: typeSetting.nachaConfigurationId,
        nachaBillingSetting: typeSetting.nachaBillingSetting,
      }
    }
    return el
  })

  let object: PartialPaymentsState = {
    locationId: locationId,
    selectedMerchant: ccMerchant?.merchantId,
    selectedMerchantECheck: achMerchant?.merchantId,
    returnedItemFee: !achMerchant ? 0 : achMerchant.fineFee,
    merchantList,
    merchantListECheck,
    T2PPaymentConfigId: apiModel.paymentConfig.id,
    accountNachaDfiNumber: api.paymentNachaConfig.nachaDFIAccountNumber,
    accountType: apiModel.paymentConfig.type,
    nacha: api.paymentNachaConfig.isActive ? Nacha.Use : Nacha.DontUse,
    receiverName: api.paymentNachaConfig.nachaIndividualName,
    routingNumber:
      api.paymentNachaConfig.nachaReceivingDFIIdentification +
      api.paymentNachaConfig.nachaCheckDigit,
    senderId: api.paymentNachaConfig.nachaSenderID,
    redirectURL: api.paymentNachaConfig.redirectURL,
    accountTypeACR: apiModel.paymentConfig.type,
    accountNachaDfiNumberACR: undefined,
    acrSettingsId: undefined,
    merchantSwitch: undefined,
    nachaACR: undefined,
    receiverNameACR: undefined,
    redirectURLACR: undefined,
    routingNumberACR: undefined,
    senderIdACR: undefined,
    configurationSource:
      apiModel.paymentConfig.t2PPaymentTypeSettingsConfig[0]?.configSource ??
      ConfigurationSource.T2P,
  }

  if (!!api.paymentACRNachaConfig) {
    object.accountNachaDfiNumberACR =
      api.paymentACRNachaConfig.nachaDFIAccountNumber
    object.nachaACR = api.paymentACRNachaConfig.isActive
      ? Nacha.Use
      : Nacha.DontUse
    object.receiverNameACR = api.paymentACRNachaConfig.nachaIndividualName
    object.routingNumberACR =
      api.paymentACRNachaConfig.nachaReceivingDFIIdentification +
      api.paymentACRNachaConfig.nachaCheckDigit
    object.senderIdACR = api.paymentACRNachaConfig.nachaSenderID
    object.redirectURLACR = api.paymentACRNachaConfig.redirectURL
  }
  return object
}
export const mapApiModelToState = (
  api: GetPaymentConfigModel,
  locationId: number
): PaymentsState => {
  const partialState = mapPartialApiModelToState(api, locationId)
  const fullState = mapApiProcessingFees(
    api.t2PPaymentConfigModel.paymentConfig.t2PProcessingFeeAmountConfig,
    partialState
  )
  return fullState
}

export const getPaymentParamName = (paymentType: PaymentType) => {
  const names: Record<
    PaymentType,
    keyof Pick<
      PaymentsState,
      'creditCardPayments' | 'convenienceFeePayments' | 'achPayments'
    >
  > = {
    ACH: 'achPayments',
    ConvenienceFee: 'convenienceFeePayments',
    CreditCard: 'creditCardPayments',
  }
  return names[paymentType]
}
export const isPaySwitchedOff = (
  payOrAcr: PayOrAcr,
  propertyName: keyof Fee,
  value: unknown
) => payOrAcr === 'pay' && propertyName === 'enabled' && !value

export const getMerchantIds = (res: GetPaymentConfigModel) => {
  const selectedMerchant =
    res.t2PPaymentConfigModel.paymentConfig.t2PPaymentTypeSettingsConfig.find(
      (el) =>
        el.paymentType ===
          T2PProcessingFeeAmountConfigPaymentType['CreditCard'] ||
        el.paymentType ===
          T2PProcessingFeeAmountConfigPaymentType['PaymentGatewayCreditCard']
    )?.carrierId || res.t2PPaymentConfigModel.merchantList[0]?.value
  const selectedMerchantECheck =
    res.t2PPaymentConfigModel.paymentConfig.t2PPaymentTypeSettingsConfig.find(
      (el) =>
        el.paymentType === T2PProcessingFeeAmountConfigPaymentType['ACH'] ||
        el.paymentType ===
          T2PProcessingFeeAmountConfigPaymentType['PaymentGatewayACH']
    )?.carrierId || ''
  console.log({ selectedMerchant, selectedMerchantECheck, res })
  return { selectedMerchant, selectedMerchantECheck }
}
