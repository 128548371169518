import React from 'react'
import { useTranslation } from 'react-i18next'
import Translations from '../../utils/translations'
import { CurrencyInput } from '../../Controls/CustomInput/CurrencyInput'

export interface ReturnedItemFeeProps {
  value: number | undefined
  setValue: (v: number) => void
  id?: string
}

const ReturnedItemFee = ({ value, setValue, id }: ReturnedItemFeeProps) => {
  const { t } = useTranslation()

  return (
    <div className="content-row">
      <div className="width-100">
        <label>{t(Translations.ReturnedItemFee)}</label>
        <CurrencyInput
          value={value ?? 0}
          changed={setValue}
          maxLength={10}
          className="form-control"
          id={id}
        />
      </div>
    </div>
  )
}

export default ReturnedItemFee
