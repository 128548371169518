import * as React from 'react'

interface TabsProps extends React.Props<Tabs> {
  style?: React.CSSProperties
}

export class Tabs extends React.Component<TabsProps> {
  render() {
    return (
      <div className="tabs" style={this.props.style}>
        {this.props.children}
      </div>
    )
  }
}
