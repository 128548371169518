import Moment from 'moment'
import React from 'react'
import { useState } from 'react'
import DatePicker from 'react-datepicker'
import i18n from '../../i18n'
import Translations from '../../utils/translations'
import { GET_MONTHLY_PARKING_TRANSACTIONS } from '../../api/constants'
import T2PTimePicker from '../../Controls/TimePicker/T2PTimePicker'
import { MonthlyTransactionContext } from './MonthlyTransactionContext'
import MonthlyTransactionsPopup, {
  MonthlyTransactionsPopupType,
} from './MonthlyTransactionsPopup'
import { Icons } from '../../assets'
import { DataTable, Column } from '../../shared/components/DataTable/DataTable'
import ActionPopup from '../../Controls/Popup/ActionPopup'
import './MonthlyTransactions.scss'
import AppTooltip from '../../shared/components/AppTooltip'
import { T2PProcessingFeeAmountConfigPaymentType } from '../../Enums/Enums'
import { centsToDollars } from '../../utils/utils'
import NumberFormat from 'react-number-format'
import { resolveCurrencyFormat } from '@sumup/intl'

interface TransactionDates {
  date?: Moment.Moment
  hour?: Moment.Moment
}

interface AmountCharged {
  from?: number
  to?: number
}

interface ColumnButtonsFormatData {
  isChargeBack: boolean
  chargebackType: MonthlyTransactionsPopupType
  transactionDate: any
  chargebackAmount: number
  paymentType: number
}

const titlePopup = (
  <div className="col-xs-12">
    <img src={Icons.Price} alt="price icon" />
    <h3>{i18n.t(Translations.Refund)}</h3>
  </div>
)

const MonthlyTransactions = () => {
  const [search, setSearch] = useState('')
  const [refreshGrid, setRefreshGrid] = useState(false)
  const [amountCharged, setAmountCharged] = useState<AmountCharged>({
    from: undefined,
    to: undefined,
  })
  const [transactionFrom, setTransactionFrom] = useState<TransactionDates>({
    date: Moment().subtract(1, 'month'),
    hour: Moment().subtract(1, 'month'),
  })
  const [transactionTo, setTransactionTo] = useState<TransactionDates>({
    date: Moment(),
    hour: Moment(),
  })
  const [popup, setPopup] = useState<{
    show: boolean
    transaction: any | null
    type: MonthlyTransactionsPopupType
  }>({
    show: false,
    transaction: null,
    type: MonthlyTransactionsPopupType.Refund,
  })
  const [showInfoPopup, setShowInfoPopup] = useState(false)
  const [isSuccessResult, setIsSuccessResult] = useState(false)

  const handleSearch = () => {
    setRefreshGrid((prev) => !prev)
  }

  const handleSearchChange = (e: any) => {
    setSearch(e.target.value)
    if (e.keyCode === 13) {
      handleSearch()
    }
  }

  const handleHidePopupAndRefreshGrid = (result: boolean) => {
    setPopup({
      show: false,
      transaction: null,
      type: MonthlyTransactionsPopupType.Refund,
    })
    setIsSuccessResult(result)
    setShowInfoPopup(true)
  }

  const getDateTime = (
    date: Moment.Moment | null | undefined,
    time: Moment.Moment | null | undefined
  ) => {
    if (date != null) {
      const d = Moment(date).format('MM/DD/YY')
      const t = time != null ? Moment(time).format(' hh:mm A') : ''
      return Moment.utc(d + t).format()
    } else {
      return null
    }
  }

  const formatColumnCorporation = (data: {
    corporation: React.ReactNode
    location: React.ReactNode
  }) => (
    <span>
      <b>{data.corporation}</b>
      <br />
      {data.location}
    </span>
  )
  const formatColumnTransaction = (data: { transactionDate: any }) => (
    <span>{Moment(data.transactionDate).format('MM/DD/YY hh:mm A')}</span>
  )
  const formatColumnCustomer = (data: {
    customerFirstName: React.ReactNode
    customerLastName: React.ReactNode
    customerPhone: React.ReactNode
    customerEmail: React.ReactNode
  }) => (
    <span>
      {data.customerFirstName} {data.customerLastName} <br />{' '}
      {data.customerPhone} <br /> {data.customerEmail}{' '}
    </span>
  )
  const formatColumnNetDepo = (data: { netDepo: number }) => (
    <span>{centsToDollars(data.netDepo)}</span>
  )
  const formatColumnAbsFee = (data: { absFee: number }) => (
    <span>+ {centsToDollars(data.absFee)}</span>
  )
  const formatColumnNacha = (data: { nacha: number }) => (
    <span>{centsToDollars(data.nacha)}</span>
  )
  const formatColumnConvFee = (data: { convFee: number }) => (
    <span>+ {centsToDollars(data.convFee)}</span>
  )
  const formatColumnCharged = (data: { charged: number }) => (
    <span>{centsToDollars(data.charged)}</span>
  )

  const formatColumnButtons = (data: ColumnButtonsFormatData) => {
    if (data.paymentType === T2PProcessingFeeAmountConfigPaymentType.Check) {
      return null
    }

    if (data.isChargeBack) {
      return (
        <span>
          {data.chargebackType === MonthlyTransactionsPopupType.Refund ? (
            <>{i18n.t(Translations.Refund)}</>
          ) : (
            <>{i18n.t(Translations.Dispute)}</>
          )}
          <br />
          {Moment(data.transactionDate).format('MM/DD/YY')}
          <br />
          {centsToDollars(data.chargebackAmount)}
        </span>
      )
    } else {
      return (
        <>
          <AppTooltip
            title={<div>{i18n.t(Translations.Refund)}</div>}
            placement="bottom"
          >
            <img
              src={Icons.MarkAsPaid}
              onClick={() => {
                setPopup({
                  show: true,
                  transaction: data,
                  type: MonthlyTransactionsPopupType.Refund,
                })
              }}
              alt="mark as paid icon"
            />
          </AppTooltip>

          <AppTooltip
            title={<div>{i18n.t(Translations.Dispute)}</div>}
            placement="bottom"
          >
            <img
              src={Icons.Dispute}
              onClick={() => {
                setPopup({
                  show: true,
                  transaction: data,
                  type: MonthlyTransactionsPopupType.Dispute,
                })
              }}
              alt="dispute icon"
            />
          </AppTooltip>
        </>
      )
    }
  }
  const filter = {
    SearchText: search,
    AmountFrom: amountCharged.from
      ? (amountCharged.from * 100).toFixed()
      : amountCharged.from,
    AmountTo: amountCharged.to
      ? (amountCharged.to * 100).toFixed()
      : amountCharged.to,
    TransactionFrom: getDateTime(transactionFrom.date, transactionFrom.hour),
    TransactionTo: getDateTime(transactionTo.date, transactionTo.hour),
  }

  if (transactionFrom.date && transactionTo.date) {
    if (transactionTo.date.isBefore(transactionFrom.date)) {
      setTransactionTo((prev) => ({ ...prev, date: transactionFrom.date }))
    } else if (
      transactionFrom.date.isSame(transactionTo.date, 'day') &&
      transactionTo?.hour?.isBefore(transactionFrom.hour)
    ) {
      setTransactionTo((prev) => ({ ...prev, hour: transactionFrom.hour }))
    }
  }

  const format = resolveCurrencyFormat()

  return (
    <div className="content col-centered">
      <div className="header">
        <img src={Icons.Price} alt="price icon" />
        <h3>{i18n.t(Translations.MonthlyParkingTransactions)}</h3>
      </div>
      <div>
        <div className="fields no-wrap-over-1366">
          <div className="field field-search">
            <input
              className="form-control magnifier"
              placeholder={i18n.t(Translations.CorporationLocationCustomer)}
              style={{ maxWidth: '350px' }}
              value={search}
              onKeyUp={handleSearchChange}
              onChange={handleSearchChange}
              maxLength={30}
            />
          </div>
          <div className="field field-group field-185px">
            <div className="field field-100px no-margin-left">
              <label>{i18n.t(Translations.AmountCharged)}</label>
              <NumberFormat
                className="form-control"
                placeholder={i18n.t(Translations.FromDolarSign)}
                prefix="$"
                inputMode="text"
                maxLength={10}
                fixedDecimalScale={true}
                decimalScale={2}
                decimalSeparator={format?.decimalDelimiter ?? '.'}
                allowedDecimalSeparators={[',', '.']}
                thousandSeparator={format?.groupDelimiter ?? ','}
                allowNegative={false}
                allowLeadingZeros={false}
                name="dollarInput"
                value={amountCharged.from}
                onValueChange={(value) => {
                  setAmountCharged((prev) => ({
                    ...prev,
                    from: value.floatValue,
                  }))
                }}
                style={{ maxWidth: 85 }}
              />
            </div>
            <div className="field field-85px no-margin-left">
              <NumberFormat
                className="form-control"
                placeholder={i18n.t(Translations.ToDolarSign)}
                prefix="$"
                inputMode="text"
                maxLength={10}
                fixedDecimalScale={true}
                decimalScale={2}
                decimalSeparator={format?.decimalDelimiter ?? '.'}
                allowedDecimalSeparators={[',', '.']}
                thousandSeparator={format?.groupDelimiter ?? ','}
                allowNegative={false}
                allowLeadingZeros={false}
                name="dollarInput"
                value={amountCharged.to}
                onValueChange={(value) => {
                  setAmountCharged((prev) => ({
                    ...prev,
                    to: value.floatValue,
                  }))
                }}
                style={{ maxWidth: 85 }}
              />
            </div>
          </div>
          <div className="field field-group field-270px">
            <div className="field field-155px no-margin-left">
              <label>{i18n.t(Translations.TransactionFrom)}</label>
              <DatePicker
                selected={transactionFrom.date?.toDate()}
                startDate={transactionFrom.date?.toDate()}
                endDate={transactionTo.date?.toDate()}
                maxDate={Moment().toDate()}
                onChange={(date: Date) => {
                  setTransactionFrom((prev) => ({
                    ...prev,
                    date: Moment(date),
                    hour: Moment('00:00', 'HH:mm'),
                  }))
                }}
                className="form-control datePicker"
                placeholderText="MM/dd/yyyy"
              />
            </div>
            <div className="field field-100px">
              <T2PTimePicker
                selected={
                  transactionFrom.date == null
                    ? undefined
                    : Moment(transactionFrom.hour)
                }
                onChange={(date) => {
                  setTransactionFrom((prev) => ({ ...prev, hour: date }))
                }}
                placeholder="hh:mm AM"
                disabled={transactionFrom.date == null}
              />
            </div>
          </div>
          <div className="field field-group field-270px">
            <div className="field field-155px no-margin-left">
              <label>{i18n.t(Translations.TransactionTo)}</label>
              <DatePicker
                selected={transactionTo.date?.toDate()}
                startDate={transactionFrom.date?.toDate()}
                endDate={transactionTo.date?.toDate()}
                minDate={transactionFrom.date?.toDate()}
                maxDate={Moment().toDate()}
                onChange={(date: Date) => {
                  setTransactionTo((prev) => ({
                    ...prev,
                    date: Moment(date),
                    hour: Moment('23:59', 'HH:mm'),
                  }))
                }}
                className="form-control datePicker"
                placeholderText="MM/dd/yyyy"
              />
            </div>
            <div className="field field-100px">
              <T2PTimePicker
                selected={
                  transactionTo.date == null
                    ? undefined
                    : Moment(transactionTo.hour)
                }
                onChange={(date) => {
                  setTransactionTo((prev) => ({ ...prev, hour: date }))
                }}
                placeholder="hh:mm AM"
                disabled={transactionTo.date == null}
              />
            </div>
          </div>
          <div className="field field-button">
            <button
              type="button"
              className="cancelBtn text-uppercase search-button"
              onClick={handleSearch}
            >
              {i18n.t(Translations.Search)}
            </button>
          </div>
        </div>
      </div>
      <div className="list monthly-transactions">
        <DataTable
          url={GET_MONTHLY_PARKING_TRANSACTIONS}
          pageSize={10}
          sortField="TransactionDate"
          sortDirection="DESC"
          refreshValue={refreshGrid}
          filters={filter}
        >
          <Column
            keyName="Corporation"
            style={{ minWidth: '25%', width: '25%', maxWidth: '25%' }}
            headerName="Corporation / Location"
            format={formatColumnCorporation}
          />
          <Column
            keyName="TransactionDate"
            style={{ minWidth: '25%', width: '25%', maxWidth: '25%' }}
            headerName="Transaction / Approval code"
            format={formatColumnTransaction}
          />
          <Column
            keyName="CustomerLastName"
            style={{ minWidth: '25%', width: '25%', maxWidth: '25%' }}
            headerName="Customer"
            format={formatColumnCustomer}
          />
          <Column
            keyName="NetDepo"
            headerName="NET Depo"
            style={{
              minWidth: 80,
              width: 80,
              maxWidth: 80,
              textAlign: 'right',
            }}
            format={formatColumnNetDepo}
          />
          <Column
            keyName="AbsFee"
            headerName="Absor. Fee"
            style={{
              minWidth: 80,
              width: 80,
              maxWidth: 80,
              textAlign: 'right',
            }}
            format={formatColumnAbsFee}
          />
          <Column
            keyName="Nacha"
            headerName="NACHA"
            style={{
              minWidth: 80,
              width: 80,
              maxWidth: 80,
              textAlign: 'right',
            }}
            className="nacha-column-item"
            headerClassName="nacha-header"
            format={formatColumnNacha}
          />
          <Column
            keyName="ConvFee"
            headerName="Conv. Fee"
            style={{
              minWidth: 80,
              width: 80,
              maxWidth: 80,
              textAlign: 'right',
            }}
            format={formatColumnConvFee}
          />
          <Column
            keyName="Charged"
            headerName="Charged"
            style={{
              minWidth: 80,
              width: 80,
              maxWidth: 80,
              textAlign: 'right',
            }}
            className="charged-column-item"
            headerClassName="charged-header"
            format={formatColumnCharged}
          />
          <Column
            headerName=""
            format={formatColumnButtons}
            style={{ minWidth: '118px', textAlign: 'right' }}
          />
        </DataTable>
      </div>
      <MonthlyTransactionContext.Provider value={popup.transaction}>
        <MonthlyTransactionsPopup
          show={popup.show}
          onCancel={() => {
            setPopup((prev) => ({ ...prev, show: false }))
          }}
          hidePopupAndRefreshGrid={handleHidePopupAndRefreshGrid}
          type={popup.type}
        />
      </MonthlyTransactionContext.Provider>

      <ActionPopup
        show={showInfoPopup}
        isSuccess={isSuccessResult}
        title={titlePopup}
        successIcon={Icons.PaymentSuccess}
        errorIcon={Icons.PaymentError}
        successText={i18n.t(Translations.RefundCompleted)}
        errorText={i18n.t(Translations.RefundFailed)}
        onClick={() => {
          setShowInfoPopup(false)
          handleSearch()
        }}
      />
    </div>
  )
}

export default MonthlyTransactions
