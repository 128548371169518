import { AppContextAction, AppContextState } from '.'
import { SET_IS_LOGGED, SET_IS_SPINNING, SET_PROFILE_NAME } from './constants'

const reducer = (
  state: AppContextState,
  action: AppContextAction
): AppContextState => {
  switch (action.type) {
    case SET_IS_LOGGED:
      return {
        ...state,
        isLogged: action.isLogged,
      }
    case SET_PROFILE_NAME:
      return {
        ...state,
        profileName: action.profileName,
      }
    case SET_IS_SPINNING:
      return {
        ...state,
        isSpinning: action.isSpinning,
      }
    default:
      return state
  }
}

export default reducer
