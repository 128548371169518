import {
  PaymentTypeSettingsConfig,
  ProcessingFeeAmountConfigModel,
  SavePaymentConfigParams,
} from '../../../api/services/payments/params'
import { MerchantOption } from '../../../api/services/payments/types'
import { currencyInputValueToCents as toCents } from '../../../Controls/CustomInput/CurrencyInput'
import {
  ConfigurationSource,
  T2PProcessingFeeAmountConfigPaymentType,
} from '../../../Enums/Enums'
import { SavePaymentConfigModel } from '../types'
import { getProcessingFeeAmountConfig } from './feeAmountConfig'

export const getPaymentTypeSettingsConfig = (
  selectedMerchant: MerchantOption | undefined,
  selectedMerchantECheck: MerchantOption | undefined,
  returnedItemFee?: number,
  configSource: ConfigurationSource = ConfigurationSource.T2P
): PaymentTypeSettingsConfig[] => {
  const paymentsTypeSettings: PaymentTypeSettingsConfig[] = []

  if (selectedMerchant) {
    paymentsTypeSettings.push({
      PaymentType: selectedMerchant.paymentType,
      CarrierId: selectedMerchant.value,
      FineFee: undefined,
      ConfigSource: configSource,
      NachaConfigurationId: selectedMerchant.nachaConfigurationId,
      NachaBillingSetting: selectedMerchant.nachaBillingSetting,
      PaymentGatewayRedirectUsed:
        selectedMerchant.paymentGatewayRedirectUsed || false,
    })
  }
  if (selectedMerchantECheck) {
    paymentsTypeSettings.push({
      PaymentType: selectedMerchantECheck.paymentType,
      FineFee: returnedItemFee ? toCents(returnedItemFee) : undefined,
      CarrierId: selectedMerchantECheck.value,
      ConfigSource: configSource,
      NachaConfigurationId: selectedMerchantECheck.nachaConfigurationId,
      NachaBillingSetting: selectedMerchantECheck.nachaBillingSetting,
      PaymentGatewayRedirectUsed:
        selectedMerchantECheck.paymentGatewayRedirectUsed || false,
    })
  }

  return paymentsTypeSettings
}

export const getModelToSavePayments = (
  obj: SavePaymentConfigModel
): SavePaymentConfigParams => {
  const { creditCardPayments, ACHPayments, convenienceFeePayments } = obj
  const {
    CreditCardACR,
    CreditCard,
    Check,
    CreditBalance,
    ACH,
    ACHACR,
    PaymentGatewayACH,
    PaymentGatewayCreditCard,
  } = T2PProcessingFeeAmountConfigPaymentType
  const cc = getProcessingFeeAmountConfig({
    payments: creditCardPayments,
    cFeePayments: convenienceFeePayments,
    paymentsPayOrAcr: 'pay',
    cfeePayOrAcr: 'pay',
    paymentTypes: [CreditCard, Check, CreditBalance, PaymentGatewayCreditCard],
  })
  const ccArc = getProcessingFeeAmountConfig({
    payments: creditCardPayments,
    cFeePayments: convenienceFeePayments,
    paymentsPayOrAcr: 'acr',
    cfeePayOrAcr: 'acr',
    paymentTypes: [CreditCardACR],
  })
  const ach = getProcessingFeeAmountConfig({
    payments: ACHPayments,
    cFeePayments: convenienceFeePayments,
    paymentsPayOrAcr: 'pay',
    cfeePayOrAcr: 'pay',
    paymentTypes: [ACH, PaymentGatewayACH],
  })
  const achArc = getProcessingFeeAmountConfig({
    payments: ACHPayments,
    cFeePayments: convenienceFeePayments,
    paymentsPayOrAcr: 'acr',
    cfeePayOrAcr: 'acr',
    paymentTypes: [ACHACR],
  })

  const T2PProcessingFeeAmountConfig: ProcessingFeeAmountConfigModel[] = [
    cc,
    ccArc,
    ach,
    achArc,
  ]

  const object: SavePaymentConfigParams = {
    Id: obj.id,
    LocationId: obj.locationId,
    T2PPaymentTypeSettingsConfig: getPaymentTypeSettingsConfig(
      obj.selectedMerchant,
      obj.selectedMerchantECheck,
      obj.returnedItemFee,
      obj.configurationSource
    ),
    T2PProcessingFeeAmountConfig,
  }

  return object
}
