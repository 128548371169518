import 'dropzone/dist/min/dropzone.min.css'
import Numeral from 'numeral'
import * as React from 'react'
import { useContext, useState } from 'react'
import 'react-dropzone-component/styles/filepicker.css'
import { useTranslation } from 'react-i18next'
import Form from 'react-validation/build/form'
import Textarea from 'react-validation/build/textarea'
import { MakeChargebackTransactionParams } from '../../api/models/params'
import { paymentsApi } from '../../api/services'
import Validator from '../../shared/components/Validator/ValidationError'
import { Box } from '../../Controls/Box/Box'
import RadioButtonGroup from '../../Controls/CustomRadioButton/RadioButtonGroup'
import { RefundType } from '../../Enums/Enums'
import Translations from '../../utils/translations'
import { refundDisputeOptions } from './Common'
import { MonthlyTransactionContext } from './MonthlyTransactionContext'
import CustomCheckbox from '../../shared/components/CustomCheckbox/CustomCheckbox'
import { Icons } from '../../assets'
import { AppContext } from '../../context'
import { setIsSpinning } from '../../context/actions'
import CustomPopup from '../../Controls/Popup/CustomPopup'
import { centsToDollars } from '../../utils/utils'
import NumberFormat from 'react-number-format'
import { resolveCurrencyFormat } from '@sumup/intl'

export enum MonthlyTransactionsPopupType {
  Refund = 1,
  Dispute = 2,
}

interface MonthlyTransactionsPopupProps {
  show: boolean
  onCancel: () => void
  hidePopupAndRefreshGrid: (r: boolean) => void
  type: MonthlyTransactionsPopupType
}

const MonthlyTransactionsPopup = (props: MonthlyTransactionsPopupProps) => {
  const { contextDispatch } = useContext(AppContext)
  const transaction = useContext(MonthlyTransactionContext)
  const { t } = useTranslation()
  const [selectedOption, setSelectedOption] = useState(RefundType.Full)
  const [reason, setReason] = useState('')
  const [isBillBack, setIsBillBack] = useState(true)
  const [amount, setAmount] = useState(0)
  const [formInstance, setFormInstance] = useState<any>()

  if (!transaction) return null

  const handleMakeChargeback = async () => {
    if (Validator.isFormValid(formInstance)) {
      const params: MakeChargebackTransactionParams = {
        transactionId: transaction.id,
        chargebackType: props.type,
        chargebackPartType: selectedOption,
        amount: Numeral(Numeral(amount * 100).format('0')).value(),
        isBillBack: isBillBack,
        reason: reason,
      }
      setIsSpinning(contextDispatch, true)
      const result = await paymentsApi.makeChargebackTransaction(params)
      setIsSpinning(contextDispatch, false)

      if (result && result.success) {
        props.hidePopupAndRefreshGrid(true)
      } else {
        props.hidePopupAndRefreshGrid(false)
      }
    }
  }

  const titleDiv = (
    <div className="col-md-12">
      {props.type === MonthlyTransactionsPopupType.Refund ? (
        <>
          <img src={Icons.RefundGray} alt="refund icon" />
          <h3>{t(Translations.Refund)}</h3>
        </>
      ) : (
        <>
          <img src={Icons.DisputeGray} alt="dispute icon" />
          <h3>{t(Translations.Dispute)}</h3>
        </>
      )}
    </div>
  )

  // const dropzoneConfig: DropzoneComponentConfig = {
  //   iconFiletypes: [".pdf"],
  //   showFiletypeIcon: true,
  //   postUrl: "http://localhost/API.SmsValet/api/v1/File/UploadFiles", // it will be to change
  // };

  // const djsConfig = {
  //   dictDefaultMessage: t(Translations.DragAndDropPdf),
  // };
  const format = resolveCurrencyFormat()
  const onSubmit = (e: { preventDefault: () => any }) => e.preventDefault()
  const contentDiv = (
    <div className="popup-container col-sm-12">
      <Form ref={setFormInstance} className="col-xs-12" onSubmit={onSubmit}>
        <div className="popup-row">
          <RadioButtonGroup
            options={refundDisputeOptions}
            selected={selectedOption}
            onChange={(value) => {
              setSelectedOption(value)
            }}
            className="refund-options"
          />
        </div>
        <div className="popup-row">
          {selectedOption === RefundType.Full ? (
            <Box
              className="refund-box"
              number={centsToDollars(transaction.netDepo + transaction.absFee)}
              text={`+ ${centsToDollars(transaction.convFee)} convenience fee`}
            />
          ) : (
            <div className="popup-item">
              <label>{t(Translations.Amount)}</label>
              <NumberFormat
                className="form-control"
                prefix="$"
                inputMode="text"
                fixedDecimalScale={true}
                decimalScale={2}
                decimalSeparator={format?.decimalDelimiter ?? '.'}
                allowedDecimalSeparators={[',', '.']}
                thousandSeparator={format?.groupDelimiter ?? ','}
                allowNegative={false}
                allowLeadingZeros={false}
                name="dollarInput"
                value={amount}
                onValueChange={(value) => {
                  value.floatValue && setAmount(value.floatValue)
                }}
                style={{ maxWidth: 85 }}
              />
            </div>
          )}
        </div>
        <div className="popup-row">
          <div
            className="popup-item is-full-width"
            style={{ height: '100%', margin: 0 }}
          >
            <label>{t(Translations.Reason)}</label>
            <Textarea
              className="form-control"
              rows={5}
              style={{ resize: 'none', width: '100%', marginLeft: 0 }}
              onChange={(e: {
                target: { value: React.SetStateAction<string> }
              }) => {
                setReason(e.target.value)
              }}
              validations={[Validator.required]}
              value={reason}
            />
          </div>
        </div>
        <div className="popup-row">
          <CustomCheckbox
            handleCheckboxChange={(value) => {
              setIsBillBack(value)
            }}
            checked={isBillBack}
            title={t(Translations.BillBackToCompany)}
          />
        </div>
        {/* {props.type === MonthlyTransactionsPopupType.Dispute && (
                <div className="popup-row dispute-dropzone">
                    <DropzoneComponent
                        config={dropzoneConfig}
                        djsConfig={djsConfig}
                    />
                </div>
            )} */}
      </Form>
    </div>
  )

  return (
    <CustomPopup
      open={props.show}
      titleElement={titleDiv}
      onCancel={props.onCancel}
      maxWidth="sm"
      onClick={() => handleMakeChargeback()}
      okBtnText={
        props.type === MonthlyTransactionsPopupType.Refund
          ? t(Translations.Refund)
          : t(Translations.Dispute)
      }
      content={contentDiv}
    />
  )
}

export default MonthlyTransactionsPopup
