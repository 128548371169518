import * as React from 'react'
import { LabelValue } from '../../Models/DashboardTypes'
import RadioButton from './RadioButton'

interface RadioButtonGroupProps {
  options: Array<LabelValue>
  selected: any
  onChange: (value: any) => void
  className?: string
  disabled?: boolean
  id?: string
}

class RadioButtonGroup extends React.Component<RadioButtonGroupProps> {
  render() {
    return (
      <div
        className={
          'radio-button-group' +
          (this.props.className ? ` ${this.props.className}` : '')
        }
      >
        {this.props.options.map((o, i) => (
          <RadioButton
            key={i}
            onClick={() => {
              !this.props.disabled && this.props.onChange(o.value)
            }}
            checked={o.value === this.props.selected}
            additional={o.additional}
            disabled={this.props.disabled}
            id={`${this.props.id}-${i}`}
          >
            {o.label}
          </RadioButton>
        ))}
      </div>
    )
  }
}

export default RadioButtonGroup
