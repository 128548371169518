import * as React from 'react'
import { generatePath, RouteComponentProps } from 'react-router'
import { locationApi } from '../../api/services'
import { GetLocationsModel } from '../../api/services/location/types'
import { Icons } from '../../assets'
import i18n from '../../i18n'
import { routes } from '../../routes'
import { Column, DataTable } from '../../shared/components/DataTable/DataTable'
import Translations from '../../utils/translations'
import SearchContext, { SearchContextType } from '../../context/SearchContext'

interface MatchParams {
  corporationId: string
}

type LocationProps = {
  history: unknown
}

type LocationListProps = LocationProps & RouteComponentProps<MatchParams>

type LocationListState = {
  statement: string
  refresh: boolean
  locationName: string
}

export class LocationList extends React.Component<
  LocationListProps,
  LocationListState
> {
  static contextType = SearchContext
  constructor(
    props: LocationListProps | Readonly<LocationListProps>,
    context: SearchContextType
  ) {
    super(props)
    this.state = {
      statement: '',
      refresh: false,
      locationName: context.locSearch,
    }
  }

  componentDidMount() {}

  LocationNameChange = (e: { target: { value: any } }): void => {
    this.setState({ locationName: e.target.value })
  }

  searchClick = () => {
    this.setState({
      refresh: !this.state.refresh,
    })
    this.context.setLocSearch(this.state.locationName)
  }

  serachEnter = (e: { keyCode: number }) => {
    if (e.keyCode === 13) {
      this.setState({ refresh: !this.state.refresh })
      this.context.setLocSearch(this.state.locationName)
    }
  }

  handleCCConfgRedirect = (locationId: string): void => {
    this.props.history.push(
      generatePath(routes.location.MONTHLY_PARKING_FEES, {
        ...this.props.match.params,
        locationId,
      })
    )
  }

  formatLocationName = (data: GetLocationsModel) => {
    return (
      <span
        className="corporationName table-clickable"
        onClick={() => this.handleCCConfgRedirect(data.id.toString())}
      >
        {' '}
        {data.name}{' '}
        {'(' +
          data.guid.slice(0, 3) +
          '-' +
          data.guid.slice(3, 6) +
          '-' +
          data.guid.slice(6, 10) +
          ')'}
      </span>
    )
  }

  formatColumnAddress = (data: GetLocationsModel) => {
    return <span> {data.address} </span>
  }

  render() {
    const filter = {
      locationName: this.state.locationName,
      corporationId: parseInt(this.props.match.params.corporationId),
    }

    return (
      <div className="content col-centered">
        <div className="header">
          <img src={Icons.MenuLocationsBlack} alt="menu locations black icon" />

          <h3>{i18n.t(Translations.Locations)}</h3>

          <input
            className="form-control magnifier"
            onKeyUp={this.serachEnter}
            placeholder={i18n.t(Translations.LocationNameGuid)}
            style={{ maxWidth: '350px', marginLeft: 'auto' }}
            value={this.state.locationName}
            onChange={this.LocationNameChange}
          />
          <button
            className="cancelBtn text-uppercase"
            onClick={this.searchClick}
          >
            {i18n.t(Translations.Search)}
          </button>
        </div>

        <div className="list">
          <DataTable<GetLocationsModel[]>
            url={locationApi.getLocations}
            pageSize={10}
            sortField="Name"
            sortDirection="ASC"
            refreshValue={this.state.refresh}
            filters={filter}
          >
            <Column
              keyName="Name"
              style={{ width: '48%' }}
              headerName={i18n.t(Translations.LocationName)}
              format={this.formatLocationName}
            />
            <Column
              keyName="Address"
              style={{ width: '52%' }}
              headerName={i18n.t(Translations.Address)}
              format={this.formatColumnAddress}
            />
          </DataTable>
        </div>
      </div>
    )
  }
}
