import * as React from 'react'
import { Icons } from '../../assets'
import './RadioButton.scss'

interface RadioButtonProps {
  className?: string
  disabled?: boolean
  checked?: boolean
  onClick?: () => void
  children?: React.ReactNode
  additional?: JSX.Element
  id?: string
}

const RadioButton: React.FunctionComponent<RadioButtonProps> = (
  props: RadioButtonProps
) => {
  let imgSrc: string
  if (props.checked) {
    imgSrc = Icons.RadioButtonMarked
  } else if (props.disabled) {
    imgSrc = Icons.RadioButtonDisabled
  } else {
    imgSrc = Icons.RadioButtonEmpty
  }
  return (
    <div
      className={
        'radio-button-div' +
        (props.disabled ? ' disabled' : '') +
        (props.checked ? ' checked' : '')
      }
      onClick={props.onClick}
      id={props.id}
    >
      <img
        src={imgSrc}
        className={
          'radio-button' + (props.className ? ' ' + props.className : '')
        }
        alt=""
      />
      <label>{props.children}</label>
      {props.additional ? props.additional : null}
    </div>
  )
}

export default RadioButton
