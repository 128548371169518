import axios from '../..'
import {
  DataTableApiResult,
  DataTableParams,
} from '../../../shared/components/DataTable/DataTable'
import { GET_CORPORATIONS_LIST, GET_CORPORATION_NAME } from '../../constants'
import { ApiResultGeneric } from '../../types'
import { GetCorporationsModel } from './types'

const getCorporationName = async (
  corporationId: number
): Promise<ApiResultGeneric<string> | null> => {
  try {
    const params = {
      id: corporationId,
    }
    const result = await axios.get<ApiResultGeneric<string>>(
      GET_CORPORATION_NAME,
      { params }
    )
    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}

const getCorporations = async (
  params: DataTableParams
): Promise<DataTableApiResult<GetCorporationsModel[]> | null> => {
  try {
    const result = await axios.get<DataTableApiResult<GetCorporationsModel[]>>(
      GET_CORPORATIONS_LIST,
      {
        params,
      }
    )
    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}

const corporation = { getCorporationName, getCorporations }

export default corporation
