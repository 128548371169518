import { makeStyles, Theme, Tooltip, TooltipProps } from '@material-ui/core'
import React from 'react'

const useStylesBootstrap = makeStyles((theme: Theme) => ({
  root: { top: '-10' },
  arrow: {
    color: theme.palette.common.black,
  },
  tooltip: {
    backgroundColor: theme.palette.common.black,
    fontSize: 12,
  },
  tooltipPlacementBottom: {
    margin: '10px 0',
  },
}))

export interface AppTooltipProps extends TooltipProps {}

const AppTooltip = (props: AppTooltipProps) => {
  const classes = useStylesBootstrap()

  return <Tooltip arrow classes={classes} {...props} />
}

AppTooltip.defaultProps = {
  placement: 'bottom',
}

export default AppTooltip
