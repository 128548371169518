import * as React from 'react'

export interface SearchContextType {
  corpSearch: string
  setCorpSearch: React.Dispatch<React.SetStateAction<string>>
  locSearch: string
  setLocSearch: React.Dispatch<React.SetStateAction<string>>
}

const SearchContext = React.createContext<SearchContextType>(
  {} as SearchContextType
)

export const SearchProvider: React.FC = ({ children }) => {
  const [corpSearch, setCorpSearch] = React.useState('')
  const [locSearch, setLocSearch] = React.useState('')

  return (
    <SearchContext.Provider
      value={{
        corpSearch,
        locSearch,
        setCorpSearch,
        setLocSearch,
      }}
    >
      {children}
    </SearchContext.Provider>
  )
}

export default SearchContext
