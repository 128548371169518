import * as React from 'react'
import { useTranslation } from 'react-i18next'
import Translations from '../../utils/translations'
import { CurrencyInput } from '../../Controls/CustomInput/CurrencyInput'
import CustomCheckbox from '../../shared/components/CustomCheckbox/CustomCheckbox'

interface MaximumFeeValueProps {
  setValue: (v?: number) => void
  value?: number | null
  id?: string
}

const MaximumFeeValue = ({ value, setValue, id }: MaximumFeeValueProps) => {
  const { t } = useTranslation()
  const [lastValue, setLastValue] = React.useState(0)

  React.useEffect(() => {
    if (typeof value === 'number') setLastValue(value)
  }, [value])
  return (
    <div
      className="content-row"
      style={{
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
      }}
    >
      <CustomCheckbox
        checked={value !== undefined}
        title={t(Translations.MaxValue)}
        id={`${id}Box`}
        handleCheckboxChange={(v) =>
          !v ? setValue(undefined) : setValue(lastValue)
        }
      />
      <div className="width-100">
        <CurrencyInput
          id={`${id}Input`}
          value={typeof value === 'number' ? value : lastValue}
          changed={setValue}
          maxLength={10}
          className="form-control"
          disabled={value === undefined}
        />
      </div>
    </div>
  )
}

export default MaximumFeeValue
