import * as React from 'react'
import i18n from '../../../i18n'
import Translations from '../../../utils/translations'

export default class ValidationError {
  static isFormValid = (form: {
    validateAll: () => void
    getChildContext: () => {
      (): any
      new (): any
      _errors: { (): any; new (): any; length: number }
    }
  }) => {
    form.validateAll()
    return form.getChildContext()._errors.length === 0
  }

  static required = (value: {
    toString: () => {
      (): any
      new (): any
      trim: { (): { (): any; new (): any; length: any }; new (): any }
    }
  }) => {
    if (!value.toString().trim().length) {
      // We can return string or jsx as the 'error' prop for the validated Component
      return (
        <label className="label_Invalid">{i18n.t(Translations.Required)}</label>
      )
    }
  }

  static maxInputValue = (e: any, max: number) => {
    let value = e.target.value
    if (parseInt(value) > max) {
      value = max
    }

    e.target.value = value
  }
}
