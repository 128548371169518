import styled from 'styled-components'

const Border = styled.div`
  padding: 0 30px 30px;
  margin-bottom: 25px;

  border: 1px solid #e2e2e2;
  box-sizing: border-box;
`

export default Border
