import axios from '../..'
import { ApiResultBase, ApiResultGeneric } from '../../types'
import {
  GET_MONTHLY_CONFIGURATION,
  SAVE_MONTHLY_CONFIGURATION,
} from '../../constants'
import { SaveMonthyConfigurationApiParams } from '../../models/params'
import { GetMonthlyConfigurationApiModel } from './types'

const getMonthlyConfiguration = async (
  locationId: number
): Promise<ApiResultGeneric<GetMonthlyConfigurationApiModel> | null> => {
  try {
    const params = {
      Id: locationId,
    }
    const result = await axios.get<
      ApiResultGeneric<GetMonthlyConfigurationApiModel>
    >(GET_MONTHLY_CONFIGURATION, { params })

    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}

const saveMonthyConfiguration = async (
  params: SaveMonthyConfigurationApiParams
): Promise<ApiResultBase | null> => {
  try {
    const result = await axios.post<ApiResultBase>(
      SAVE_MONTHLY_CONFIGURATION,
      params
    )

    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}

const monthlyParking = { getMonthlyConfiguration, saveMonthyConfiguration }

export default monthlyParking
