import * as React from 'react'
import styled from 'styled-components'

interface ContentHeaderProps {
  imgSrc: string
  text: string
}

const StyledText = styled.h3`
  margin-right: 30px;
`

const ContentHeader: React.FunctionComponent<ContentHeaderProps> = ({
  imgSrc,
  text,
  children,
}) => {
  return (
    <div className="header">
      <img src={imgSrc} alt="" />
      <StyledText>{text}</StyledText>
      {children}
    </div>
  )
}

export const RightAligned = styled.div`
  margin-left: auto;
  display: flex;
`

export default ContentHeader
