import { RefundType } from '../../Enums/Enums'
import i18n from '../../i18n'
import { LabelValue } from '../../Models/DashboardTypes'
import Translations from '../../utils/translations'

export const refundDisputeOptions: Array<LabelValue> = [
  {
    label: i18n.t(Translations.Full),
    value: RefundType.Full,
  },
  {
    label: i18n.t(Translations.Partial),
    value: RefundType.Partial,
  },
]
