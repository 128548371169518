import { IntegrationModulesSettings } from '../../api/services/integration/types'
import {
  IntegrationSettingsContextState,
  T2IntegrationAreaType,
  EnforcementIntegrationAreaType,
} from './types'

export type IntegrationSettingsContextAction =
  | { type: 'set_settings'; settings: IntegrationModulesSettings }
  | {
      type: 'set_t2_integrations'
      t2Integrations: T2IntegrationAreaType[]
    }
  | {
      type: 'set_enforcement_integrations'
      enforcementIntegrations: EnforcementIntegrationAreaType[]
    }
export const integrationSettingsReducer = (
  state: IntegrationSettingsContextState,
  action: IntegrationSettingsContextAction
): IntegrationSettingsContextState => {
  switch (action.type) {
    case 'set_settings':
      return {
        ...state,
        ...action.settings,
      }
    case 'set_t2_integrations':
      return {
        ...state,
        t2Integrations: [...action.t2Integrations],
      }
    case 'set_enforcement_integrations':
      return {
        ...state,
        enforcementIntegrations: [...action.enforcementIntegrations],
      }
    default:
      return state
  }
}
