import * as React from 'react'
import {
  generatePath,
  matchPath,
  RouteComponentProps,
  withRouter,
} from 'react-router'

interface Props extends React.Props<Tab> {
  path: string
  exact?: boolean
  id?: string
}

type TabProps = Props & RouteComponentProps

class Tab extends React.Component<TabProps> {
  static defaultProps = {
    exact: false,
  }
  tabClicked = () => {
    this.props.history.push(
      generatePath(this.props.path, this.props.match.params)
    )
  }

  render() {
    const isActive = matchPath(this.props.location.pathname, {
      path: this.props.path,
      exact: this.props.exact,
    })
    return (
      <span
        className={'tab' + (isActive ? ' active' : '')}
        onClick={this.tabClicked}
        id={this.props.id}
      >
        {this.props.children}
      </span>
    )
  }
}

export default withRouter(Tab)
