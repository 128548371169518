import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch, useParams } from 'react-router'
import { locationApi } from '../../api/services'
import Tab from '../../Controls/Tabs/Tab'
import { Tabs } from '../../Controls/Tabs/Tabs'
import { routes } from '../../routes'
import Translations from '../../utils/translations'
import IntegrationSettings from '../Integrations/IntegrationSettings'
import PaymentsContainer from './PaymentsContainer'
import StartDateConfiguration from './StartDateConfiguration'
import * as S from './Styled'

interface LocationConfigurationProps {
  setLocationName: (name: string | undefined) => void
}

const LocationConfiguration = ({
  setLocationName,
}: LocationConfigurationProps) => {
  const { t } = useTranslation()
  const { locationId } = useParams<{ locationId: string }>()

  const loadLocationName = async () => {
    const id = parseInt(locationId)
    if (isNaN(id)) {
      setLocationName(undefined)
      return
    }

    const result = await locationApi.getLocationName(id)
    if (!result || !result.data || !result.success) {
      return
    }

    setLocationName(result.data)
  }

  React.useEffect(() => {
    loadLocationName()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId])

  return (
    <div>
      <div className="tabs-header">
        <Tabs>
          <Tab
            path={routes.payment.PAYMENT_CONFIGURATION}
            id="paymentConfigurationTab"
          >
            {t(Translations.PaymentConfiguration)}
          </Tab>
          <Tab
            path={routes.payment.PROCESSING_FEE_CONFIGURATION}
            id="processingFeeConfigurationTab"
          >
            {t(Translations.ProcessingFeeConfiguration)}
          </Tab>
          <Tab
            id="monthlyParkingConfigurationTab"
            path={routes.locationConfiguration.MONTHLY_PARKING_CONFIGURATION}
          >
            {t(Translations.MonthlyParkingConfiguration)}
          </Tab>
          <Tab
            id="integrationSettingsTab"
            path={routes.locationConfiguration.INTEGRATION_SETTINGS}
          >
            {t(Translations.IntegrationSettings)}
          </Tab>
        </Tabs>
      </div>
      <S.RoutesContainer>
        <Switch>
          <Route
            path={routes.locationConfiguration.PAYMENT}
            component={PaymentsContainer}
          />
          <Route
            path={routes.locationConfiguration.MONTHLY_PARKING_CONFIGURATION}
            component={StartDateConfiguration}
          />
          <Route
            path={routes.locationConfiguration.INTEGRATION_SETTINGS}
            component={IntegrationSettings}
          />
          <Redirect to={routes.payment.PAYMENT_CONFIGURATION} />
        </Switch>
      </S.RoutesContainer>
    </div>
  )
}

export default LocationConfiguration
