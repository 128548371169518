import { Grid, Paper } from '@material-ui/core'
import { useFormik } from 'formik'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import * as yup from 'yup'
import {
  setAuthHeader,
  setTokensInLocalStorage,
  setUserInLocalStorage,
} from '../../api/auth'
import { AccountLoginParams } from '../../api/models/params'
import { authApi } from '../../api/services'
import { fetchStatusIs, RequestStatus } from '../../api/types'
import { AppContext } from '../../context'
import {
  setIsLogged,
  setIsSpinning,
  setProfileName,
} from '../../context/actions'
import i18n from '../../i18n'
import { routes } from '../../routes'
import AppButton from '../../shared/components/AppButton'
import AppSpinner from '../../shared/components/Spinner/AppSpinner'
import AppTextField from '../../shared/components/AppTextField'
import Translations from '../../utils/translations'
import { buildStyles } from './styles'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface LoginProps {}

const Login = (props: LoginProps) => {
  const { state, contextDispatch } = useContext(AppContext)
  const classes = buildStyles()
  const history = useHistory()
  const { t } = useTranslation()

  const [requestStatus, setRequestStatus] = useState<RequestStatus>(
    RequestStatus.Idle
  )
  const { pending, rejected } = fetchStatusIs(requestStatus)

  const validationSchema = yup.object().shape({
    login: yup.string().required(t(Translations.REQUIRED)),
    password: yup.string().required(t(Translations.REQUIRED)),
  })

  const initialValues: AccountLoginParams = {
    login: '',
    password: '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setRequestStatus(RequestStatus.Pending)
      setIsSpinning(contextDispatch, true)
      const result = await authApi.login(values)
      if (result && result.success) {
        // let location = String(window.location)
        // location = location.substring(0, location.lastIndexOf('/Account/'))

        setTokensInLocalStorage(result.data.tokens)
        setUserInLocalStorage({
          profileName: result.data.profileName,
          role: result.data.role,
        })
        setAuthHeader(result.data.tokens.auth)
        setIsLogged(contextDispatch, true)
        setProfileName(contextDispatch, result.data.profileName)
        setIsSpinning(contextDispatch, false)
        history.push(routes.root.BUSINESS)
      } else {
        setIsSpinning(contextDispatch, false)
        setRequestStatus(RequestStatus.Rejected)
      }
    },
  })

  if (state.isLogged) history.push(routes.root.BUSINESS)

  return (
    <Paper className={classes.paper}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container className={classes.wrapper} spacing={1}>
          <Grid item xs={12}>
            <div className="row">
              <label className="titleLabel col-xs-12 col-form-label text-uppercase">
                {i18n.t(Translations.LoginHeader)}
              </label>
            </div>
          </Grid>

          <Grid item xs={12}>
            <AppTextField
              id="login"
              label={i18n.t(Translations.Login)}
              name="login"
              type="text"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.login}
              helperText={formik.errors.login}
              withoutBorderRadius
            />
          </Grid>

          <Grid item xs={12}>
            <AppTextField
              id="password"
              label={i18n.t(Translations.Password)}
              name="password"
              type="password"
              fullWidth
              onChange={formik.handleChange}
              value={formik.values.password}
              helperText={formik.errors.password}
              withoutBorderRadius
            />
          </Grid>

          {!!rejected && (
            <Grid item xs={12} container justifyContent="center">
              <label className="label_Invalid">
                {t(Translations.INCORRECT_LOGIN_DATA)}
              </label>
            </Grid>
          )}

          <Grid item xs={12} container justifyContent="center">
            <AppButton type="submit" minWidth={170}>
              {i18n.t(Translations.SignIn)}
            </AppButton>
          </Grid>
        </Grid>

        <AppSpinner show={pending} />
      </form>
    </Paper>
  )
}

export default Login
