import React, { Fragment } from 'react'
import Translations from '../../utils/translations'
import { useTranslation } from 'react-i18next'
import Dialog, { DialogProps } from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import AppButton from '../../shared/components/AppButton'
import DialogTitle from '@material-ui/core/DialogTitle'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { buildStyles } from './styles'

export interface CustomPopupProps extends DialogProps {
  className?: string
  titleElement?: JSX.Element
  content: JSX.Element
  okBtnText?: string
  cancelBtnText?: string
  otherBtnText?: string
  isMobileFullScreen?: boolean
  okBtnDisabled?: boolean
  disableBackdropClick?: boolean
  onClick?: () => void
  onCancel?: () => void
  onClickOther?: (id: any) => void
  onExited?: () => void
}

const CustomPopup = ({
  className,
  titleElement,
  content,
  okBtnText,
  cancelBtnText,
  otherBtnText,
  okBtnDisabled,
  disableBackdropClick,
  onClick,
  onCancel,
  onClickOther,
  onExited,
  maxWidth,
  ...props
}: CustomPopupProps) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const classes = buildStyles()

  const buttons: JSX.Element[] = []
  onCancel &&
    buttons.push(
      <AppButton type="button" variant="outlined" onClick={onCancel}>
        {cancelBtnText && t(cancelBtnText)}
      </AppButton>
    )

  onClickOther &&
    buttons.push(
      <AppButton type="submit" onClick={onClickOther}>
        {otherBtnText && t(otherBtnText)}
      </AppButton>
    )

  onClick &&
    buttons.push(
      <AppButton type="submit" onClick={onClick} disabled={okBtnDisabled}>
        {okBtnText && t(okBtnText)}
      </AppButton>
    )

  return (
    <Dialog
      className={className}
      fullWidth
      onExited={onExited}
      maxWidth={fullScreen ? false : maxWidth}
      onClose={onCancel}
      disableBackdropClick={!!disableBackdropClick}
      {...props}
    >
      {!!titleElement && (
        <DialogTitle disableTypography classes={{ root: classes.dialogTitle }}>
          {titleElement}
        </DialogTitle>
      )}

      <DialogContent classes={{ root: classes.dialogContent }}>
        {content}
      </DialogContent>

      <DialogActions classes={{ root: classes.dialogActions }}>
        {buttons.map((el, i) => (
          <Fragment key={i}>{el}</Fragment>
        ))}
      </DialogActions>
    </Dialog>
  )
}

CustomPopup.defaultProps = {
  cancelBtnText: Translations.Cancel,
  okBtnText: Translations.Ok,
  otherBtnText: Translations.OTHER,
}

export default CustomPopup
