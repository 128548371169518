enum Translations {
    AccountConfiguration = "AccountConfiguration",
    AccountNachaDfiNumber = "AccountNachaDfiNumber",
    AccountName = "AccountName",
    AccountName2 = "AccountName2",
    AccountNumber = "AccountNumber",
    AccountType = "AccountType",
    ACHECheck = "ACHECheck",
    ACHECheckPriority = "ACHECheckPriority",
    AcrNachaTransferConfiguration = "AcrNachaTransferConfiguration",
    AcrSettings = "AcrSettings",
    ActivityLogDetails = "ActivityLogDetails",
    AddNew = "AddNew",
    Address = "Address",
    AllowEnabledMonthlyParking = "AllowEnabledMonthlyParking",
    AllowEnabledMonthlyParkingDescr1 = "AllowEnabledMonthlyParkingDescr1",
    AllowEnabledMonthlyParkingDescr2 = "AllowEnabledMonthlyParkingDescr2",
    Amount = "Amount",
    AmountCharged = "AmountCharged",
    AreaPcode = "AreaPcode",
    AreaPcodeSearch = "AreaPcodeSearch",
    AverageTime = "AverageTime",
    Back = "Back",
    BillBackToCompany = "BillBackToCompany",
    BillingConfiguration = "BillingConfiguration",
    Box = "Box",
    Boxes = "Boxes",
    Business = "Business",
    Cancel = "Cancel",
    Change = "Change",
    Checking = "Checking",
    Citations = "Citations",
    CitationsIssued = "CitationsIssued",
    City = "City",
    Clear = "Clear",
    ClientPay = "ClientPay",
    ClientPayAchFeesAbsorbed = "ClientPayAchFeesAbsorbed",
    ACHPayments = "ACHPayments",
    ACRSettingsACH = "ACRSettingsACH",
    ConvenienceFee = "ConvenienceFee",
    ACRSettingsConvenienceFee = "ACRSettingsConvenienceFee",
    CustomerPayConvenienceFee = "CustomerPayConvenienceFee",
    Close = "Close",
    Confirm = "Confirm",
    Confirmation = "Confirmation",
    ConvenienceFeeName = "ConvenienceFeeName",
    CorporationAdminFees = "CorporationAdminFees",
    CorporationLocationCustomer = "CorporationLocationCustomer",
    CorporationName = "CorporationName",
    Corporations = "Corporations",
    Country = "Country",
    CountryCodeNotValid = "CountryCodeNotValid",
    County = "County",
    Created = "Created",
    CreditCardPayments = "CreditCardPayments",
    CreditCardsConfiguration = "CreditCardsConfiguration",
    Currency = "Currency",
    CustomerPay = "CustomerPay",
    Date = "Date",
    Declined = "Declined",
    Details = "Details",
    Disabled = "Disabled",
    Discount = "Discount",
    DiscountType = "DiscountType",
    DiscountValue = "DiscountValue",
    Dispute = "Dispute",
    DontUseNacha = "DontUseNacha",
    DragAndDropPdf = "DragAndDropPdf",
    Duration = "Duration",
    Email = "Email",
    EmailNotValid = "EmailNotValid",
    Enabled = "Enabled",
    EnableEnforcementIntegration = "EnableEnforcementIntegration",
    EnableStartDateConfiguration = "EnableStartDateConfiguration",
    EnableT2Integration = "EnableT2Integration",
    EnableTermination = "EnableTermination",
    EnableTezGo = "EnableTezGo",
    EndDate = "EndDate",
    EndHour = "EndHour",
    Ends = "Ends",
    Enforcer = "Enforcer",
    EnforcerActivityLog = "EnforcerActivityLog",
    ExportCSV = "ExportCSV",
    ExportCSVDetails = "ExportCSVDetails",
    FirstName = "FirstName",
    FlatFee = "FlatFee",
    ForgotPassword = "ForgotPassword",
    FromDolarSign = "FromDolarSign",
    Full = "Full",
    GeneralInfo = "GeneralInfo",
    GoToTezGo = "GoToTezGo",
    GoToTheBilling = "GoToTheBilling",
    GroupAdminFees = "GroupAdminFees",
    GuidCorpSearch = "GuidCorpSearch",
    HeaderPortal = "HeaderPortal",
    IntegrationSettings = "IntegrationSettings",
    InternalError = "InternalError",
    InvalidLogin = "InvalidLogin",
    Large = "Large",
    LastName = "LastName",
    ListOfReports = "ListOfReports",
    LocationAdded = "LocationAdded",
    LocationCode = "LocationCode",
    LocationGuid = "LocationGuid",
    LocationName = "LocationName",
    LocationNameGuid = "LocationNameGuid",
    Locations = "Locations",
    LocationSearch = "LocationSearch",
    LocationsVisited = "LocationsVisited",
    Login = "Login",
    LoginHeader = "LoginHeader",
    Logout = "Logout",
    MaxValue = "MaxValue",
    MenuAdvertisers = "MenuAdvertisers",
    MenuHome = "MenuHome",
    MenuLocations = "MenuLocations",
    MenuMesseges = "MenuMesseges",
    MenuMonthlyParkingPayments = "MenuMonthlyParkingPayments",
    MenuPayments = "MenuPayments",
    MenuReports = "MenuReports",
    MenuRevenue = "MenuRevenue",
    MenuTowCompany = "MenuTowCompany",
    Merchant = "Merchant",
    MerchantId = "MerchantId",
    MonthlyParkingConfiguration = "MonthlyParkingConfiguration",
    MonthlyParkingFees = "MonthlyParkingFees",
    MonthlyParkingLocationCurrentlyAssigned = "MonthlyParkingLocationCurrentlyAssigned",
    MonthlyParkingTransactions = "MonthlyParkingTransactions",
    MyAccount = "MyAccount",
    NachaSettingsAndTransferConfiguration = "NachaSettingsAndTransferConfiguration",
    NachaStatus = "NachaStatus",
    NachaTransferConfiguration = "NachaTransferConfiguration",
    NachaType = "NachaType",
    Name = "Name",
    NewPassword = "NewPassword",
    NoAccountYet = "NoAccountYet",
    None = "None",
    Ok = "Ok",
    Owners = "Owners",
    OwnerSearch = "OwnerSearch",
    ParkingRevenueNachaTransferConfiguration = "ParkingRevenueNachaTransferConfiguration",
    Partial = "Partial",
    Password = "Password",
    PasswordsNotEqual = "PasswordsNotEqual",
    PaymentConfiguration = "PaymentConfiguration",
    PayPalPayments = "PayPalPayments",
    Percentage = "Percentage",
    PercentageFlatFee = "PercentageFlatFee",
    PerParker = "PerParker",
    PhoneNotValid = "PhoneNotValid",
    PostalCode = "PostalCode",
    Pricings = "Pricings",
    PrintPdf = "PrintPdf",
    PriorityPayment = "PriorityPayment",
    ProcessingFeeConfiguration = "ProcessingFeeConfiguration",
    ProcessingFeesPaidBy = "ProcessingFeesPaidBy",
    Qty = "Qty",
    QtyUsed = "QtyUsed",
    Reason = "Reason",
    ReceiverName = "ReceiverName",
    Refund = "Refund",
    RefundCompleted = "RefundCompleted",
    RefundFailed = "RefundFailed",
    RegisterNow = "RegisterNow",
    RepeatNewPassword = "RepeatNewPassword",
    ReportSearchTitle = "ReportSearchTitle",
    Required = "Required",
    ResetPassword = "ResetPassword",
    Residential = "Residential",
    ResourceManager = "ResourceManager",
    ReturnedItemFee = "ReturnedItemFee",
    RoundUpTo = "RoundUpTo",
    RoutingNumber = "RoutingNumber",
    Save = "Save",
    Savings = "Savings",
    Search = "Search",
    SearchResults = "SearchResults",
    SeeReport = "SeeReport",
    SelectDateYouWantInvoicesToBeginGenerating = "SelectDateYouWantInvoicesToBeginGenerating",
    SelectMethod = "SelectMethod",
    SelfTermination = "SelfTermination",
    SenderId = "SenderId",
    SendPassword = "SendPassword",
    Ship = "Ship",
    ShortDescription = "ShortDescription",
    SignatureKey = "SignatureKey",
    SignIn = "SignIn",
    Skip = "Skip",
    Small = "Small",
    StartDate = "StartDate",
    StartDateConfiguration = "StartDateConfiguration",
    StartDateOffConfirmationText1 = "StartDateOffConfirmationText1",
    StartDateOffConfirmationText2 = "StartDateOffConfirmationText2",
    StartHour = "StartHour",
    Starts = "Starts",
    StartsEnds = "StartsEnds",
    State = "State",
    Status = "Status",
    Street = "Street",
    Success = "Success",
    Summary = "Summary",
    SystemIntegrations = "SystemIntegrations",
    T2AccountAndSignatureInvalid = "T2AccountAndSignatureInvalid",
    T2AccountAndSignatureValid = "T2AccountAndSignatureValid",
    T2RegionInvalid = "T2RegionInvalid",
    T2RegionName = "T2RegionName",
    T2RegionValid = "T2RegionValid",
    TemporaryPassword = "TemporaryPassword",
    TezGo = "TezGo",
    TEZPayFirstData = "TEZPayFirstData",
    ThirdParty = "ThirdParty",
    TimeIN = "TimeIN",
    TimePeriod = "TimePeriod",
    TitleChargebackPopup = "TitleChargebackPopup",
    ToDolarSign = "ToDolarSign",
    TransactionFrom = "TransactionFrom",
    TransactionTo = "TransactionTo",
    Type = "Type",
    UsedQty = "UsedQty",
    UseNacha = "UseNacha",
    ValueGreaterThanZero = "ValueGreaterThanZero",
    VerifyConnection = "VerifyConnection",
    Welcome = "Welcome",
    YouHaveSelected = "YouHaveSelected",
    Zip = "Zip",
    REQUIRED = "REQUIRED",
    INVALID_EMAIL_FORMAT = "INVALID_EMAIL_FORMAT",
    INCORRECT_LOGIN_DATA = "INCORRECT_LOGIN_DATA",
    TEZ_ADMIN = "TEZ_ADMIN",
    PARKING_MADE_EASY = "PARKING_MADE_EASY",
    TURN_EMPTY_SPACE_INTO_REVENUE = "TURN_EMPTY_SPACE_INTO_REVENUE",
    OTHER = "OTHER",
    SAVED_SUCCESSFULLY = "SAVED_SUCCESSFULLY",
    SAVE_FAILED = "SAVE_FAILED",
    VersionV1 = "VersionV1",
    VersionV2 = "VersionV2",
    EmailEnterMultiple = "EmailEnterMultiple",
    Attention = "Attention",
    T2V2Enabling = "T2V2Enabling",
    T2V2DeleteCoupons = "T2V2DeleteCoupons",
    T2V2DeleteFee = "T2V2DeleteFee",
    T2V2TakeAction = "T2V2TakeAction",
    T2LocationId = "T2LocationId",
    T2V2Descr3 = "T2V2Descr3",
    T2LocationIdInvalid = "T2LocationIdInvalid",
    T2LocationIdValid = "T2LocationIdValid",
    ConfigurationFrom = "ConfigurationFrom",
    UseConfigurationFrom = "UseConfigurationFrom",
    NoConfigurationPleaseClick = "NoConfigurationPleaseClick",
    UnexpectedError = "UnexpectedError",
    EditPaymentConfiguration = "EditPaymentConfiguration",
    TryAgain = "TryAgain",
    NoProcessingFeeConfiguration = "NoProcessingFeeConfiguration",
    NoNACHAConfig = "NoNACHAConfig",
    BackToList = "BackToList",
    Location = "Location",
    Corporation = "Corporation",
    SessionWillExpire = "SessionWillExpire",
    SelectContinueSession = "SelectContinueSession",
    ContinueSession = "ContinueSession",
    SessionExpired = "SessionExpired",
    AutomaticallyLoggedOut = "AutomaticallyLoggedOut",
    
}

export default Translations;