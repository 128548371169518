import Numeral from 'numeral'
import * as React from 'react'
import Helper from '../../Utility/Helpers'

type CurrencyInputProps = {
  value?: number
  className?: string
  changed(value?: number): void
  required?: boolean
  disabled?: boolean
  name?: string
  style?: any
  allowEmpty?: boolean
  placeholder?: string
  moneyFormat?: boolean
  maxLength?: number
  maxValue?: number
  id?: string
}

type CurrencyInputState = {
  maskedValue: string
}

export const currencyInputValueToCents = (amount: number) =>
  typeof amount === 'number'
    ? parseInt(amount.toFixed(2).replace('.', ''))
    : undefined

export class CurrencyInput extends React.Component<
  CurrencyInputProps,
  CurrencyInputState
> {
  static defaultProps = {
    allowEmpty: false,
    moneyFormat: true,
    maxValue: 100000,
  }
  constructor(props: CurrencyInputProps) {
    super(props)

    this.state = {
      maskedValue:
        props.allowEmpty && !props.value
          ? ''
          : this.props.moneyFormat
          ? Numeral(props.value).format(Helper.MoneyFormat)
          : Numeral(props.value).format(Helper.NoCurrencyMoneyFormat),
    }
  }

  componentDidUpdate(prevProps: CurrencyInputProps) {
    if (prevProps.value !== this.props.value) {
      this.setState({
        maskedValue:
          this.props.allowEmpty && !this.props.value
            ? ''
            : this.props.moneyFormat
            ? Numeral(this.props.value).format(Helper.MoneyFormat)
            : Numeral(this.props.value).format(Helper.NoCurrencyMoneyFormat),
      })
    }
  }

  onChange = (e: any): void => {
    if (this.props.allowEmpty && !e.target.value) {
      this.setState({
        maskedValue: '',
      })
      return
    }

    e.target.value = e.target.value.replace(/,/g, '.').replace(/-/g, '')
    this.setState({
      maskedValue: e.target.value,
    })
  }

  onBlur = (e: any): void => {
    if (this.props.allowEmpty && !e.target.value) {
      this.setState(
        {
          maskedValue: '',
        },
        () => {
          this.props.changed(undefined)
        }
      )
      return
    }

    const value = Numeral(e.target.value).format(Helper.NoCurrencyMoneyFormat)
    const maskedValue = this.props.moneyFormat
      ? Numeral(e.target.value).format(Helper.MoneyFormat)
      : value

    const floatValue = parseFloat(value)
    if (
      isNaN(floatValue) ||
      (this.props.maxValue && floatValue > this.props.maxValue)
    ) {
      this.props.changed(this.props.maxValue)
      this.setState({
        maskedValue: this.props.moneyFormat
          ? Numeral(this.props.maxValue).format(Helper.MoneyFormat)
          : Numeral(this.props.maxValue).format(Helper.NoCurrencyMoneyFormat),
      })
      return
    }

    this.props.changed(floatValue)

    this.setState({
      maskedValue,
    })
  }

  render() {
    const { className } = this.props
    return (
      <input
        type="text"
        step="0.01"
        min="0.1"
        className={'currency-input' + (className ? ' ' + className : '')}
        value={this.state.maskedValue}
        onChange={this.onChange}
        onBlur={this.onBlur}
        required={this.props.required}
        disabled={this.props.disabled}
        name={this.props.name}
        style={this.props.style}
        placeholder={this.props.placeholder}
        maxLength={this.props.maxLength}
        id={this.props.id}
      />
    )
  }
}
