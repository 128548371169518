import axios from '../..'
import { GET_LOCATIONS_LIST, GET_LOCATION_NAME } from '../../constants'
import {
  DataTableApiResult,
  DataTableParams,
} from '../../../shared/components/DataTable/DataTable'
import { GetLocationsModel } from './types'
import { ApiResultGeneric } from '../../types'

const getLocationName = async (
  locationId: number
): Promise<ApiResultGeneric<string> | null> => {
  try {
    const params = {
      id: locationId,
    }
    const result = await axios.get<ApiResultGeneric<string>>(
      GET_LOCATION_NAME,
      { params }
    )
    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}

const getLocations = async (
  params: DataTableParams
): Promise<DataTableApiResult<GetLocationsModel[]> | null> => {
  try {
    const result = await axios.get<DataTableApiResult<GetLocationsModel[]>>(
      GET_LOCATIONS_LIST,
      {
        params,
      }
    )
    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}

const location = { getLocationName, getLocations }

export default location
