import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { getSessionExpiredPopupFromLocalStorage, removeTokensFromLocalStorage, setSessionExpiredPopupInLocalStorage } from '../../api/auth'
import SessionExpiredPopup from '../../app/Timer/SessionExpiredPopup'
import Timer from '../../app/Timer/Timer'
import { AppContext } from '../../context'
import { setIsLogged } from '../../context/actions'
import { routes } from '../../routes'
import Nav from './Nav'

export interface LayoutProps {
  children: React.ReactNode
}

const Layout = ({ children }: LayoutProps) => {
  const { state, contextDispatch } = useContext(AppContext)
  const history = useHistory()
  const [openSessionExpiredPopup, setOpenSessionExpiredPopup] = useState(false)

  const logout = () => {
    history.push(routes.root.LOGIN)
    removeTokensFromLocalStorage()
    setIsLogged(contextDispatch, false)
  }

  const automaticLogout = () => {
    logout()
    setOpenSessionExpiredPopup(true)
  }

  useEffect(() => {
    if (getSessionExpiredPopupFromLocalStorage()) {
      setOpenSessionExpiredPopup(true);
      setSessionExpiredPopupInLocalStorage(false);
    }
  }, []);

  return (
    <>
      <div className="container-fluid">
        <div className={`IndexComponent ${state.isLogged && 'paddTop'}`}>
          {state.isLogged && (
            <>
              <Timer logout={logout} automaticLogout={automaticLogout} />
              <Nav profileName={state.profileName} logout={logout} />
            </>
          )}
          <div
            className="container-fluid"
            style={{ display: 'flex', flexFlow: 'row wrap' }}
          >
            {state.isLogged ? (
              <div className="layout">{children}</div>
            ) : (
              children
            )}
          </div>
        </div>
      </div>
      <SessionExpiredPopup
        show={openSessionExpiredPopup}
        onOkClick={() => setOpenSessionExpiredPopup(false)}
      />
    </>
  )
}

export default Layout
