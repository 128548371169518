import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { routes } from '.'
import { getTokensFromLocalStorage } from '../api/auth'
import LoginView from '../app/login/LoginView'
import Business from './Business'
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface RoutesProps {}

const Routes = (props: RoutesProps) => {
  const tokens = getTokensFromLocalStorage()
  return (
    <Switch>
      {tokens && <Route path={routes.root.BUSINESS} component={Business} />}
      {(!tokens || (tokens && tokens.expires < Date.now())) && (
        <Route path={routes.root.LOGIN} component={LoginView} />
      )}
      <Redirect to={routes.root.LOGIN} />
    </Switch>
  )
}

export default Routes
