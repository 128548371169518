import MomentUtils from '@date-io/moment'
import { MuiThemeProvider, StylesProvider } from '@material-ui/core'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import React, { useEffect, useMemo, useReducer } from 'react'
import { useHistory } from 'react-router-dom'
import { ThemeProvider as StyledThemeProvider } from 'styled-components'
import 'typeface-montserrat'
import 'typeface-roboto'
import { getTokensFromLocalStorage, getUserFromLocalStorage } from './api/auth'
import './App.css'
import { AppContext } from './context'
import { setIsLogged, setProfileName } from './context/actions'
import reducer from './context/reducer'
import i18n from './i18n'
import { routes } from './routes'
import Routes from './routes/Routes'
import AppSpinner from './shared/components/Spinner/AppSpinner'
import Layout from './shared/layout/Layout'
import './styles_to_remove/bootstrap-theme.css'
import './styles_to_remove/bootstrap.css'
import './styles_to_remove/common.css'
import './styles_to_remove/site.scss'
import { theme } from './themes/theme'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface AppProps {}

const App = (props: AppProps) => {
  const history = useHistory()
  const [state, contextDispatch] = useReducer(reducer, {
    isLogged: false,
    profileName: '',
    isSpinning: false,
  })

  const contextValue = useMemo(
    () => ({ state, contextDispatch }),
    [state, contextDispatch]
  )

  const authenticateUser = () => {
    const tokens = getTokensFromLocalStorage()
    const user = getUserFromLocalStorage()

    if (tokens && tokens.expires > Date.now()) {
      setIsLogged(contextDispatch, true)
      setProfileName(contextDispatch, user?.profileName || '')
      return true
    }
    return false
  }

  useEffect(() => {
    authenticateUser()
  }, [])

  useEffect(() => {
    const isLogged = authenticateUser()
    if (!isLogged) history.push(routes.root.LOGIN)
    else if (isLogged && window.location.pathname.includes('login')) {
      history.push(routes.business.CORPORATION)
    }
  }, [history, state.isLogged])

  return (
    <StylesProvider injectFirst>
      <StyledThemeProvider theme={theme}>
        <MuiThemeProvider theme={theme}>
          <MuiPickersUtilsProvider locale={i18n.language} utils={MomentUtils}>
            <AppContext.Provider value={contextValue}>
              <Layout>
                <Routes />
                <AppSpinner show={state.isSpinning} />
              </Layout>
            </AppContext.Provider>
          </MuiPickersUtilsProvider>
        </MuiThemeProvider>
      </StyledThemeProvider>
    </StylesProvider>
  )
}

export default App
