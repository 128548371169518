import axios from '..'
import { LOGIN, LOGOUT, REFRESH_TOKEN } from '../constants'
import { AccountLoginParams, AccountLogoutParams } from '../models/params'
import { AccountLoginModel } from '../models/results'
import { ApiResultBase, ApiResultGeneric } from '../types'

const login = async (
  data: AccountLoginParams
): Promise<ApiResultGeneric<AccountLoginModel> | null> => {
  try {
    const result = await axios.post<ApiResultGeneric<AccountLoginModel>>(
      LOGIN,
      data
    )
    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}

const refresh = async (): Promise<ApiResultGeneric<AccountLoginModel> | null> => {
  try {
    const result = await axios.post<ApiResultGeneric<AccountLoginModel>>(
      REFRESH_TOKEN
    )
    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}

const logout = async (
  data: AccountLogoutParams
): Promise<ApiResultBase | null> => {
  try {
    const result = await axios.post<ApiResultBase>(LOGOUT, data)
    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}

const auth = {
  login,
  refresh,
  logout,
}

export default auth
