import * as React from 'react'

interface ContentProps {
  className?: string
}

const Content: React.FunctionComponent<ContentProps> = ({
  children,
  className,
}) => {
  return (
    <div className={`content col-xs-12 ${!!className ? className : ''}`}>
      {children}
    </div>
  )
}

export default Content
