import * as React from 'react'
import { withStyles } from '@material-ui/styles'
import Switch from '@material-ui/core/Switch'
import { FormControlLabel } from '@material-ui/core'

interface RedSwitchProps {
  selected: boolean
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
  disabled?: boolean
  title?: string
}

const StyledSwitch = withStyles({
  switchBase: {
    color: 'rgb(200,200,200)',
    '&$checked': {
      color: 'rgba(237, 28, 36, 1)',
    },
    '&$checked + $track': {
      backgroundColor: 'rgba(237, 28, 36, 1)',
    },
  },
  checked: {},
  track: {},
})(Switch)

const RedSwitch = (props: RedSwitchProps) => {
  return (
    <StyledSwitch
      checked={props.selected}
      onClick={props.onClick}
      disabled={!!props.disabled}
      title={props.title}
      onChange={props.onChange}
    />
  )
}

interface T2PSwitchProps extends RedSwitchProps {
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
}

const T2PSwitch = ({
  selected,
  onClick,
  onChange,
  disabled,
  title,
  labelPlacement = 'end',
}: T2PSwitchProps) => {
  return (
    <FormControlLabel
      control={
        <RedSwitch
          title={title}
          selected={selected}
          onChange={onChange}
          onClick={onClick}
          disabled={disabled}
        />
      }
      classes={{ label: 'titleSwitch' }}
      label={title}
      labelPlacement={labelPlacement}
    />
  )
}

export default T2PSwitch
