export enum FeesType {
  Percentage = 1,
  FlatFee = 2,
  Mixed = 3,
  Parker = 5,
}

export enum Nacha {
  DontUse = 0,
  Use = 1,
}

export enum AccountType {
  // dont change those numbers -- KS
  Checking = 22,
  Savings = 32,
}

export enum T2PProcessingFeeAmountConfigPaymentType {
  MarkedAsPaid = 0,
  CreditCard = 1,
  PayPal = 2,
  Chargeback = 3,
  CreditCardACR = 4,
  ACH = 5,
  Check = 6,
  PaymentGatewayCreditCard = 7,
  CreditBalance = 8,
  ACHACR = 9,
  PaymentGatewayACH = 10,
}
export enum T2PPaymentConfigPayerType {
  GroupAdmin = 1,
  CorporationAdmin = 2,
}

export enum RefundType {
  Full = 1,
  Partial = 2,
}

export enum DictPaymentConfigType {
  MonthlyAgreementRate = 1,
  CarPark = 2,
  Citation = 3,
}

export enum ConfigurationSource {
  T2P = 0,
  Nacha = 1,
}
