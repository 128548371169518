import * as React from 'react'
import './CustomCheckbox.scss'

type CustomCheckboxProps = {
  handleCheckboxChange?: (checked: boolean) => void
  checked?: boolean
  title?: string
  isTitleDisabled?: boolean
  disabled?: boolean
  style?: React.CSSProperties
  class?: string
  id?: string
}

export default class CustomCheckbox extends React.Component<
  CustomCheckboxProps,
  any
> {
  handleCheckboxChange = (event: any) => {
    this.props.handleCheckboxChange &&
      this.props.handleCheckboxChange(event.target.checked)
  }

  render() {
    const disabled = this.props.disabled ? 'disabled ' : ''
    const className = this.props.class != null ? this.props.class : ''
    return (
      <label
        className={'customCheckbox ' + disabled + className}
        style={this.props.style}
      >
        {!this.props.isTitleDisabled && this.props.title}
        <input
          type="checkbox"
          checked={this.props.checked != null ? this.props.checked : false}
          onChange={this.handleCheckboxChange}
          disabled={this.props.disabled}
          id={this.props.id}
        />
        <span className="checkmark"></span>
      </label>
    )
  }
}
