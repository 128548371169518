import { makeStyles, Theme } from '@material-ui/core'

export const buildStyles = makeStyles((theme: Theme) => ({
  dialogTitle: {
    borderBottom: '1px solid #e5e5e5',
    marginBottom: theme.spacing(2),
  },

  dialogContent: {},

  dialogActions: {
    margin: theme.spacing(2, 0),
    display: 'flex',
    justifyContent: 'center',
  },
}))
