import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { APP_CONFIG } from '../../../api/config'
import GridWrapper from '../../../Controls/GridWrapper'
import Translations from '../../../utils/translations'
import useConfigurationSource from './useConfigurationSource'
const Wrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
`
const TextWrapper = styled.p`
  margin-bottom: 0;
  min-width: 160px;
`

const ErrorText = styled.p`
  color: ${(_) => _.theme.palette.primary.main};
  font-weight: bold;
  white-space: pre-line;
`
export interface ConfigurationSourceProps {}

export const ConfigurationSource: React.FC<ConfigurationSourceProps> = (
  props
) => {
  const { t } = useTranslation()
  const { title, changeSource, apiError } = useConfigurationSource()

  if (!APP_CONFIG.nachaMonthlyParkingConfigEnabled) {
    console.warn('"Nacha Monthly Parking Config" flag is disabled')
    return null
  }

  return (
    <GridWrapper>
      <Wrapper>
        <TextWrapper>
          {`${t(Translations.ConfigurationFrom)} `}
          <strong>{title.from}</strong>
        </TextWrapper>
        <button
          type="button"
          style={{ minWidth: 270 }}
          className="cancelBtn changeBtn"
          onClick={changeSource}
        >
          {`${t(Translations.UseConfigurationFrom)} ${title.useFrom}`}
        </button>
      </Wrapper>
      {apiError && <ErrorText>{apiError}</ErrorText>}
    </GridWrapper>
  )
}

export default ConfigurationSource
