import React from 'react'
import { useTranslation } from 'react-i18next'
import { Redirect, Route, Switch } from 'react-router'
import { routes } from '.'
import Corporation from '../app/BISHome/Corporation'
import CorporationList from '../app/BISHome/CorporationList'
import MonthlyTransactions from '../app/BISHome/MonthlyTransactions'
import Tab from '../Controls/Tabs/Tab'
import { Tabs } from '../Controls/Tabs/Tabs'
import Translations from '../utils/translations'
import { SearchProvider } from '../context/SearchContext'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BusinessProps {}

const Business = (props: BusinessProps) => {
  const { t } = useTranslation()
  return (
    <SearchProvider>
      <div className="container">
        <div className="tabs-header">
          <Tabs style={{ marginBottom: '36px' }}>
            <Tab path={routes.corporations.CORPORATIONS} id="corporations">
              {t(Translations.Corporations)}
            </Tab>
            <Tab
              path={routes.business.MONTHLY_TRANSACTIONS}
              id="monthlyParking"
            >
              {t(Translations.MonthlyParkingTransactions)}
            </Tab>
          </Tabs>
        </div>

        <Switch>
          <Route
            exact
            path={routes.corporations.CORPORATIONS}
            component={CorporationList}
          />
          <Route path={routes.business.CORPORATION} component={Corporation} />
          <Route
            path={routes.business.MONTHLY_TRANSACTIONS}
            component={MonthlyTransactions}
          />

          <Redirect to={routes.corporations.CORPORATIONS} />
        </Switch>
      </div>
    </SearchProvider>
  )
}

export default Business
