export default class Helper {
  static MoneyFormat = '$0.00'
  static NoCurrencyMoneyFormat = '0.00'

  static getAmountInCents(amount: number): number {
    return Number((amount * 100).toFixed(0))
  }

  static getAmountFromCents(amount: number): number {
    return Number((amount / 100).toFixed(2))
  }

  static DateFormat = 'YYYY-MM-DD'
  static DateTimeFormat = 'YYYY-MM-DDTHH:mm:ss'
  static EmailValidationRegex = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
}
