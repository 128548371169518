import axios from '../..'
import { SavePaymentConfigModel } from '../../../app/Payments/types'
import { getModelToSavePayments } from '../../../app/Payments/utils/getModelToSavePayments'
import { AccountType, ConfigurationSource } from '../../../Enums/Enums'
import {
  CHANGE_CONFIGURATION_SOURCE,
  GET_PAYMENT_CONFIG,
  MAKE_CHARGEBACK_TRANSACTION,
  SAVE_PAYMENT_CONFIG,
} from '../../constants'
import { MakeChargebackTransactionParams } from '../../models/params'
import { ApiResultBase, ApiResultGeneric } from '../../types'
import { GetPaymentConfigModel } from './types'
const getPaymentConfig = async (
  locationId: string
): Promise<ApiResultGeneric<GetPaymentConfigModel> | null> => {
  try {
    const params = {
      locationId,
    }
    const result = await axios.get<ApiResultGeneric<GetPaymentConfigModel>>(
      GET_PAYMENT_CONFIG,
      { params }
    )

    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}

const savePaymentConfig = async (
  params: SavePaymentConfigModel
): Promise<ApiResultBase | null> => {
  try {
    const model = getModelToSavePayments(params)
    const result = await axios.post<ApiResultBase>(SAVE_PAYMENT_CONFIG, model)

    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}

const makeChargebackTransaction = async (
  params: MakeChargebackTransactionParams
): Promise<ApiResultGeneric<string> | null> => {
  try {
    const result = await axios.post<ApiResultGeneric<string>>(
      MAKE_CHARGEBACK_TRANSACTION,
      params
    )

    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}

const getConfigurationFrom = async (params: {
  locationId: string
  configSource: ConfigurationSource
  type?: AccountType
}) => {
  try {
    const result = await axios.post<
      ApiResultGeneric<GetPaymentConfigModel | null>
    >(CHANGE_CONFIGURATION_SOURCE, params)

    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    throw err
  }
}

const payment = {
  getPaymentConfig,
  savePaymentConfig,
  makeChargebackTransaction,
  getConfigurationFrom,
}

export default payment
