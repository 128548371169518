import * as React from 'react'

type BoxProps = {
  number: any
  text: any
  style?: React.CSSProperties
  className?: string
}

export const Box: React.SFC<BoxProps> = (props: BoxProps) => {
  return (
    <div
      className={'box' + (props.className ? ' ' + props.className : '')}
      style={props.style}
    >
      <div className="number">{props.number}</div>
      <label className="text">{props.text}</label>
    </div>
  )
}
