import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Icons } from '../../assets'
import CustomPopup from '../../Controls/Popup/CustomPopup'
import Translations from '../../utils/translations'

interface SessionExpiredPopupProps {
  show: boolean
  onOkClick: () => void
}

const SessionExpiredPopup = ({ show, onOkClick }: SessionExpiredPopupProps) => {
  const { t } = useTranslation()
  const popupContent = React.useMemo(
    () => (
      <div className="col-xs-12 text-center">
        <p style={{ fontSize: '16px', fontFamily: 'Roboto' }}>
          {t(Translations.AutomaticallyLoggedOut)}
        </p>
      </div>
    ),
    [t]
  )

  return (
    <CustomPopup
      open={show}
      titleElement={
        <div className="col-md-12" style={{ display: 'flex' }}>
          <img src={Icons.BlackInfoIcon} alt="" />
          <h1>{t(Translations.SessionExpired)}</h1>
        </div>
      }
      content={popupContent}
      okBtnText={t(Translations.Ok)}
      onClick={onOkClick}
    />
  )
}

export default SessionExpiredPopup
