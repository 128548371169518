import styled from 'styled-components'

export const TimerClock = styled.div`
  height: 30px;
  width: 50px;
  top: 15px;
  position: absolute;
  z-index: 10000;
  right: 370px;
  color: #7c7c7c;
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  visibility: hidden;
`
