import * as React from 'react'

interface PopupTitleProps {
  imgSrc: string
  text: string
}

const PopupTitle = ({ imgSrc, text }: PopupTitleProps) => {
  return (
    <div className="col-xs-12">
      <img src={imgSrc} alt={text} />
      <h3>{text}</h3>
    </div>
  )
}

export default PopupTitle
