import { makeStyles, Theme } from '@material-ui/core'

export const buildStyles = makeStyles((theme: Theme) => ({
  iconInfo: {
    width: '32px',
  },
  listItem: {
    display: 'list-item',
    listStylePosition: 'inside',
  },
  contentRow: {
    marginTop: '10px !important',
  },
  labelInvalid: {
    height: 14,
  },
  labelInvalidSmall: {
    height: 10,
  },
  noMarginTop: {
    marginTop: '0 !important',
  },
  noMarginLeft: {
    marginLeft: '0 !important',
  },
}))
