import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { Icons } from '../../assets'
import { SelectListOption } from '../../Models/DashboardTypes'
import Translations from '../../utils/translations'
import ACHPayments from './ACHPayments'
import ConvenienceFeePayments from './ConvenienceFeePayments'
import CreditCardPayments from './CreditCardPayments'
import { PaymentsContext } from './PaymentsContext'
import { Fee, PaymentConfig } from './types'
import { getPaymentParamName, isPaySwitchedOff } from './utils/dataHelper'

export type PayOrAcr = 'pay' | 'acr'
export type FeeType =
  | 'Flat'
  | 'Percentage'
  | 'MixedFlat'
  | 'MixedPercentage'
  | 'Parker'
  | 'Name'
  | 'MaximumValue'
export type PaymentType = 'CreditCard' | 'ACH' | 'ConvenienceFee'

const ProcessingFeeConfiguration = () => {
  const { state, dispatch } = React.useContext(PaymentsContext)
  const { t } = useTranslation()
  const { config } = state

  const isACHSet = React.useMemo(
    () => (config.selectedMerchantECheck ? 1 : 0),
    [config.selectedMerchantECheck]
  )

  const handleFeePropertyChange = (
    paymentType: PaymentType,
    payOrAcr: PayOrAcr,
    propertyName: keyof Fee,
    value: unknown
  ) => {
    let newState = { ...config }
    const paymentParam = getPaymentParamName(paymentType)
    const payment = newState[paymentParam] as PaymentConfig
    const fee = { ...payment[payOrAcr] }

    if (isPaySwitchedOff(payOrAcr, propertyName, value)) {
      newState[paymentParam].acr.enabled = false
    }

    for (let prop in fee)
      if (prop === propertyName) (fee as Record<string, any>)[prop] = value

    for (let prop in payment)
      if (prop === payOrAcr) (payment as Record<string, any>)[prop] = fee

    for (let prop in newState)
      if (prop === paymentParam)
        (newState as Record<string, any>)[prop] = payment

    dispatch({ type: 'set_config', config: newState })
  }

  const handleSubsectionEnabledChange = (
    paymentType: PaymentType,
    adminType: PayOrAcr
  ) => (value: boolean) =>
    handleFeePropertyChange(paymentType, adminType, 'enabled', value)

  const handleSelectedChange = (
    paymentType: PaymentType,
    adminType: PayOrAcr
  ) => (value: number) =>
    handleFeePropertyChange(paymentType, adminType, 'selected', value)

  const handleFeeChange = (
    paymentType: PaymentType,
    payOrAcr: PayOrAcr,
    feeType: FeeType
  ) => {
    const feeTypeName = feeType.toLowerCase() as keyof Fee
    const paymentParam = getPaymentParamName(paymentType)
    const payment = { ...config[paymentParam] } as PaymentConfig
    const fee = payment[payOrAcr]

    if (feeType === 'MixedFlat' || feeType === 'MixedPercentage') {
      return (value?: number | string) => {
        if (typeof value === 'string' || typeof value === 'undefined') return
        const mixedFee = { ...fee.mixed }
        if (feeType === 'MixedFlat') mixedFee.flat = value
        else mixedFee.percentage = value
        handleFeePropertyChange(paymentType, payOrAcr, 'mixed', mixedFee)
      }
    }
    if (feeType === 'MaximumValue') {
      return (value?: number | string) =>
        handleFeePropertyChange(paymentType, payOrAcr, 'maximumValue', value)
    }
    return (value?: number | string) =>
      handleFeePropertyChange(paymentType, payOrAcr, feeTypeName, value)
  }

  const handleRoundUpChange = (
    paymentType: PaymentType,
    adminType: PayOrAcr
  ) => (option: SelectListOption) =>
    handleFeePropertyChange(paymentType, adminType, 'roundUp', {
      value: option.value,
      enabled: Boolean(option.value),
    })

  const handleReturnedItemFee = (val: number) =>
    dispatch({ type: 'change_returned_item_fee', value: val })
  const handleSectionEnabledChange = (
    selected: boolean,
    paymentType: PaymentType
  ) => {
    let newState = { ...config }
    newState[getPaymentParamName(paymentType)].enabled = selected
    dispatch({ type: 'set_config', config: newState })
  }

  const handlers = {
    handleEnabledChange: handleSubsectionEnabledChange,
    handleFeeTypeChange: handleSelectedChange,
    handleFeeChange,
    handleRoundUpChange,

    handleSectionEnabledChange,
  }

  return (
    <div className="content col-centered">
      <div className="header">
        <img src={Icons.Price} alt=" price icon" />
        <h3>{t(Translations.ProcessingFeeConfiguration)}</h3>
      </div>
      <CreditCardPayments payment={config.creditCardPayments} {...handlers} />
      <ACHPayments
        payment={config.achPayments}
        returnedItemFee={config.returnedItemFee}
        isACHSet={isACHSet}
        handleReturnedItemFee={handleReturnedItemFee}
        {...handlers}
      />
      <ConvenienceFeePayments
        payment={config.convenienceFeePayments}
        {...handlers}
      />
    </div>
  )
}

export default ProcessingFeeConfiguration
