import { makeStyles, Theme } from '@material-ui/core'
import Button, { ButtonProps as MUIButtonProps } from '@material-ui/core/Button'
import React from 'react'

interface AppButtonProps {
  minWidth?: number
}

export const buildStyles = makeStyles((theme: Theme) => ({
  root: (props: { minWidth?: number }) => ({
    borderRadius: '26px',
    fontSize: '12px',
    fontWeight: 700,
    minWidth: `${props.minWidth}px`,
  }),
}))

const AppButton = ({ minWidth, ...props }: MUIButtonProps & AppButtonProps) => {
  const classes = buildStyles({ minWidth })

  return (
    <Button {...props} className={classes.root}>
      {props.children}
    </Button>
  )
}

AppButton.defaultProps = {
  minWidth: '170',
  variant: 'contained',
  color: 'primary',
}

export default AppButton
