import * as React from 'react'
import styled from 'styled-components'

export const Body = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
`

const ItemContainer = styled.div`
  min-height: 50px;
  flex: 0 1 33.333%;
`
const ItemLabel = styled.label<{ bold: boolean }>`
  line-height: 14px;
  ${({ bold }) =>
    bold
      ? `font-weight: bold;
  color: black;`
      : `font-weight: 400;
  color: #7c7c7c;`}
`
const ItemDiv = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #000;
`

interface ItemProps {
  label: string
  bold?: boolean
}

export const Item: React.FunctionComponent<ItemProps> = ({
  label,
  bold,
  children,
}) => (
  <ItemContainer>
    <ItemLabel bold={!!bold}>{label}</ItemLabel>
    <ItemDiv>{children}</ItemDiv>
  </ItemContainer>
)

const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

const TitleText = styled.h4`
  font-family: 'Roboto';
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #000;
`

interface TitleProps {
  text: string
}

export const Title: React.FunctionComponent<TitleProps> = ({
  text,
  children,
}) => (
  <TitleContainer>
    <TitleText>{text}</TitleText>
    {children}
  </TitleContainer>
)

const SeparatorContainer = styled.div`
  width: 100%;
`

export const Separator = () => (
  <SeparatorContainer>
    <hr />
  </SeparatorContainer>
)
