import Moment from 'moment'
import React, { useContext, useState } from 'react'
import DatePicker from 'react-datepicker'
import { useTranslation } from 'react-i18next'
import { generatePath, useHistory, useParams } from 'react-router'
import { SaveMonthyConfigurationApiParams } from '../../api/models/params'
import { monthlyParkingApi } from '../../api/services'
import { Icons } from '../../assets'
import { AppContext } from '../../context'
import { setIsSpinning } from '../../context/actions'
import * as S from '../../Controls/Body/Body'
import CustomPopup from '../../Controls/Popup/CustomPopup'
import InfoPopup from '../../Controls/Popup/InfoPopup'
import PopupContent from '../../Controls/Popup/PopupContent'
import PopupTitle from '../../Controls/Popup/PopupTitle'
import T2PSwitch from '../../Controls/Switch/T2PSwitch'
import { routes } from '../../routes'
import Helper from '../../Utility/Helpers'
import Translations from '../../utils/translations'
import '../Style/Index.scss'

const StartDateConfiguration = () => {
  const { contextDispatch } = useContext(AppContext)
  const { t } = useTranslation()
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const history = useHistory()
  const { locationId, corporationId } = useParams<{
    locationId: string
    corporationId: string
  }>()
  const [isStartDate, setIsStartDate] = React.useState(false)
  const [hasMonthlyGroups, setHasMonthlyGroups] = React.useState(false)
  const [showDisableStartDatePopup, setShowDisableStartDatePopup] =
    React.useState(false)
  const [startDate, setStartDate] = React.useState<Moment.Moment>(
    Moment().add(1, 'days')
  )
  const [
    showCanNotDisabledMonthlyParkingPopup,
    setShowCanNotDisabledMonthlyParkingPopup,
  ] = React.useState(false)
  const [
    showCantEnableMPNoProcessingFeePopup,
    setShowCantEnableMPNoProcessingFeePopup,
  ] = React.useState(false)
  const [allowEnabledMonthlyParking, setAllowEnabledMonthlyParking] =
    React.useState(false)
  const [hasMonthlyGroupAgreement, setHasMonthlyGroupAgreement] =
    React.useState(false)
  const [hasProcessingFeeConfiguration, setHasProcessingFeeConfiguration] =
    React.useState(false)

  const handleStartDateCheckboxChange = React.useCallback(
    (_, checked: boolean) => {
      if (hasMonthlyGroups && isStartDate && !checked) {
        setShowDisableStartDatePopup(true)
      } else {
        setIsStartDate(checked)
      }
    },
    [
      setIsStartDate,
      isStartDate,
      hasMonthlyGroups,
      setShowDisableStartDatePopup,
    ]
  )

  const handleAllowEnabledMonthlyParkingCheckboxChange = React.useCallback(
    (_, checked: boolean) => {
      if (allowEnabledMonthlyParking && !checked) {
        if (hasMonthlyGroupAgreement) {
          setShowCanNotDisabledMonthlyParkingPopup(true)
        } else {
          setAllowEnabledMonthlyParking(false)
        }
      } else if (!hasProcessingFeeConfiguration) {
        setShowCantEnableMPNoProcessingFeePopup(true)
      } else {
        setAllowEnabledMonthlyParking(checked)
      }
    },
    [
      setAllowEnabledMonthlyParking,
      allowEnabledMonthlyParking,
      hasMonthlyGroupAgreement,
      setShowCanNotDisabledMonthlyParkingPopup,
      hasProcessingFeeConfiguration,
      setShowCantEnableMPNoProcessingFeePopup,
    ]
  )

  const handleStartDateChange = React.useCallback(
    (date: Moment.Moment) => {
      setStartDate(date)
    },
    [setStartDate]
  )

  const back = React.useCallback(() => {
    history.push(
      generatePath(routes.corporation.LOCATION_LIST, {
        corporationId,
      })
    )
  }, [history, corporationId])

  const handleSave = React.useCallback(async () => {
    setIsSpinning(contextDispatch, true)
    const params: SaveMonthyConfigurationApiParams = {
      LocationId: parseInt(locationId),
      OnboardingStartDate: isStartDate
        ? startDate.format(Helper.DateFormat)
        : undefined,
      AllowEnabledMonthlyParking: allowEnabledMonthlyParking,
    }

    const result = await monthlyParkingApi.saveMonthyConfiguration(params)
    setIsSpinning(contextDispatch, false)
    if (result?.success) {
      setIsPopupOpen(true)
      // back()
    }
  }, [
    contextDispatch,
    locationId,
    isStartDate,
    startDate,
    allowEnabledMonthlyParking,
  ])

  const loadData = async () => {
    setIsSpinning(contextDispatch, true)

    const monthlyConfiguration =
      await monthlyParkingApi.getMonthlyConfiguration(parseInt(locationId))
    if (!monthlyConfiguration) {
      return
    }

    const result = {
      startDate: !!monthlyConfiguration.data.onboardingStartDate
        ? Moment(
            monthlyConfiguration.data.onboardingStartDate,
            Helper.DateTimeFormat
          )
        : undefined,
      hasMonthlyGroup: monthlyConfiguration.data.hasMonthlyGroupRates,
      allowEnabledMonthlyParking:
        monthlyConfiguration.data.allowEnabledMonthlyParking,
      hasMonthlyGroupAgreement:
        monthlyConfiguration.data.hasMonthlyGroupAgreement,
      hasProcessingFeeConfiguration:
        monthlyConfiguration.data.hasProcessingFeeConfiguration,
    }

    if (result && !!result.startDate) {
      setIsStartDate(true)
      setStartDate(result.startDate)
    }
    setHasMonthlyGroups(!!result && !!result.hasMonthlyGroup)
    setAllowEnabledMonthlyParking(result.allowEnabledMonthlyParking)
    setHasMonthlyGroupAgreement(result.hasMonthlyGroupAgreement)
    setHasProcessingFeeConfiguration(result.hasProcessingFeeConfiguration)
    setIsSpinning(contextDispatch, false)
  }

  const popupContent = React.useMemo(
    () => (
      <PopupContent.Content>
        <PopupContent.Row>
          <PopupContent.Label>
            {t(Translations.StartDateOffConfirmationText1)}
          </PopupContent.Label>
        </PopupContent.Row>
        <PopupContent.Row>
          <PopupContent.Label>
            {t(Translations.StartDateOffConfirmationText2)}
          </PopupContent.Label>
        </PopupContent.Row>
      </PopupContent.Content>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const popupTitle = React.useMemo(
    () => (
      <PopupTitle
        imgSrc={Icons.QuestionMark}
        text={t(Translations.Confirmation)}
      />
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const minDate = React.useMemo(() => Moment().add(1, 'days'), [])

  React.useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId])

  return (
    <>
      <div className="content col-centered">
        <div className="header">
          <img src={Icons.CalendarBlack} alt="calendar icon" />
          <h3>{t(Translations.MonthlyParkingConfiguration)}</h3>
        </div>
        <div className="payment-wrapper">
          <div className="fees-border fees-space-between">
            <div className="content-row">
              <S.Title
                text={t(
                  Translations.SelectDateYouWantInvoicesToBeginGenerating
                )}
              />
              <T2PSwitch
                onChange={handleStartDateCheckboxChange}
                selected={isStartDate}
                title={t(Translations.EnableStartDateConfiguration)}
              />
              <div className="content-row" style={{ marginTop: 10 }}>
                <div className="width-140">
                  <label>{t(Translations.StartDate)}</label>
                  <DatePicker
                    id="startDatePicker"
                    selected={isStartDate ? startDate.toDate() : undefined}
                    minDate={minDate.toDate()}
                    onChange={(date: Date) =>
                      handleStartDateChange(Moment(date))
                    }
                    dateFormat="MM/dd/yyyy"
                    className="form-control datePicker startDatePicker"
                    disabled={!isStartDate}
                    popperModifiers={{
                      arrow: {
                        order: 1,
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fees-border fees-space-between">
            <div className="content-row">
              <S.Title
                text={t(Translations.AllowEnabledMonthlyParkingDescr1)}
              />
              <p>{t(Translations.AllowEnabledMonthlyParkingDescr2)}</p>
              <T2PSwitch
                onChange={handleAllowEnabledMonthlyParkingCheckboxChange}
                selected={allowEnabledMonthlyParking}
                title={t(Translations.AllowEnabledMonthlyParking)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="buttons center">
        <button className="cancelBtn text-uppercase" onClick={back}>
          {t(Translations.Cancel)}
        </button>
        <button
          className="saveBtn text-uppercase"
          onClick={handleSave}
          disabled={isStartDate && !startDate}
        >
          {t(Translations.Save)}
        </button>
      </div>

      <InfoPopup
        show={isPopupOpen}
        text={t(Translations.SAVED_SUCCESSFULLY)}
        onClick={() => {
          setIsPopupOpen(false)
          loadData()
        }}
      />

      <CustomPopup
        maxWidth="md"
        open={showDisableStartDatePopup}
        onCancel={() => {
          setShowDisableStartDatePopup(false)
        }}
        titleElement={popupTitle}
        content={popupContent}
        onClick={() => {
          setIsStartDate(false)
          setShowDisableStartDatePopup(false)
        }}
        okBtnText={t(Translations.Confirm)}
      />

      <InfoPopup
        show={showCanNotDisabledMonthlyParkingPopup}
        text={t(Translations.MonthlyParkingLocationCurrentlyAssigned)}
        onClick={() => {
          setShowCanNotDisabledMonthlyParkingPopup(false)
        }}
      />

      <InfoPopup
        show={showCantEnableMPNoProcessingFeePopup}
        text={t(Translations.NoProcessingFeeConfiguration)}
        onClick={() => {
          setShowCantEnableMPNoProcessingFeePopup(false)
        }}
      />
    </>
  )
}

export default StartDateConfiguration
