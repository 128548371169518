import {
  BuyerFees,
  ProcessingFeeAmountConfigModel,
  VendorFees,
} from '../../../api/services/payments/params'
import {
  FeesType,
  T2PProcessingFeeAmountConfigPaymentType,
} from '../../../Enums/Enums'
import Helpers from '../../../Utility/Helpers'
import { Fee, PaymentConfig } from '../types'

const isEnabled = (arg: boolean): 0 | 1 => (arg ? 1 : 0)
export const getVendorFeesConfig = (v: Fee): VendorFees => {
  const vendorMixedFee = (f: Fee) =>
    v.selected === FeesType.Mixed
      ? {
          VendorFlatFee: Helpers.getAmountInCents(f.mixed.flat),
          VendorPercentageFee: Helpers.getAmountInCents(f.mixed.percentage),
        }
      : {
          VendorFlatFee: Helpers.getAmountInCents(f.flat),
          VendorPercentageFee: Helpers.getAmountInCents(f.percentage),
        }

  return {
    ...vendorMixedFee(v),
    VendorFeeType: v.selected,
    VendorRoundUpTo: v.roundUp.value,
    IsVendorRoundUpToEnabled: isEnabled(v.roundUp.enabled),
    IsVendorEnabled: isEnabled(v.enabled),
  }
}
export const getBuyerFeesConfig = (arg: Fee): BuyerFees => {
  const buyerMixedFee = (f: Fee) =>
    arg.selected === FeesType.Mixed
      ? {
          BuyerFlatFee: Helpers.getAmountInCents(f.mixed.flat || 0),
          BuyerPercentageFee: Helpers.getAmountInCents(f.mixed.percentage),
        }
      : {
          BuyerFlatFee: Helpers.getAmountInCents(f.flat || 0),
          BuyerPercentageFee: Helpers.getAmountInCents(f.percentage),
        }
  return {
    ...buyerMixedFee(arg),
    BuyerFeeType: arg.selected,
    BuyerRoundUpTo: arg.roundUp.value,
    IsBuyerRoundUpToEnabled: isEnabled(arg.roundUp.enabled),
    IsBuyerEnabled: isEnabled(arg.enabled),
    BuyerFeeName: arg.name,
    BuyerPerParkerFee: Helpers.getAmountInCents(arg.parker || 0),
    MaximumBuyerFeeValue: Helpers.getAmountInCents(arg.maximumValue || 0),
  }
}

export type GetProcessingFeeAmountConfig = (arg: {
  payments: PaymentConfig
  paymentsPayOrAcr: keyof Pick<PaymentConfig, 'acr' | 'pay'>
  cFeePayments: PaymentConfig
  cfeePayOrAcr: keyof Pick<PaymentConfig, 'acr' | 'pay'>
  paymentTypes: T2PProcessingFeeAmountConfigPaymentType[]
}) => ProcessingFeeAmountConfigModel

export const getProcessingFeeAmountConfig: GetProcessingFeeAmountConfig = ({
  cFeePayments,
  paymentTypes,
  payments,
  cfeePayOrAcr,
  paymentsPayOrAcr,
}) => ({
  PaymentTypes: paymentTypes,
  IsEnabled: isEnabled(payments.enabled),
  Id: payments.Id,
  ...getBuyerFeesConfig(cFeePayments[cfeePayOrAcr]),
  ...getVendorFeesConfig(payments[paymentsPayOrAcr]),
})
