import { Grid, useTheme } from '@material-ui/core'
import React, { Children, isValidElement } from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  border: 2px solid #e5e5e5;
  padding: 20px 20px;
  margin: 15px 0;
`

export const SectionWrapper: React.FC<{ className?: string; id?: string }> = ({
  className,
  id,
  ...props
}) => {
  const [bottomGutter, setBottomGutter] = React.useState(false)
  const [width, setWidth] = React.useState(window.screen.width)
  const widthRef = React.useRef(window.innerWidth)
  const { breakpoints, spacing } = useTheme()

  const windowResize = React.useCallback(() => {
    if (window.innerWidth !== widthRef.current) {
      widthRef.current = window.innerWidth
      setWidth(window.innerWidth)
    }
  }, [])

  React.useLayoutEffect(() => {
    window.addEventListener('resize', windowResize, {})
    return () => {
      window.removeEventListener('resize', windowResize)
    }
  }, [windowResize])

  React.useEffect(() => {
    width < breakpoints.values.lg
      ? setBottomGutter(true)
      : setBottomGutter(false)
  }, [breakpoints.values.lg, width])

  const renderChildren = () => {
    const children = Children.map(props.children, (child) =>
      isValidElement(child) ? child : null
    )

    if (!children) return null

    return children.filter(Boolean).map((child, i, arr) => (
      <Grid item xs={12} lg={6} key={i}>
        {child}
        {bottomGutter && i < arr.length - 1 ? (
          <div style={{ paddingTop: `${spacing(5)}px` }} />
        ) : null}
      </Grid>
    ))
  }
  if (!renderChildren()?.length) return null
  return (
    <Wrapper className={className} id={id}>
      <Grid container>{renderChildren()}</Grid>
    </Wrapper>
  )
}

export default SectionWrapper
