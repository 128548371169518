import axios from '../..'
import {
  GET_INTEGRATION_MODULES_SETTINGS,
  GET_LINK_TO_LOG_TEZ_AS_RES_OWNER,
  GET_REDIRECT_URL_TO_NACHA_PORTAL,
  SAVE_INTEGRATION_MODULES_SETTINGS,
  VERIFY_T2_CONNECTION_INTEGRATION_SETTINGS,
  VERIFY_T2_V2_CONNECTION_INTEGRATION_SETTINGS,
} from '../../../api/constants'
import { ApiResultGeneric, ApiResultBase } from '../../types'
import {
  EnforcementIntegrationAreaApiModel,
  GetIntegrationModulesSettingsApiResult,
  SaveIntegrationModulesSettingsApiModel,
  T2IntegrationAreaApiModel,
  VerifyT2ConnectionApiModel,
  VerifyT2ConnectionV2ApiModel,
  VerifyT2IntegrationSettings,
  VerifyT2IntegrationV2Settings,
} from './types'

const getIntegrationModulesSettings = async (
  locationId: number
): Promise<ApiResultGeneric<GetIntegrationModulesSettingsApiResult> | null> => {
  try {
    const params = { id: locationId }

    const result = await axios.get<
      ApiResultGeneric<GetIntegrationModulesSettingsApiResult>
    >(GET_INTEGRATION_MODULES_SETTINGS, {
      params,
    })

    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}

const saveIntegrationModulesSettings = async (
  locationId: number,
  t2Integrations: T2IntegrationAreaApiModel[],
  enforcementIntegrations: EnforcementIntegrationAreaApiModel[]
): Promise<ApiResultBase | null> => {
  try {
    const model: SaveIntegrationModulesSettingsApiModel = {
      t2Integrations: t2Integrations,
      enforcementIntegrations: enforcementIntegrations,
    }

    const result = await axios.post<ApiResultBase>(
      SAVE_INTEGRATION_MODULES_SETTINGS,
      model
    )

    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}
export const VerifyT2ConnectionV2 = async (
  locationId: string
): Promise<VerifyT2IntegrationV2Settings | null> => {
  try {
    const model: VerifyT2ConnectionV2ApiModel = {
      locationId: locationId,
    }

    const result = await axios.post<
      ApiResultGeneric<VerifyT2IntegrationV2Settings>
    >(VERIFY_T2_V2_CONNECTION_INTEGRATION_SETTINGS, model)

    return result.data.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}
const VerifyT2Connection = async (
  accountName: string,
  signatureKey: string,
  t2RegionName: string
): Promise<VerifyT2IntegrationSettings | null> => {
  try {
    const model: VerifyT2ConnectionApiModel = {
      accountName: accountName,
      signatureKey: signatureKey,
      t2RegionName: t2RegionName,
    }

    const result = await axios.post<
      ApiResultGeneric<VerifyT2IntegrationSettings>
    >(VERIFY_T2_CONNECTION_INTEGRATION_SETTINGS, model)

    return result.data.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}

const getLinkToLogTezAsResOwner = async (
  ownerId: string
): Promise<ApiResultGeneric<string> | null> => {
  try {
    const params = { ownerId }

    const result = await axios.get<ApiResultGeneric<string>>(
      GET_LINK_TO_LOG_TEZ_AS_RES_OWNER,
      { params }
    )

    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}

const getRedirectUrlToNachaPortal = async (
  baseUrl: string
): Promise<ApiResultGeneric<string> | null> => {
  try {
    const params = { baseUrl }

    const result = await axios.get<ApiResultGeneric<string>>(
      GET_REDIRECT_URL_TO_NACHA_PORTAL,
      { params }
    )

    return result.data
  } catch (err) {
    if (err.response) return err.response.data
    return null
  }
}

const integrationApi = {
  getIntegrationModulesSettings,
  saveIntegrationModulesSettings,
  VerifyT2Connection,
  getLinkToLogTezAsResOwner,
  getRedirectUrlToNachaPortal,
  VerifyT2ConnectionV2,
}

export default integrationApi
