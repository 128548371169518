import { Grid, GridProps } from '@material-ui/core'
import React from 'react'

export interface GridWrapperProps extends GridProps {
  itemProps?: GridProps
}

export const GridWrapper: React.FC<GridWrapperProps> = ({
  itemProps,
  ...props
}) => {
  const renderChildren = () =>
    React.Children.map(props.children, (child) =>
      React.isValidElement(child) ? (
        <Grid item xs={12} {...itemProps}>
          {child}
        </Grid>
      ) : null
    )
  if (!renderChildren()?.length) return null
  return <Grid {...props}>{renderChildren()}</Grid>
}
GridWrapper.defaultProps = {
  spacing: 2,
  container: true,
}
export default GridWrapper
