export interface ApiResultBase {
  success: boolean
  error?: ApiError
}

export interface ApiResultGeneric<T = unknown> extends ApiResultBase {
  data: T
}

export interface ApiError {
  errorCode: ErrorCode
  messages: string[]
}

export enum ErrorCode {
  OtherError = 4999, //network error
  ObjectNotFound = 4010,
  PhoneNumberAlreadyExist = 2014, //network error
}

export enum RequestStatus {
  Idle = 'idle',
  Pending = 'pending',
  Fulfilled = 'fulfilled',
  Rejected = 'rejected',
}

export const fetchStatusIs = (status: RequestStatus) => ({
  idle: status === RequestStatus.Idle,
  pending: status === RequestStatus.Pending,
  fulfilled: status === RequestStatus.Fulfilled,
  rejected: status === RequestStatus.Rejected,
})

export const fetchStatus = (statuses: RequestStatus[]) => {
  return {
    idle: statuses.includes(RequestStatus.Idle),
    pending: statuses.includes(RequestStatus.Pending),
    fulfilled: statuses.includes(RequestStatus.Fulfilled),
    rejected: statuses.includes(RequestStatus.Rejected),
  }
}
