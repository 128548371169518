interface AppConfig {
  apiUrl: string
  oldApiUrl: string
  t2IntegrationV1Enabled: boolean
  t2IntegrationV2Enabled: boolean
  /** flag should be changed on backend as well (IsNachaMonthlyParkingConfigEnabled) */
  nachaMonthlyParkingConfigEnabled: boolean
  autoLogoutTime: number
  warningPopupBeforeLogoutTime: number
}
declare global {
  interface Window {
    config: AppConfig
  }
}

export const APP_CONFIG = window.config
