import axios from '.'
import { AccountLoginModel, TokensModel } from './models/results'
import { authApi } from './services'

const ACCOUNT_KEY = 'T2P_KEY'
const ACCOUNT_TOKENS = 'T2P_TOKENS'
const SESSION_EXPIRED_POPUP = 'SESSION_EXPIRED_POPUP';

export const setTokensInLocalStorage = (tokens: TokensModel) => {
  localStorage.setItem(ACCOUNT_TOKENS, JSON.stringify(tokens))
}

export const getTokensFromLocalStorage = (): TokensModel | null => {
  const tokenSession = localStorage.getItem(ACCOUNT_TOKENS)

  if (!tokenSession) return null

  const tokens: TokensModel = JSON.parse(tokenSession)
  return tokens
}

export const removeTokensFromLocalStorage = () => {
  localStorage.removeItem(ACCOUNT_TOKENS)
}

export const setAuthHeader = (token: string) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

export const removeAuthHeader = () => {
  delete axios.defaults.headers.common['Authorization']
}

export const authHeader = () => {
  // return authorization header with jwt token
  const tokens: TokensModel | null = getTokensFromLocalStorage()

  if (tokens && tokens.auth) {
    return {
      Authorization: 'Bearer ' + tokens.auth,
    }
  }
}

export const setUserInLocalStorage = (
  user: Omit<AccountLoginModel, 'tokens'>
) => {
  localStorage.setItem(ACCOUNT_KEY, JSON.stringify(user))
}

export const getUserFromLocalStorage = (): Omit<
  AccountLoginModel,
  'tokens'
> | null => {
  const userSession = localStorage.getItem(ACCOUNT_KEY)
  const user: AccountLoginModel =
    userSession != null ? JSON.parse(userSession) : null
  return user
}

export const logoutAccount = () => {
  const tokens = getTokensFromLocalStorage()
  tokens &&
    authApi.logout({ authToken: tokens.auth, refreshToken: tokens.refresh })

  localStorage.removeItem(ACCOUNT_KEY)
  localStorage.removeItem(ACCOUNT_TOKENS)
}

export const setSessionExpiredPopupInLocalStorage = (value: boolean) => {
  localStorage.setItem(SESSION_EXPIRED_POPUP, value ? "1" : "0");
}

export const getSessionExpiredPopupFromLocalStorage = () => {
  return localStorage.getItem(SESSION_EXPIRED_POPUP) === "1";
}
