import * as React from 'react'

interface MonthlyTransaction {
  absFee: number
  charged: number
  convFee: number
  corporation: string
  customer: string
  id: string
  nacha: number
  netDepo: number
  transaction: string
}

export const MonthlyTransactionContext =
  React.createContext<MonthlyTransaction>({
    absFee: 0,
    charged: 0,
    convFee: 0,
    corporation: '',
    customer: '',
    id: '',
    nacha: 0,
    netDepo: 0,
    transaction: '',
  })
