import { ConfigurationSource } from '../../Enums/Enums'
import { PaymentsContextState, PaymentsState, Fee } from './types'

export type PaymentsAction =
  | { type: 'set_config'; config: PaymentsState }
  | { type: 'change_merchant'; merchant: string }
  | {
      type: 'change_merchant_echeck'
      merchant: string
      returnedItemFee: number
    }
  | { type: 'change_returned_item_fee'; value: number }
  | { type: 'credit_card_payment_change'; selected: boolean }
  | { type: 'acr_settings_client_pay_change'; props: Partial<Fee> }
  | { type: 'acr_settings_customer_pay_change'; props: Partial<Fee> }
  | { type: 'paypal_account_name_change'; accountName: string }
  | { type: 'ach_payments_change'; selected: boolean }
  | { type: 'change_config_source'; value: ConfigurationSource }

export const paymentsReducer = (
  state: PaymentsContextState,
  action: PaymentsAction
): PaymentsContextState => {
  switch (action.type) {
    case 'set_config':
      return {
        ...state,
        config: { ...action.config },
      }
    case 'change_merchant':
      return {
        ...state,
        config: { ...state.config, selectedMerchant: action.merchant },
      }
    case 'change_merchant_echeck':
      return {
        ...state,
        config: {
          ...state.config,
          selectedMerchantECheck: action.merchant,
          returnedItemFee: action.returnedItemFee,
        },
      }
    case 'change_returned_item_fee':
      return {
        ...state,
        config: {
          ...state.config,
          returnedItemFee: action.value,
        },
      }
    case 'change_config_source':
      return {
        ...state,
        config: {
          ...state.config,
          configurationSource: action.value,
        },
      }
    default:
      return state
  }
}
