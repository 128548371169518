import React from 'react'
import { useTranslation } from 'react-i18next'
import { SelectListOption } from '../../Models/DashboardTypes'
import CustomCheckbox from '../../shared/components/CustomCheckbox/CustomCheckbox'
import Translations from '../../utils/translations'
import Fees from './Fees'
import MaximumFeeValue from './MaximumFeeValue'
import { FeeType, PaymentType, PayOrAcr } from './ProcessingFeeConfiguration'
import RoundUp from './RoundUp'
import SectionWrapper from './SectionWrapper'
import { PaymentConfig } from './types'

const paymentType: PaymentType = 'ConvenienceFee'
type ConvenienceFeePaymentsProps = {
  payment: PaymentConfig | undefined
  handleSectionEnabledChange: (
    selected: boolean,
    paymentType: PaymentType
  ) => void
  handleEnabledChange: (
    paymentType: PaymentType,
    adminType: PayOrAcr
  ) => (value: boolean) => void
  handleFeeTypeChange: (
    paymentType: PaymentType,
    adminType: PayOrAcr
  ) => (value: number) => void
  handleFeeChange: (
    paymentType: PaymentType,
    adminType: PayOrAcr,
    feeType: FeeType
  ) => (value?: number | string) => void
  handleRoundUpChange: (
    paymentType: PaymentType,
    adminType: PayOrAcr
  ) => (option: SelectListOption) => void
}

const ConvenienceFeePayments = ({
  payment,
  handleSectionEnabledChange,
  handleEnabledChange,
  handleFeeTypeChange,
  handleFeeChange,
  handleRoundUpChange,
}: ConvenienceFeePaymentsProps) => {
  const { t } = useTranslation()
  const onPayFeeChange = (feeType: FeeType) =>
    handleFeeChange(paymentType, 'pay', feeType)
  const onAcrFeeChange = (feeType: FeeType) =>
    handleFeeChange(paymentType, 'acr', feeType)
  React.useEffect(() => {
    if (!payment?.enabled) {
      handleEnabledChange(paymentType, 'pay')(false)
      handleEnabledChange(paymentType, 'acr')(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment?.enabled])
  return (
    <>
      <div className="content-row">
        <CustomCheckbox
          id="cfPaymentsBox"
          checked={payment?.enabled}
          title={t(Translations.ConvenienceFee)}
          class="bold"
          handleCheckboxChange={(e) =>
            handleSectionEnabledChange(e, paymentType)
          }
        />
      </div>
      {payment?.enabled && (
        <>
          <SectionWrapper className="fees-border fees-space-between" id="cf">
            <div>
              <div className="width-full">
                <CustomCheckbox
                  id="cfClientBox"
                  checked={payment?.pay.enabled}
                  title={t(Translations.CustomerPayConvenienceFee)}
                  handleCheckboxChange={(value) =>
                    handleEnabledChange(paymentType, 'pay')(value)
                  }
                />
              </div>
              {payment?.pay.enabled && (
                <div>
                  <div className="feeInput">
                    <label>{t(Translations.ConvenienceFeeName)}</label>
                    <input
                      id="cfFeeNameInput"
                      className="form-control"
                      value={payment.pay.name || ''}
                      onChange={(e) => onPayFeeChange('Name')(e.target.value)}
                    />
                  </div>
                  <Fees
                    id="cfClient"
                    buttonSelected={payment?.pay.selected}
                    handleChange={(value: number) =>
                      handleFeeTypeChange(paymentType, 'pay')(value)
                    }
                    firstInputValue={payment?.pay.percentage}
                    firstInputOnChange={() => onPayFeeChange('Percentage')}
                    secondInputValue={payment?.pay.flat}
                    secondInputOnChange={() => onPayFeeChange('Flat')}
                    thirdInputValue={payment?.pay.mixed.flat}
                    thirdInputOnChange={() => onPayFeeChange('MixedFlat')}
                    fourthInputValue={payment?.pay.mixed.percentage}
                    fourthInputOnChange={() =>
                      onPayFeeChange('MixedPercentage')
                    }
                    parkerInputOnChange={() => onPayFeeChange('Parker')}
                    parkerInputValue={payment?.pay.parker}
                  />
                  <RoundUp
                    id="cfClientRoundUp"
                    change={() => handleRoundUpChange(paymentType, 'pay')}
                    value={payment?.pay.roundUp.value}
                  />
                  <MaximumFeeValue
                    id="cfClientMaxValue"
                    setValue={(e) => onPayFeeChange('MaximumValue')(e)}
                    value={payment?.pay.maximumValue}
                  />
                </div>
              )}
            </div>
            {payment?.pay.enabled && (
              /*ACR Settings*/
              <div>
                <div className="width-full">
                  <CustomCheckbox
                    id="cfAcrBox"
                    checked={payment.acr?.enabled}
                    title={t(Translations.ACRSettingsConvenienceFee)}
                    handleCheckboxChange={(value) =>
                      handleEnabledChange(paymentType, 'acr')(value)
                    }
                  />
                </div>
                {payment.acr?.enabled && (
                  <>
                    <Fees
                      id="cfAcr"
                      buttonSelected={payment.acr?.selected}
                      handleChange={(value: any) => {
                        handleFeeTypeChange(paymentType, 'acr')(value)
                      }}
                      firstInputValue={payment.acr?.percentage}
                      firstInputOnChange={() => onAcrFeeChange('Percentage')}
                      secondInputValue={payment.acr?.flat}
                      secondInputOnChange={() => onAcrFeeChange('Flat')}
                      thirdInputValue={payment.acr?.mixed.flat}
                      thirdInputOnChange={() => onAcrFeeChange('MixedFlat')}
                      fourthInputValue={payment.acr?.mixed.percentage}
                      fourthInputOnChange={() =>
                        onAcrFeeChange('MixedPercentage')
                      }
                      parkerInputOnChange={() => onAcrFeeChange('Parker')}
                      parkerInputValue={payment?.acr.parker}
                    />
                    <RoundUp
                      id="cfAcrRoundUp"
                      change={() => handleRoundUpChange(paymentType, 'acr')}
                      value={payment?.acr.roundUp.value}
                    />
                    <MaximumFeeValue
                      id="cfAcrMaxValue"
                      setValue={(e) => onAcrFeeChange('MaximumValue')(e)}
                      value={payment?.acr.maximumValue}
                    />
                  </>
                )}
              </div>
            )}
          </SectionWrapper>
        </>
      )}
    </>
  )
}

export default ConvenienceFeePayments
