import {
  GetPaymentConfigModel,
  PaymentTypeSettingsModel,
  ProcessingFeeAmountConfigModel,
} from '../../../api/services/payments/types'
import {
  FeesType,
  T2PProcessingFeeAmountConfigPaymentType,
} from '../../../Enums/Enums'
import Helpers from '../../../Utility/Helpers'
import { Fee, PaymentsState } from '../types'
import { initialPaymentNumbers } from './dataHelper'

const convertFineFee = (fineFee: number) =>
  typeof fineFee === 'number' ? fineFee / 100 : fineFee

export const getSelectedCarrierId = (
  api: GetPaymentConfigModel,
  paymentTypes: T2PProcessingFeeAmountConfigPaymentType[]
): { merchantId: string; fineFee: number | undefined } | null => {
  const { CreditCard, PaymentGatewayCreditCard } =
    T2PProcessingFeeAmountConfigPaymentType
  const config =
    api.t2PPaymentConfigModel.paymentConfig?.t2PPaymentTypeSettingsConfig
  let merchant = null as unknown as PaymentTypeSettingsModel
  paymentTypes.forEach((paymentTypeEnum) => {
    const selected = config?.find((el) => el.paymentType === paymentTypeEnum)
    if (selected) {
      merchant = selected
    }
  })

  if (
    (paymentTypes.includes(CreditCard) ||
      paymentTypes.includes(PaymentGatewayCreditCard)) &&
    !merchant
  ) {
    return {
      merchantId: api.t2PPaymentConfigModel.merchantList[0]?.value,
      fineFee: 0,
    }
  }

  if (!merchant) return { merchantId: '', fineFee: 0 }

  return {
    merchantId: merchant.carrierId,
    fineFee: merchant.fineFee ? convertFineFee(merchant.fineFee) : undefined,
  }
}

export const getECheckMerchantId = (state: PaymentsState): string | null => {
  if (!state.merchantListECheck.length) return null

  const merchant = state.merchantListECheck[0]
  return merchant ? merchant.value : null
}

export const getVendorFee = (
  arg?: ProcessingFeeAmountConfigModel
): Fee | null =>
  !arg
    ? null
    : {
        flat: Helpers.getAmountFromCents(
          arg.vendorFeeType === FeesType.FlatFee ? arg.vendorFlatFee : 0
        ),
        percentage: Helpers.getAmountFromCents(
          arg.vendorFeeType === FeesType.Percentage
            ? arg.vendorPercentageFee
            : 0
        ),
        roundUp: {
          value:
            arg.isVendorRoundUpToEnabled === 1 ? arg.vendorRoundUpTo ?? 1 : 0,
          enabled: Boolean(arg.isVendorRoundUpToEnabled),
        },
        enabled: arg.isVendorEnabled === 1,
        selected: arg.vendorFeeType,
        mixed: {
          flat: Helpers.getAmountFromCents(
            arg.vendorFeeType === FeesType.Mixed ? arg.vendorFlatFee : 0
          ),
          percentage: Helpers.getAmountFromCents(
            arg.vendorFeeType === FeesType.Mixed ? arg.vendorPercentageFee : 0
          ),
        },
      }

export const getBuyerFee = (arg?: ProcessingFeeAmountConfigModel): Fee =>
  !arg
    ? initialPaymentNumbers
    : {
        flat: Helpers.getAmountFromCents(
          arg.buyerFeeType === FeesType.FlatFee ? arg.buyerFlatFee : 0
        ),
        percentage: Helpers.getAmountFromCents(
          arg.buyerFeeType === FeesType.Percentage ? arg.buyerPercentageFee : 0
        ),
        roundUp: {
          value:
            arg.isBuyerRoundUpToEnabled === 1 ? arg.buyerRoundUpTo ?? 1 : 0,
          enabled: Boolean(arg.isBuyerRoundUpToEnabled),
        },
        enabled: arg.isBuyerEnabled === 1,
        selected: arg.buyerFeeType,
        mixed: {
          flat: Helpers.getAmountFromCents(
            arg.buyerFeeType === FeesType.Mixed ? arg.buyerFlatFee : 0
          ),
          percentage: Helpers.getAmountFromCents(
            arg.buyerFeeType === FeesType.Mixed ? arg.buyerPercentageFee : 0
          ),
        },
        parker: Helpers.getAmountFromCents(
          arg.buyerFeeType === FeesType.Parker ? arg.buyerPerParkerFee : 0
        ),
        name: arg.buyerFeeName,
        maximumValue:
          arg.maximumBuyerFeeValue && arg.maximumBuyerFeeValue !== null
            ? arg.maximumBuyerFeeValue / 100
            : undefined,
      }

export const getProcessingFee = (
  paymentType: keyof typeof T2PProcessingFeeAmountConfigPaymentType,
  config?: ProcessingFeeAmountConfigModel[]
) =>
  config
    ? config.find((x) =>
        x.paymentTypes.includes(
          T2PProcessingFeeAmountConfigPaymentType[paymentType]
        )
      )
    : undefined

/**
 * @arg for pay - CreditCard or ACH
 * @arg for acr - CreditCardACR or ACHACR
 * */
export const getConvenienceFeeNumbers = (
  args: (ProcessingFeeAmountConfigModel | undefined)[] = []
) => {
  return getBuyerFee(args[0])
}

export const isConvenienceFeeEnabled = (args: Fee[]) =>
  Boolean(args.filter((el) => el.enabled).length)
