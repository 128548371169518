import React from 'react'
import { useTranslation } from 'react-i18next'
import Translations from '../../utils/translations'
import CustomPopup from './CustomPopup'

export interface InfoPopupProps {
  show: boolean
  text?: string
  okBtnText?: string
  onClick?: () => void
}

const InfoPopup = ({ show, text, okBtnText, onClick }: InfoPopupProps) => {
  const { t } = useTranslation()

  const popup = (
    <div className="col-xs-12 text-center">
      <h3>{text}</h3>
    </div>
  )

  return (
    <CustomPopup
      open={show}
      content={popup}
      maxWidth="sm"
      onClick={onClick}
      okBtnText={okBtnText && t(okBtnText)}
    />
  )
}

InfoPopup.defaultProps = {
  okBtnText: Translations.Ok,
}

export default InfoPopup
