import * as React from 'react'
import { useTranslation } from 'react-i18next'
import CustomPopup from '../../Controls/Popup/CustomPopup'
import Translations from '../../utils/translations'

interface TimerWarningPopupProps {
  show: boolean
  remainingTime: string
  onCancel: () => void
  onOkClick: () => void
}

const TimerWarningPopup = ({
  show,
  remainingTime,
  onCancel,
  onOkClick,
}: TimerWarningPopupProps) => {
  const { t } = useTranslation()
  const popupContent = React.useMemo(
    () => (
      <div className="col-xs-12 text-center">
        <p style={{ fontSize: '18px', fontFamily: 'Roboto' }}>
          {t(Translations.SessionWillExpire)}
        </p>
        <p style={{ fontWeight: 600, fontSize: '48px', fontFamily: 'Roboto' }}>
          {remainingTime}
        </p>
        <p style={{ fontSize: '14px', fontFamily: 'Roboto' }}>
          {t(Translations.SelectContinueSession)}
        </p>
      </div>
    ),
    [remainingTime, t]
  )

  return (
    <CustomPopup
      open={show}
      content={popupContent}
      okBtnText={t(Translations.ContinueSession)}
      cancelBtnText={t(Translations.Logout)}
      onCancel={onCancel}
      onClick={onOkClick}
      disableBackdropClick
      maxWidth="xs"
    />
  )
}

export default TimerWarningPopup
