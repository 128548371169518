import React from 'react'
import Login from './Login'
import LoginLayout from './LoginLayout'
import './Login.scss'

export interface LoginViewProps {}

const LoginView = (props: LoginViewProps) => {
  return (
    <LoginLayout>
      <Login />
    </LoginLayout>
  )
}

export default LoginView
