import React from 'react'
import { ConfigurationSource } from '../../../Enums/Enums'
import { PaymentsContext } from '../PaymentsContext'
import useGetConfigSource from './useGetConfigSource'

export const useConfigurationSource = () => {
  const { getConfig, apiError } = useGetConfigSource()
  const { state } = React.useContext(PaymentsContext)
  const { config } = state

  const source = () => ({
    t2p: config.configurationSource === ConfigurationSource.T2P,
    nacha: config.configurationSource === ConfigurationSource.Nacha,
  })

  const from = () => {
    if (source().t2p) return 'Text2Park'
    if (source().nacha) return 'Nacha'
    throw Error('Unhandled configuration source')
  }

  const useFrom = () => {
    if (source().nacha) return 'Text2Park'
    if (source().t2p) return 'Nacha'
    throw Error('Unhandled configuration source')
  }

  const nextSource = () => {
    if (source().nacha) return ConfigurationSource.T2P
    if (source().t2p) return ConfigurationSource.Nacha
    throw Error('Unhandled configuration source')
  }

  return {
    source: source(),
    apiError,
    changeSource: () => getConfig(nextSource()),
    title: {
      useFrom: useFrom(),
      from: from(),
    },
  }
}

export default useConfigurationSource
