import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, TimePicker } from '@material-ui/pickers'
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date'
import { ThemeProvider } from '@material-ui/styles'
import Moment from 'moment'
import * as React from 'react'
import { useEffect, useState } from 'react'
import './T2PTimePicker.scss'
import { createTheme } from '@material-ui/core/styles'

interface T2PTimePickerProps {
  selected?: Moment.Moment
  onChange: (time: Moment.Moment) => void
  placeholder?: string
  readOnly?: boolean
  disabled?: boolean
  minTime?: Moment.Moment
  maxTime?: Moment.Moment
  className?: string
  name?: string
  id?: string
}

const theme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#ffffff',
      },
    },
    MuiTypography: {
      h2: {
        fontFamily: 'Montserrat',
        fontSize: 60,
      },
      body1: {
        fontFamily: 'Montserrat',
        fontSize: 16,
      },
    },
    MuiPickersToolbarText: {
      toolbarTxt: {
        color: '#000000',
      },
      toolbarBtnSelected: {
        color: '#ED1C24',
      },
    },
    MuiPickersClockPointer: {
      pointer: {
        backgroundColor: '#ED1C24',
      },
      thumb: {
        border: '14px solid #ED1C24',
      },
      noPoint: {
        backgroundColor: '#ED1C24',
      },
    },
    MuiPickersClock: {
      pin: {
        backgroundColor: '#ED1C24',
      },
    },
    MuiButton: {
      textPrimary: {
        width: '50%',
      },
    },
    MuiPickersTimePickerToolbar: {
      hourMinuteLabel: {
        marginLeft: 'auto',
      },
      ampmSelection: {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
  },
} as any)

const T2PTimePicker = (props: T2PTimePickerProps) => {
  const [selectedTime, setSelectedTime] = useState<Moment.Moment>(
    props.selected || Moment()
  )

  useEffect(() => {
    if (props.minTime && selectedTime.isBefore(props.minTime)) {
      setSelectedTime(props.minTime)
    } else if (props.maxTime && selectedTime.isAfter(props.maxTime)) {
      setSelectedTime(props.maxTime)
    } else if (
      selectedTime &&
      props.selected &&
      selectedTime.format('HH:mm') !== props.selected.format('HH:mm')
    ) {
      props.onChange(selectedTime)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTime])

  useEffect(() => {
    if (
      props.selected &&
      selectedTime &&
      props.selected.diff(selectedTime) !== 0
    ) {
      setSelectedTime(props.selected)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selected])

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <ThemeProvider theme={theme}>
        <TimePicker
          value={selectedTime}
          onChange={(date: MaterialUiPickersDate) => {
            if (date) setSelectedTime(Moment(date))
            
          }}
          emptyLabel={props.placeholder}
          TextFieldComponent={({ onClick, value, onChange, inputProps }) => {
            return (
              <input
                id={props.id}
                name={props.name}
                type="text"
                className={
                  'form-control' +
                  (props.className ? ` ${props.className}` : '')
                }
                onClick={onClick}
                onChange={onChange ? onChange : () => undefined}
                value={
                  selectedTime && value !== props.placeholder
                    ? (value as string)
                    : ''
                }
                placeholder={props.placeholder}
                disabled={props.disabled}
                {...inputProps}
              />
            )
          }}
          okLabel={<p className="saveBtn button-time-picker">OK</p>}
          cancelLabel={<p className="cancelBtn button-time-picker">CANCEL</p>}
          DialogProps={{
            disableEnforceFocus: true,
          }}
        />
      </ThemeProvider>
    </MuiPickersUtilsProvider>
  )
}

export default T2PTimePicker
