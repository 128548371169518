const root = {
  BUSINESS: '/business',
  RESIDENTIAL: '/residential',
  HOME: '/BISHome/Index',
  LOGIN: '/login',
}

const corporations = {
  CORPORATIONS: `${root.BUSINESS}/corporations`,
}

const business = {
  CORPORATION: `${corporations.CORPORATIONS}/:corporationId`,
  MONTHLY_TRANSACTIONS: `${root.BUSINESS}/monthly-parking-transactions`,
}

const corporation = {
  LOCATION_ID: `${business.CORPORATION}/:locationId`,
  LOCATION_LIST: `${business.CORPORATION}/location-list`,
}

const location = {
  MONTHLY_PARKING_FEES: `${corporation.LOCATION_ID}/monthly-parking-fees`,
}

const monthlyParking = {
  LOCATION_CONFIGURATION: `${location.MONTHLY_PARKING_FEES}/location-configuration`,
}

const locationConfiguration = {
  PAYMENT: `${monthlyParking.LOCATION_CONFIGURATION}/payment`,
  MONTHLY_PARKING_CONFIGURATION: `${monthlyParking.LOCATION_CONFIGURATION}/monthly-parking-configuration`,
  INTEGRATION_SETTINGS: `${monthlyParking.LOCATION_CONFIGURATION}/integration-settings`,
}

const payment = {
  PAYMENT_CONFIGURATION: `${locationConfiguration.PAYMENT}/payment-configuration`,
  PROCESSING_FEE_CONFIGURATION: `${locationConfiguration.PAYMENT}/processing-fee-configuration`,
}

const Routes = {
  root,
  corporations,
  business,
  corporation,
  location,
  monthlyParking,
  locationConfiguration,
  payment,
}

export default Routes
