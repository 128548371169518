import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  generatePath,
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from 'react-router'
import { paymentsApi } from '../../api/services'
import { AppContext } from '../../context'
import { setIsSpinning } from '../../context/actions'
import InfoPopup from '../../Controls/Popup/InfoPopup'
import { T2PProcessingFeeAmountConfigPaymentType } from '../../Enums/Enums'
import { routes } from '../../routes'
import Translations from '../../utils/translations'
import * as apiToState from '../../app/Payments/utils/apiToState'
import PaymentConfiguration from './PaymentConfiguration'
import './Payments.scss'
import { PaymentsContext } from './PaymentsContext'
import { paymentsReducer } from './paymentsReducer'
import ProcessingFeeConfiguration from './ProcessingFeeConfiguration'
import { PaymentsState, SavePaymentConfigModel } from './types'
import * as dataHelper from './utils/dataHelper'
import CustomPopup from '../../Controls/Popup/CustomPopup'

const PaymentsContainer = () => {
  const { locationId, corporationId } = useParams<{
    locationId: string
    corporationId: string
  }>()

  const { contextDispatch } = useContext(AppContext)
  const { t } = useTranslation()
  const history = useHistory()
  const [isPopupOpen, setIsPopupOpen] = useState<boolean>(false)
  const [isFailPopupOpen, setIsFailPopupOpen] = useState<boolean>(false)
  const [isFailLoaded, setIsFailLoaded] = React.useState(false)
  const [state, dispatch] = React.useReducer(paymentsReducer, {
    config: dataHelper.initialState(locationId),
  })

  const back = React.useCallback(() => {
    history.push(
      generatePath(routes.corporation.LOCATION_LIST, {
        corporationId,
      })
    )
  }, [history, corporationId])

  const loadData = async () => {
    setIsSpinning(contextDispatch, true)
    const result = await paymentsApi.getPaymentConfig(locationId)
    setIsSpinning(contextDispatch, false)
    if (!result?.success) {
      setIsFailLoaded(true)
      return
    }

    let paymentConfig: PaymentsState
    const t2PPaymentConfigModel = result.data.t2PPaymentConfigModel

    if (t2PPaymentConfigModel.paymentConfig) {
      paymentConfig = dataHelper.mapApiModelToState(
        result.data,
        parseInt(locationId)
      )
    } else {
      paymentConfig = {
        ...dataHelper.initialState(locationId),
        merchantList: t2PPaymentConfigModel.merchantList,
        merchantListECheck: t2PPaymentConfigModel.merchantListECheck,
        selectedMerchant: apiToState.getSelectedCarrierId(result.data, [
          T2PProcessingFeeAmountConfigPaymentType.CreditCard,
        ])?.merchantId,
        selectedMerchantECheck: apiToState.getSelectedCarrierId(result.data, [
          T2PProcessingFeeAmountConfigPaymentType.ACH,
        ])?.merchantId,
      }
    }

    dispatch({ type: 'set_config', config: paymentConfig })
  }

  const handleSave = React.useCallback(async () => {
    setIsSpinning(contextDispatch, true)

    const { config } = state
    const params: SavePaymentConfigModel = {
      id: config.T2PPaymentConfigId,
      convenienceFeeName: config.creditCardPayments?.acr.name ?? '',
      locationId: config.locationId,
      acrSettingsId: config.acrSettingsId,
      creditCardPayments: config.creditCardPayments,
      ACHPayments: config.achPayments,
      convenienceFeePayments: config.convenienceFeePayments,
      selectedMerchant: config.merchantList.find(
        (el) => el.value === config.selectedMerchant
      ),
      selectedMerchantECheck: config.merchantListECheck.find(
        (el) => el.value === config.selectedMerchantECheck
      ),
      returnedItemFee: config.returnedItemFee,
      configurationSource: config.configurationSource,
    }
    try {
      const result = await paymentsApi.savePaymentConfig(params)
      if (!result?.success) throw Error
      if (result && result.success) {
        setIsPopupOpen(true)
      }
    } catch (error) {
      setIsFailPopupOpen(true)
    }
    setIsSpinning(contextDispatch, false)
  }, [contextDispatch, state])

  React.useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId])

  const contextValue = React.useMemo(() => ({ state, dispatch }), [
    state,
    dispatch,
  ])

  return (
    <PaymentsContext.Provider value={contextValue}>
      <InfoPopup
        show={isPopupOpen}
        text={t(Translations.SAVED_SUCCESSFULLY)}
        onClick={() => {
          setIsPopupOpen(false)
          loadData()
        }}
      />
      <InfoPopup
        show={isFailPopupOpen}
        text={t(Translations.SAVE_FAILED)}
        onClick={() => {
          setIsFailPopupOpen(false)
        }}
      />
      <CustomPopup
        open={isFailLoaded}
        okBtnText={t(Translations.TryAgain)}
        onClick={() => {
          setIsFailLoaded(false)
          loadData()
        }}
        cancelBtnText={t(Translations.Cancel)}
        onCancel={back}
        content={
          <div className="col-xs-12 text-center">
            <h3>{t(Translations.UnexpectedError)}</h3>
          </div>
        }
      />

      <Switch>
        <Route
          path={routes.payment.PAYMENT_CONFIGURATION}
          component={PaymentConfiguration}
        />
        <Route
          path={routes.payment.PROCESSING_FEE_CONFIGURATION}
          component={ProcessingFeeConfiguration}
        />
        <Redirect to={routes.payment.PAYMENT_CONFIGURATION} />
      </Switch>

      <div className="buttons center">
        <button
          className="cancelBtn text-uppercase"
          onClick={back}
          id="cancelButton"
        >
          {t(Translations.Cancel)}
        </button>

        <button
          className="saveBtn text-uppercase"
          onClick={handleSave}
          id="saveButton"
        >
          {t(Translations.Save)}
        </button>
      </div>
    </PaymentsContext.Provider>
  )
}

export default PaymentsContainer
