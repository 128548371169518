import * as React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import { NavLink } from 'react-router-dom'
import { Icons } from '../../assets'
import i18n from '../../i18n'
import { routes } from '../../routes'
import Translations from '../../utils/translations'

interface Props {
  profileName: string
  logout: () => void
}

type NavProps = Props & RouteComponentProps

class Nav extends React.Component<NavProps> {
  logoutClick = () => {
    this.props.logout()
  }

  render() {
    return (
      <nav className="navbar navbar-default navbar-fixed-top">
        <div
          style={{
            display: 'flex',
            flexWrap: 'nowrap',
            height: '60px',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div>
            <NavLink className="logo" to={routes.root.BUSINESS}>
              <img className="logoImg" src={Icons.Logo} alt="logo icon" />
            </NavLink>
          </div>
          <div style={{ flexGrow: 1 }} className="text-center">
            <div style={{ display: 'inline-block', marginLeft: '10%' }}>
              <h2
                style={{
                  margin: '10px',
                  color: '#000000',
                  whiteSpace: 'nowrap',
                }}
                className="hidden-xs"
              >
                {i18n.t(Translations.HeaderPortal)}
              </h2>
            </div>
          </div>
          <div className="user-name hidden-xs">
            <h2>{this.props.profileName}</h2>
          </div>
          <div className="logout">
            <img
              className="pointer"
              src={Icons.Logout}
              onClick={this.logoutClick}
              alt="logout icon"
              id="logout"
            />
          </div>
        </div>
      </nav>
    )
  }
}

export default withRouter(Nav)
