import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router'
import payment from '../../../api/services/payments/payments'
import { GetPaymentConfigModel } from '../../../api/services/payments/types'
import { ErrorCode } from '../../../api/types'
import { AppContext } from '../../../context'
import { setIsSpinning } from '../../../context/actions'
import { ConfigurationSource } from '../../../Enums/Enums'
import { getErrorCode } from '../../../utils/errorCode'
import Translations from '../../../utils/translations'
import { PaymentsContext } from '../PaymentsContext'
import { getMerchantIds, mapPartialApiModelToState } from '../utils/dataHelper'

export const useGetConfigSource = () => {
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null)
  const { contextDispatch } = useContext(AppContext)
  const { state, dispatch } = React.useContext(PaymentsContext)
  const { locationId } = useParams<{
    locationId: string
    corporationId: string
  }>()
  const { t } = useTranslation()
  const [apiError, setApiError] = React.useState('')
  const changeSource = (source: ConfigurationSource) => {
    dispatch({
      type: 'change_config_source',
      value: source,
    })
  }
  const updateState = (res: GetPaymentConfigModel) => {
    const { convenienceFeePayments, creditCardPayments, achPayments } =
      state.config
    const config = mapPartialApiModelToState(res, parseInt(locationId))

    dispatch({
      type: 'set_config',
      config: {
        convenienceFeePayments,
        creditCardPayments,
        achPayments,
        ...config,
        ...getMerchantIds(res),
      },
    })
  }
  const cleanupErrorTimeout = () => setTimeout(() => setApiError(''), 5000)

  React.useEffect(
    () => () => {
      timeoutRef.current && clearTimeout(timeoutRef.current)
    },
    []
  )
  const handler = async (configSource: ConfigurationSource) => {
    setIsSpinning(contextDispatch, true)
    setApiError('')
    try {
      const res = await payment.getConfigurationFrom({
        locationId,
        configSource,
        type: state.config.accountType,
      })
      if (!res?.data) {
        let error = {
          response: {
            data: res,
          },
        }
        throw error
      }
      updateState(res.data)
      changeSource(configSource)
    } catch (error) {
      const code = getErrorCode(error)
      if (code === ErrorCode.ObjectNotFound)
        return setApiError(t(Translations.NoNACHAConfig))
      setApiError(t(Translations.UnexpectedError))
      timeoutRef.current = cleanupErrorTimeout()
    } finally {
      setIsSpinning(contextDispatch, false)
    }
  }
  return { getConfig: handler, apiError }
}

export default useGetConfigSource
